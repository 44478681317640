import React, { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight, AiOutlineLoading3Quarters } from "react-icons/ai";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Post } from "../../utils/httpReguests";
import Recaptcha from "../../utils/Recaptcha";
import ErrorMessage from "../components/errorMessage";
import GoogleTag from "../components/GoogleTag";
import LogicalRouter from "../components/LogicalRouter";
import NavBar from "../components/NavBar";
import StepBar from "../components/registrationStepBar";
import SuccessMessage from "../components/successMessage";
import whitePreloader from '../../assets/images/rolling_white_loader.gif';
import CountDownBar from "../components/CountdownBar";
import ProgressIndicator from "../components/ProgressIndicator";

export default function Checkout(props) {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const history = useHistory('');
  const [loading, setLoading] = useState(false);
  const [isActiveUser, setIsActiveUser] = useState(false);

  useEffect(() => {
    window.dataLayer.push({
      event: 'VerifyOTP',
      pagePath: '/vefify-otp',
      pageTitle: 'VerifyOTP',
      email: sessionStorage.getItem('userEmail') || ''
    });
    GoogleTag();
  });

  useEffect(() => {
    if (
      sessionStorage.getItem('selectedPlan')
      || localStorage.getItem('doosra-user-sessionToken')
    ) {
      // history
    } else {
      history.push('/choose-plan');
    }
  }, []);

  const SubmitOTPHandler = (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    // const data = {
    //   otp,
    //   secret: sessionStorage.getItem("secret"),
    //   phone_number: sessionStorage.getItem("userNumber"),
    // };
    // Post("v1/internal/m/verify-mobile-number", data)
    //   .then((res) => {
    //     setError("");
    //     setSuccess(false);
    signup();
    // })
    // .catch((err) => {
    //   setError(err?.response?.data?.error?.reason);
    //   setSuccess(false);
    //   setLoading(false);
    // });
  };
  const signup = (token) => {
    Recaptcha.execute({ action: Recaptcha.ACTIONS.SIGNUP_VERIFY_OTP }).then(
      (token) => {
        const selectedNumberType = sessionStorage.getItem('selectedNumberType');
        const data = {
          phone_number: sessionStorage.getItem('userNumber'),
          secret: sessionStorage.getItem('secret'),
          otp,
          v_mobile_no: sessionStorage.getItem('doosraNumber'),
          recaptchaToken: token,
          source: 'WEB',
          otp,
          secret: sessionStorage.getItem('secret'),
          phone_number: sessionStorage.getItem('userNumber'),
        };

        if (selectedNumberType !== 'DEFAULT') {
          data.v_mobile_no = sessionStorage.getItem('number');
        } else {
          data.v_mobile_no = sessionStorage.getItem('defaultNumber');
        }

        Post('auth/signup', data)
          .then((res) => {
            if (res?.data?.response?.status === 'ACTIVE') {
              setIsActiveUser(true);
            } else if (res?.data?.response['x-metro-sessiontoken']) {
              localStorage.setItem(
                'doosra-user-sessionToken',
                res?.data?.response['x-metro-sessiontoken'],
              );
              localStorage.setItem(
                'doosra-user-id',
                res?.data?.response?.user_id,
              );
              localStorage.setItem(
                'doosra-user-status',
                res?.data?.response?.status,
              );
              routeUser();
            }
          })
          .catch((err) => {
            setError(err?.response?.data?.error?.reason);
            setLoading(false);
          });
      },
    );
  };

  const goBackHandler = (point) => {
    history.push(point);
  };

  const getOTPHandler = (val) => {
    const action = Recaptcha.ACTIONS.SIGNUP_RESEND_OTP;
    Recaptcha.execute({ action }).then((token) => {
      Post('v2/internal/send-otp-new-number', {
        phone_number: val,
        recaptchaToken: token,
      })
        .then((res) => {
          sessionStorage.setItem(
            'userNumber',
            res?.data?.response?.phone_number,
          );
          sessionStorage.setItem('secret', res?.data?.response?.secret);
          setSuccess(true);
          setError('');
        })
        .catch((err) => {
          setError(err?.response?.data?.error?.reason);
          setSuccess(false);
        });
    });
  };

  const routeUser = () => {
    history.push('/checkout');
    if (sessionStorage.getItem('selectedNumberType') == 'SIMILAR') {
      history.push('/choose-similar');
    }
  };

  return (
    <div>
      <LogicalRouter />
      <NavBar />
      <CountDownBar />
      <ProgressIndicator currentStep={2} />
      <div style={{ textAlign: 'center' }}>
        <h1 className="title">Enter OTP received on your phone</h1>
        <h4 className="form-otp-sent">
          OTP successfully sent to +91
          {' '}
          {sessionStorage.getItem('userNumber')}
        </h4>
        <form className="input-form" onSubmit={SubmitOTPHandler}>
          <input
            placeholder="XXXXXX"
            className="form-input"
            value={otp}
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
          <p
            className="form-resend-otp"
            onClick={() => getOTPHandler(sessionStorage.getItem('userNumber'), true)}
          >
            Re-send OTP
          </p>
          {success && <SuccessMessage msg="OTP sent successfully" />}
          <ErrorMessage msg={error} />
          {isActiveUser && (
            <>
              <ErrorMessage msg="Looks like you are already registered with us." />

              <Link
                to="/login"
                style={{ textDecoration: 'underline', color: '#ed3833' }}
              >
                Click here to login
              </Link>
            </>
          )}
          <div className="form-button-container">
            <button
              className="form-back-btn"
              type="button"
              onClick={() => goBackHandler('/enter-number')}
            >
              <AiOutlineLeft className="form-btn-icon" />
              {' '}
              GO BACK
            </button>
            {
              loading ?
                <button className="form-proceed-btn" style={{ backgroundColor: '#939598', border: 'none' }} type="submit">
                  <img src={whitePreloader} style={{ width: '24px' }} />
                </button>
                :
                <button className="form-proceed-btn" type="submit">
                  NEXT <AiOutlineRight className="form-btn-icon" />
                </button>
            }
          </div>
        </form>
      </div>
    </div>
  );
}
