
class Recaptcha {

  ACTIONS = {
    LOGIN_OTP: 'LOGIN_OTP',
    LOGIN_PASSWORD: 'LOGIN_PASSWORD',
    LOGIN_RESEND_OTP: 'LOGIN_RESEND_OTP',
    LOGIN_VERIFY_OTP: 'LOGIN_VERIFY_OTP',
    SIGNUP_OTP: 'SIGNUP_OTP',
    SIGNUP_RESEND_OTP: 'SIGNUP_RESEND_OTP',
    SIGNUP_VERIFY_OTP: 'SIGNUP_VERIFY_OTP',
    FORGOT_PASSWORD_OTP: 'FORGOT_PASSWORD_OTP',
    FORGOT_VERIFY_OTP: 'FORGOT_VERIFY_OTP',
  }
  /**
     *
     * @param {Object} action
     *
     */
  execute(action) {
    if (this.isReady()) {
      return window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        action,
      );
    }
    return new Promise().reject(new Error('captcha not ready'));
  }

  /**
     *
     * @param {Object} action  {action: "login"}
     * @param {Object} props    this.props
     * @param {Object} data     data
     */
  executeAndTriggerApi(action, props, data) {
    this.execute(action).then((token) => {
      this.triggerApi(action.action, token, props, data);
    });
  }

  isReady() {
    if (window.grecaptcha && window.grecaptcha.ready) {
      return true;
    }
    return false;
  }

  triggerApi(action, token, props, data) {
    data.recaptchaToken = token;
    switch (action) {
      case 'login':
        props.login(data);
        break;
      case this.ACTIONS.FORGOT_PASSWORD_VERIFY_OTP:
        props.verifyOTPforPasswordReset(data);
      default:
        break;
    }
  }
}
export default Recaptcha = new Recaptcha();
