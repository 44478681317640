import React, { Component } from "react";
import CheckMark from "../../assets/images/checkmark-big.png";
import TickMark from "../../assets/images/tickMark.png";
import GoogleTag from "../../v2/components/GoogleTag";
import InCNavbar from "../InCNavBar";

class Success extends Component {
  componentDidMount() {
    if (this.props.history.action == "POP") {
      this.props.history.push("/gift-card");
    } else {
      // console.log(this.props)
      window.dataLayer.push({
        event: "GiftCardSuccess",
        pagePath: `/gift-card-success`,
        pageTitle: "GiftCardSuccess",
        purchaseValue: this.props.location.state.couponsTotal,
      });
      GoogleTag();
    }
  }

  render() {
    return (
      <React.Fragment>
        <InCNavbar />
        <div style={{ textAlign: "center" }}>
          <img src={CheckMark} className="SuccessCheckMark" />
          <p className="GiftPageHead">
            Congratulations, your family and friends <br />
            are now officially unspammable & unphishable.
          </p>
          <p className="DoNextHeader">What to do next?</p>
          <div className="DoNextContainer">
            <div className="ContainerOne">
              <img className="ContainerImage" src={TickMark} />
              <p className="ContainerText">
                Check your email for the gift vouchers
              </p>
            </div>
            <div className="ContainerTwo">
              <img className="ContainerImage" src={TickMark} />
              <p className="ContainerText">
                Share those gift vouchers with you family and friends
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Success;
