import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../styles/editNameModal.css';
import { Put } from '../../utils/httpReguests';
import Recaptcha from '../../utils/Recaptcha';

const crossImg = require('../../assets/images/cross.svg');

export default function EditEmailModal(props) {
  const [newEmail, setNewEmail] = useState('');
  const [userMessage, setUserMessage] = useState('');

  const handleClose = (e) => {
    if (e) e.preventDefault();
    props.closePopup();
    setNewEmail('');
    setUserMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const action = Recaptcha.ACTIONS.LOGIN_RESEND_OTP;
    await Recaptcha.execute({ action }).then((token) => {
      const data = {
        recaptchaToken: token,
        email: newEmail,
      };
      Put('v1/internal/w/update_user_profile', {}, data)
        .then((res) => {
          props.updateUserProfileData('zvr_email', newEmail);
          handleClose();
        })
        .catch((err) => {
          console.log('err', err);
          setUserMessage(
            err?.response?.data?.error?.reason || 'unable to update Email',
          );
        });
    });

  };
  return (
    <Modal show={props.isVisible} centered>
      <img className="cross-button" src={crossImg} onClick={handleClose} />
      <form onSubmit={handleSubmit}>
        <h1>Edit Email </h1>
        {props?.email &&
          <input disabled type="text" value={props.email} />
        }
        <input
          type="text"
          value={newEmail}
          onChange={(e) => {
            setNewEmail(e.target.value);
          }}
        />
        <span className="user-Message">{userMessage}</span>
        <div className="button-group">
          <button type="submit">Update</button>
          <button onClick={handleClose} type="button" className="cancel-button">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
