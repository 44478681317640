import moment from "moment";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "../../assets/css/modal.css";
import { Get, Put } from "../../utils/httpReguests";
import Loading from "../Loading";

const crossImg = require("../../assets/images/cross.svg");
const checkmarkCircle = require("../../assets/images/red_tick_circle.svg");
const checkmarkCircleGreen = require("../../assets/images/checkmark_circle.svg");
const rupees = require("../../assets/images/rupees.svg");
const rupeesGreen = require("../../assets/images/rupeesGreen.svg");
const unlock = require("../../assets/images/unlock.svg");

export default function ChangePlanInFreeTrialPopup(props) {
  const [loader, setLoader] = useState(false);
  const [planAmount, setPlanAmount] = useState(0);
  const [planId, setPlanId] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [plans, setPlans] = useState({});
  const [proCapsuleSelected, setProCapsuleSelected] = useState(false);

  useEffect(() => {
    Get("plans", { source: "WEB" })
      .then((res) => {
        setPlans(res?.data?.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const selectCapsuleHandler = (data) => {
    setProCapsuleSelected(data);
  };
  const Proceed = async (e) => {
    setLoader(true);
    await Put("v1/internal/w/upgrade-subscription-plan", {
      now: false,
      subscription_id: props.orderHistory.subscription_id,
      plan_id: planId,
    })
      .then((res) => {
        setTimeout(() => {
          setIsPopupOpen(false);
          setLoader(false);
        }, 5000);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const selectPlan = (plan) => {
    setPlanId(plan.planId);
    setPlanAmount(plan.amount / 100);
  };

  const plan1 = {};
  const plan2 = {};
  const proPlan1 = {};
  const proPlan2 = {};

  if (plans?.essential?.length === 2) {
    plan1.amount = plans.essential[0].amount;
    plan1.planId = plans.essential[0].plan_id;
    plan2.amount = plans.essential[1].amount;
    plan2.planId = plans.essential[1].plan_id;
  }
  if (plans?.pro?.length === 2) {
    proPlan1.amount = plans.pro[1].amount;
    proPlan1.planId = plans.pro[1].plan_id;
    proPlan2.amount = plans.pro[0].amount;
    proPlan2.planId = plans.pro[0].plan_id;
  }

  let planDuration = "";
  if (props.currentPlanAmount == plan1.amount / 100) {
    planDuration = "Half Yearly plan";
  } else {
    planDuration = "Annual plan";
  }

  const clickText = `${planDuration} (click to change)`;

  const popper = (
    <Popup open={isPopupOpen} closeOnDocumentClick={false}>
      {loader ? (
        <Loading />
      ) : (
        <>
          <img className="cross-button" src={crossImg} onClick={closePopup} />
          <div className="Modal">
            <div className="PlanUpgradeModalHeading NoBottomPadding">
              Upgrade to a Doosra Plan
            </div>

            <div className="PlanUpgradeModalSubHeading">
              Unlock all features with a Doosra paid plan
            </div>

            <div className="ModalBody" style={{ textAlign: "center" }}>
              <div className="ProductSelectCapsuleHolder">
                <span
                  className={
                    proCapsuleSelected
                      ? "ProductSelectCapsule"
                      : "ProductSelectCapsule CapsuleSelected"
                  }
                  onClick={() => {
                    selectCapsuleHandler(false);
                  }}
                >
                  <span
                    className={
                      proCapsuleSelected
                        ? "ProductSelectCapsuleHeader "
                        : "ProductSelectCapsuleHeader CapsuleSelectedText"
                    }
                  >
                    Essential
                  </span>
                </span>
                <span
                  className={
                    proCapsuleSelected
                      ? "ProductSelectCapsule CapsuleSelected"
                      : "ProductSelectCapsule"
                  }
                  onClick={() => {
                    selectCapsuleHandler(true);
                  }}
                >
                  <span
                    className={
                      proCapsuleSelected
                        ? "ProductSelectCapsuleHeader CapsuleSelectedText"
                        : "ProductSelectCapsuleHeader"
                    }
                  >
                    DOOSRA
                    <span
                      className={
                        proCapsuleSelected
                          ? "ProductSelcetCapsuleHeaderPro CapsuleSelectedSubText"
                          : "ProductSelcetCapsuleHeaderPro"
                      }
                    >
                      {" "}
                      Pro
                    </span>
                  </span>
                </span>
              </div>
              {proCapsuleSelected ? (
                <React.Fragment>
                  <div
                    className={
                      planAmount == proPlan1.amount / 100
                        ? "ItemSelectedBorder OptionBox"
                        : "ItemUnselectedBorder OptionBox"
                    }
                    onClick={() => {
                      selectPlan(proPlan1);
                    }}
                  >
                    Half Yearly @ &#8377;
                    {proPlan1.amount / 100 || 0}
                  </div>

                  <div
                    className={
                      planAmount == proPlan2.amount / 100
                        ? "ItemSelectedBorder OptionBox"
                        : "ItemUnselectedBorder OptionBox"
                    }
                    onClick={() => {
                      selectPlan(proPlan2);
                    }}
                  >
                    <p style={{ margin: 0, position: "absolute" }}>
                      {" "}
                      Annual @ &#8377;
                      {proPlan2.amount / 100 || 0}
                    </p>
                    <span className="Recommended">RECOMMENDED</span>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div
                    className={
                      planAmount == plan1.amount / 100
                        ? "ItemSelectedBorder OptionBox"
                        : "ItemUnselectedBorder OptionBox"
                    }
                    onClick={() => {
                      selectPlan(plan1);
                    }}
                  >
                    Half Yearly @ &#8377;
                    {plan1.amount / 100 || 0}
                  </div>

                  <div
                    className={
                      planAmount == plan2.amount / 100
                        ? "ItemSelectedBorder OptionBox"
                        : "ItemUnselectedBorder OptionBox"
                    }
                    onClick={() => {
                      selectPlan(plan2);
                    }}
                  >
                    <p style={{ margin: 0, position: "absolute" }}>
                      {" "}
                      Annual @ &#8377;
                      {plan2.amount / 100 || 0}
                    </p>
                    <span className="Recommended">RECOMMENDED</span>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="ModalButtonHolder">
              <button className="ModalButtonTwo" onClick={Proceed}>
                Proceed
              </button>
              <button className="ModalButtonTwo Invert" onClick={closePopup}>
                Close
              </button>
            </div>
          </div>
        </>
      )}
    </Popup>
  );

  if (props?.profileData?.accountType === "free") {
    return (
      <div className="side-box-element">
        <div>
          <p>
            Your card will be charged for ₹
            {props.profileData?.subscription_amount || 0} on{" "}
            {moment(props.expireOn).format("DD/MM/YYYY")}
            <br />
            for an{" "}
            <span
              style={{
                color: "#ed3833",
                textDecoration: "underline",
              }}
            >
              <span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsPopupOpen(true)}
                >
                  {clickText}
                </span>
                {popper}
              </span>
            </span>{" "}
            after your trial ends
          </p>
        </div>
      </div>
    );
  }
  return null;
}
