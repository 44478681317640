/* eslint-disable */
const axios = require("axios");

const API_URL = process.env.REACT_APP_API_URL_v2;
const API_KEY = process.env.REACT_APP_API_KEY;
const header = {
  "Content-Type": "application/json",
  "x-metro-api-key": API_KEY,
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { data, status } = error.response;
    if (status === 503 && data.error.code === "MAINTENANCE_MODE") {
      sessionStorage.setItem("doosra-under-maintainance", true);
      sessionStorage.setItem("doosra-maintainance-message", data.error.reason);
    }

    if (data.error.code === 1004) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

function checkForToken() {
  if (
    localStorage.getItem("doosra-user-sessionToken") &&
    localStorage.getItem("doosra-user-sessionToken") != ""
  ) {
    header["x-metro-sessiontoken"] = localStorage.getItem(
      "doosra-user-sessionToken"
    );
  }
}

function Get(url, params = {}) {
  checkForToken();
  return axios.get(API_URL + url, {
    headers: header,
    params: params,
    withCredentials: true,
  });
}

function Post(url, params = {}) {
  checkForToken();
  return axios.post(API_URL + url, params, {
    headers: header,
    withCredentials: true,
  });
}

function Put(url, params, updates) {
  checkForToken();
  return axios.put(API_URL + url, updates, {
    headers: header,
    params: params,
    withCredentials: true,
  });
}

function Delete(url, params) {
  checkForToken();
  return axios.delete(API_URL + url, {
    headers: header,
    params: params,
    withCredentials: true,
  });
}

function DELETE(url, params) {
  checkForToken();
  return axios({
    url: API_URL + url,
    method: 'delete',
    data: params,
    headers: header
  });
}

export { Get, Post, Put, Delete, DELETE };
