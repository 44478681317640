import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import '../../assets/css/giftSection.css';
import { ReactComponent as ForwardArrow } from '../../assets/images/Group 1228.svg';
import RazorpayImage from '../../assets/images/Image 41.png';
import Preloader from '../../assets/images/preloader_circle_red.gif';
import { Get, Post } from '../../utils/httpReguests';

function PaymentConfirmationPopup(props) {
  return (
    <Popup open={props.open} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className=""
        style={{
          minWidth: '300px',
          textAlign: 'center',
          background: 'white',
          height: 'auto',
          padding: '30px',
          borderRadius: '6px',
        }}
      >
        {props.count}
        <img src={Preloader} style={{ width: '64x', height: '64px' }} />
        <p style={{ margin: '0', fontWeight: '500' }}>
          We are processing your payment
        </p>
        <p style={{ margin: '0', fontWeight: '500' }}>
          Please do not refresh or close this tab
        </p>
      </div>
    </Popup>
  );
}
export default function ProvideEmail(props) {
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [
    isPaymentConfirmationLoaderVisible,
    setIsPaymentConfirmationLoaderVisible,
  ] = useState(false);
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email == '') {
      return setError('provide an email address');
    }
    if (email.match(mailformat)) {
      window.dataLayer.push({
        event: 'GiftCardEmail',
        pagePath: '/gift-card-email',
        pageTitle: 'GiftCardEmail',
        email,
      });
      const data = {
        email,
        ...props.coupons,
        discount: props.discount,
      };

      Post('v1/internal/w/create-gift-vouchers', data)
        .then((resp) => {
          if (resp.data.success) {
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY,
              name: 'Doosra',
              description: 'payment',
              order_id: resp.data.response.order_details.order_id,
              prefill: {
                email: data.email,
              },
              handler(response) {
                console.log('response', response);
                const verificationData = {
                  payment_id: response.razorpay_payment_id,
                  signature: response.razorpay_signature,
                  order_id: response.razorpay_order_id,
                };
                console.log('verificationData', verificationData);
                const confirmationData = {
                  order_id: response.razorpay_order_id,
                };
                Post(
                  'v1/internal/w/voucher-signature-verification',
                  verificationData,
                )
                  .then((resp) => {
                    console.log('response', resp.data.reponse);
                    if (resp.data.response.is_verified == true) {
                      PaymentConfirmation(data, confirmationData);
                    } else {
                      console.log('err');
                    }
                  })
                  .catch((err) => {
                    setError(err?.response?.data?.error?.reason);
                  });
              },
              theme: {
                color: '#686CFD',
              },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
          }
        })
        .catch((err) => {
          setError(err?.response?.data?.error?.reason);
        });
    } else {
      return setError('provide valid email address');
    }
  };

  const PaymentConfirmation = (data, confirmationData) => {
    setIsPaymentConfirmationLoaderVisible(true);

    let count = 1;
    const RetryConfirmation = (data, confirmationData) => {
      count += 1;
      Get('v1/internal/w/is-voucher-confirmed', confirmationData)
        .then((resp) => {
          if (resp.data.response.is_order_confirmed) {
            props.makePayment(data);
          } else {
            if (count >= 7) return FailureRedirect(data);
            setTimeout(() => {
              RetryConfirmation(data, confirmationData);
            }, 5000);
          }
        })
        .catch((err) => {
          if (count >= 7) return FailureRedirect(data);
          setTimeout(() => {
            RetryConfirmation(data, confirmationData);
          }, 5000);
        });
    };
    RetryConfirmation(data, confirmationData);
  };

  const FailureRedirect = (data) => {
    history.push({
      pathname: '/failure',
      state: {
        data: data || '',
      },
    });
  };

  return (
    <div className>
      <p className="GiftPageHead PageOneHeadSpacing">
        Enter your email address
      </p>
      <p className="GiftPageSubHead">
        Gift coupon codes will be sent to your email inbox after checkout
      </p>
      <form>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          className="Inputs InputPadding"
          placeholder="Your email address"
        />
        {' '}
        <br />
        <p className="Error">{error}</p>
        <button
          className="GiftCouponBuyButton ButtonSpacingPageOne"
          onClick={handleSubmit}
          type="submit"
        >
          <spam>Checkout</spam>
          <ForwardArrow
            className="ForwardArrowPosition"
            style={{ 'margin-left': '26px' }}
          />
        </button>
      </form>
      <p className="GreyHeaderOne" style={{ 'margin-bottom': '0' }}>
        Secure payments powered by
      </p>
      <img src={RazorpayImage} />
      <PaymentConfirmationPopup open={isPaymentConfirmationLoaderVisible} />
    </div>
  );
}
