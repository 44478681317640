import React from "react";

function Steps(props) {
  return (
    <div style={{ textAlign: "center" }}>
      <div className="Stepsbar">
        <span className={props.currentStep == 1 ? "CurrentStep" : "Step"}>
          Choose Plan
        </span>
        <span className={props.currentStep == 2 ? "CurrentStep" : "Step"}>
          Choose Doosra Number
        </span>
        <span className={props.currentStep == 3 ? "CurrentStep" : "Step"}>
          Make Payment
        </span>
        <span className={props.currentStep == 4 ? "CurrentStep" : "Step"}>
          Done
        </span>
      </div>
    </div>
  );
}

export default Steps;
