import React, { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import '../../assets/navbar.css';
import { Post } from '../../utils/httpReguests';

export default function NavBar(props) {
  const history = useHistory();
  const [onLogIn, setOnLogIn] = useState(false);
  //   constructor() {
  //     super();
  //     this.state = {
  //       login: false,
  //     };
  //   }

  useEffect(() => {
    // console.log(window.location.pathname);
    if (
      window.location.pathname == '/'
      || window.location.pathname == '/login'
    ) {
      setOnLogIn(true);
    } else {
      setOnLogIn(false);
    }
    // console.log("onLogIn", onLogIn);
  }, []);

  useEffect(() => {
    const userToken = localStorage.getItem('doosra-user-sessionToken');
    if (!userToken && window.location.pathname === '/user-dashboard') {
      history.push('/');
    }
  }, [localStorage.getItem('doosra-user-sessionToken')]);

  //   logoutHandler = (sessionToken) => {
  //     this.props.logout(sessionToken);
  //     this.setState({
  //       login: false,
  //     });
  //   };

  //   changeTitle = () => {
  //     this.setState({
  //       login: !this.state.login,
  //     });
  //   };

  // console.log('this.props', window.location.pathname);

  const logoutHandler = () => {
    Post('v1/internal/w/profile/logout')
      .then((response) => {
        if (response.data !== undefined && response.data.success) {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const token = localStorage.getItem('doosra-user-sessionToken');
  let authButton;
  if (token !== null) {
    authButton = (
      <p className="NavbarButton" onClick={() => logoutHandler(token)}>
        Logout
      </p>
    );
  }
  // else if (onLogIn) {
  //   authButton = (
  //     <Link
  //       className="NavbarButton"
  //       to="/choose-plan"
  //       onClick={() => {
  //         setOnLogIn(false);
  //       }}
  //     >
  //       Sign up
  //     </Link>
  //   );
  // } else {
  //   authButton = (
  //     <Link
  //       className="NavbarButton"
  //       to="/"
  //       onClick={() => {
  //         setOnLogIn(true);
  //       }}
  //     >
  //       Login
  //     </Link>
  //   );
  // }

  return (
    <Navbar collapseOnSelect expand="lg" className="NavbarShadow">
      <a href="https://www.doosra.com/" className="Logo">
        <img
          src={Logo}
          className="d-inline-block align-top logo"
          alt="React Bootstrap logo"
        />
      </a>
      <Navbar.Brand />
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        style={{ border: 'none' }}
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" />
        <Nav>{authButton}</Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
