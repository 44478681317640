/* eslint-disable */
import React, { useState } from "react";

export const SelectDeleteReason =(props) => {
    const [disableTextInput, setDisableTextInput] = useState(true)
    const radio_style= {width: '16px', height: '16px', margin:'10px 6px 10px 10px'}
    const label_style = {
        margin: '23px 0px 0px 0px',
        opacity: '0.7',
        'font-family': 'Montserrat',
        'font-size': '16px',
        'font-weight': '500',
        'font-stretch': 'normal',
        'font-style': 'normal',
        'line-height': '1.25',
        'letter-spacing': 'normal',
        'text-align': 'left',
        color: '#000'
    }
    const input_style= {
        'width': '350px',
        'height': '129px',
        'margin': '24px 0 40px 5px',
        'padding': '16px 16px 97px',
        'border-radius': '4px',
        'border': 'solid 2px #d2d3d4'
    }    
    const reasons = [
        'I have no use for Doosra anymore',
        'I don’t remember signing up',
        'I want to reduce my digital footprint',
        'Doosra is too expensive',
        'The app is unusable',
        'I do not like the design (UI and UX)',
        'The app crashes too much',
        'I’m using an alternative service',
        'I want outgoing calling',
        'My reason isn’t listed'
    ]
    const onReasonChange = (e) => {
        props.selectedReason(e.target.value);
        if (e.target.value === 'My reason isn’t listed') {
            setDisableTextInput(false)
        }
        else {
            setDisableTextInput(true)
        }
    }
    const onInputChange = (e) => {
        props.selectedReason(e.target.value);
    }
    return(
        <div>
            {
                reasons.map((reason) => <div>
                    <input
                        style={radio_style}
                        type="radio"
                        id={reason}
                        name="drone"
                        value={reason} 
                        onChange={onReasonChange}
                    />
                    <label style={label_style}for={reason}>{reason}</label> </div>
                )
            }
            <input style={input_style} type="text" placeholder="Write down your reason here…" disabled={disableTextInput} onChange={onInputChange}/>
        </div>
    )
}