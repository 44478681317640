import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import '../../assets/css/buyMinutes.css';
import { Get, Post } from '../../utils/httpReguests';
import Add from '../../assets/images/add_red_circle.png';
import Subtract from '../../assets/images/subtract_red_circle.png';
import ProceedIcon from '../../assets/images/proceed_white.png';
import SuccessIcon from '../../assets/images/checkmark_green_large.png';
const crossImg = require('../../assets/images/cross.svg');


export default function BuyMinutesNew(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [minPackDetails, setMinPackDetails] = useState({});
  const [count, setCount] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  let contentStyle = { marginBottom: window.screen.width < 600 ? 0 : 'auto' };

  useEffect(() => {
    Get('v1/internal/w/all-minute-packs')
      .then((res) => {
        setMinPackDetails(res?.data?.response?.minutePacks[0]);
        setTotal(res?.data?.response?.minutePacks[0]?.amount / 100 || 0);
      })
      .catch((err) => {
        setError('Error fetching minute pack details');
      });
    const isModalOpen = new URLSearchParams(window.location.search).get('modal');
    if (isModalOpen === 'buy-minutes') {
      setIsPopupOpen(true);
    }
  }, []);


  const onAdd = () => {
    if (count >= 20) return;
    if (loading) return;
    setCount(count + 1);
    setTotal((minPackDetails?.amount / 100) * (count + 1));
  };

  const onSubtract = () => {
    if (count <= 1) return;
    if (loading) return;
    setCount(count - 1);
    setTotal((minPackDetails?.amount / 100) * (count - 1));
  };

  const Proceed = async () => {
    if (count === 0) return;
    if (loading) return;
    setLoading(true);
    const data = {
      min_pack_id: minPackDetails?.min_pack_id,
      user_id: props?.profileData?.user_id,
      quantity: count,
    };
    await Post('v1/internal/w/minute-pack-order', data)
      .then((res) => {
        if (res.data.success) {
          const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            name: 'Doosra',
            description: 'payment',
            order_id: res.data.response.order_details.order_id,
            prefill: {
              name: data.name,
              email: data.email,
              contact: data.contact,
            },
            handler: (response) => {
              const verificationData = {
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                user_id: data.user_id,
                order_id: response.razorpay_order_id,
              };
              const confirmationData = {
                user_id: data.user_id,
                order_id: response.razorpay_order_id,
              };
              Post(
                'v1/internal/w/order-signature-verification',
                verificationData,
              )
                .then((resp) => {
                  if (resp.data.response.is_verified == true) {
                    setLoading(false);
                    RetryConfirmation(confirmationData);
                  } else {
                    console.log('err');
                  }
                })
                .catch((err) => {
                  console.log(err.response);
                });
            },
            theme: {
              color: '#686CFD',
            },
            modal: {
              ondismiss: (res) => {
                setLoading(false);
              },
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        }
      })
      .catch((err) => {
        console.log('err');
      });
  };

  const RetryConfirmation = (confirmationData) => {
    let retryCount = 1;

    const retry = (confirmationData) => {
      retryCount++;
      Get('v1/internal/w/is-order-confirmed', confirmationData)
        .then((resp) => {
          setLoading(false);
          setSuccess(true);
        })
        .catch((err) => {
          setTimeout(() => {
            if (retryCount < 7) retry(confirmationData);
          }, 5000);
        });
    };
    retry(confirmationData);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
    history.push('/user-dashboard?modal=buy-minutes');
  };

  const closePopup = () => {
    setCount(1);
    setTotal(minPackDetails?.amount / 100 || 0);
    setIsPopupOpen(false);
    setLoading(false);
    history.push('/user-dashboard');
  };

  const closePopupWithUpdate = () => {
    setIsPopupOpen(false);
    setTotal(minPackDetails?.amount / 100 || 0);
    setCount(1);
    setSuccess(false);
    setLoading(false);
    props.reMountComponent();
  };

  return (
    <div>
      <Popup open={isPopupOpen} closeOnDocumentClick={false} closeOnEscape={false}
        {...{ contentStyle }}
      >
        <div className="buy-minute-popup">
          <img
            className='close-minute-popup'
            src={crossImg}
            onClick={success ? closePopupWithUpdate : closePopup}
          />
          {success ?
            <React.Fragment>
              <img src={SuccessIcon} className="buy-minute-success" />
              <h1>{100 * count} minutes added</h1>
              <button
                onClick={closePopupWithUpdate}
                disabled={loading}
                className='close-minute-popup-button'
              >Close</button>
            </React.Fragment>
            :
            <React.Fragment>
              <h1>Add more call minutes</h1>
              <h2>Call minute packs can be added for more secure call-back and incoming call minutes</h2>
              <h3><strong>100</strong> minutes for <strong style={{ color: '#ed3833' }}>₹60</strong></h3>
              <div>
                <img src={Subtract} onClick={onSubtract} />
                <h4>{count}</h4>
                <img src={Add} onClick={onAdd} />
              </div>
              <button
                onClick={Proceed}
                disabled={loading}
                className='buy-minute-popup-button'
              >Buy<strong style={{ margin: 'auto 8px' }}>{100 * count}</strong>minutes for ₹ <strong>{' '} {total}</strong>{'  '}<img style={{ marginLeft: '16px' }} src={ProceedIcon} /></button>
              <button
                onClick={success ? closePopupWithUpdate : closePopup}
                disabled={loading}
                className='close-minute-popup-button'
              >Close</button>
            </React.Fragment>
          }
        </div>
      </Popup>
      <span>
        <h5 
        style={{ color: '#ed3833', cursor: 'pointer', opacity: "0.5" }} 
        // onClick={openPopup}
        >
          <strong> BUY MINUTES</strong>
        </h5>
        <span className="PlanUpgradeText">
          Add more secure call-back and incoming call minutes
        </span>
      </span>
    </div>
  )
}