/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import moment from 'moment';
import NavBar from '../components/NavBar';
import Steps from '../components/registrationStepBar';
import '../../assets/css/checkout.css';
import { Get, Post, Put } from '../../utils/httpReguests';
import ChangePlan from '../changePlanModal';
import GoogleTag from '../components/GoogleTag';
import RazorpayImage from '../../assets/images/razorpay.png';
import BlackLine from '../../assets/images/black_line.png';
import Proceed from '../../assets/images/proceed_white.png';
import StarHalf from '../../assets/images/star_half.png';
import StarFull from '../../assets/images/star_full.png';
import GstDetails from '../components/GSTPopup';
import CheckmarkGreen from '../../assets/images/checkmark_circle_green.png';
import Preloader from '../../assets/images/preloader_circle_red.gif';
import whitePreloader from '../../assets/images/rolling_white_loader.gif';
import Loading from '../../components/Loading';
import CountDownBar from '../components/CountdownBar';
import ErrorCircle from '../../assets/images/error_circle.png';
import CheckBoxChecked from '../../assets/images/checkbox_checked.png';
import CheckBoxUnchecked from '../../assets/images/checkbox_unchecked.png';
import ProgressIndicator from '../components/ProgressIndicator';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

export function PaymentConfirmationPopup(props) {
  return (
    <Popup open={props.open} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className=""
        style={{
          minWidth: '300px',
          textAlign: 'center',
          background: 'white',
          height: 'auto',
          padding: '30px',
          borderRadius: '6px',
        }}
      >
        {props.count}
        <img src={Preloader} style={{ width: '64x', height: '64px' }} />
        <p style={{ margin: '0', fontWeight: '500' }}>
          We are processing your payment
        </p>
        <p style={{ margin: '0', fontWeight: '500' }}>
          Please do not refresh or close this tab
        </p>
      </div>
    </Popup>
  );
}

export function NumberCard(props) {
  const history = useHistory();

  let doosraNumberType;
  switch (sessionStorage.getItem('selectedNumberType')) {
    case 'VIP':
      doosraNumberType = '1 VIP Number ⭐⭐⭐⭐⭐';
      break;
    case 'SIMILAR':
      doosraNumberType = '1 Similar Number';
      break;
    case 'PREMIUM':
      doosraNumberType = '1 Premium Number';
      break;
    case 'DEFAULT':
      doosraNumberType = '1 Regular Free Number';
      break;
  }

  let displayNumber;
  if (sessionStorage.getItem('selectedNumberType') === 'DEFAULT') {
    displayNumber = sessionStorage.getItem('defaultNumber');
  } else {
    displayNumber = sessionStorage.getItem('number');
  }

  const upgradeNumber = () => {
    history.push('/choose-number');
  };

  return (
    <div className="checkout-card" style={{ marginTop: '36px', marginBottom: '16px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>{doosraNumberType}</h1>
        <h2>
          &#8377;
          {props.amount}
        </h2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>{displayNumber ? displayNumber.substring(2) : null}</h3>
        <h4
          onClick={upgradeNumber}
        >
          UPGRADE
        </h4>
      </div>

    </div>
  );
}

export function PlanCard(props) {
  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);
  let formattedPlan = props?.selectedPlan?.planName;
  if (formattedPlan) formattedPlan = formattedPlan.split('(')[0];
  return (
    <>
      <div className="checkout-card" style={{ marginBottom: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>
            {' '}
            {formattedPlan}
          </h1>
          <h2>
            &#8377;
            {props.amount}
          </h2>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>
            {'Validity: '}
            {props?.selectedPlan?.planValidity ? moment(props?.selectedPlan?.planValidity).format('Do MMM YYYY') : ''}
          </h3>
          <h4 onClick={() => {
            setChangePlanModalOpen(true);
          }}
          >
            SWITCH PLAN
          </h4>
        </div>
      </div>
      <ChangePlan
        closeModal={() => {
          setChangePlanModalOpen(false);
        }}
        isOpen={changePlanModalOpen}
        switchPlan={() => {
          props.switchPlan();
        }}
        currentPlanId={props.selectedPlan.planId}
      />
    </>
  );
}

export function InputCard(props) {
  if (props.success) {
    return (
      <div style={{ marginBottom: '14px' }}>
        <div className="checkout-card">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={CheckmarkGreen} style={{ marginRight: '8px' }} />
              <h6 style={{ color: '#00b42a' }}>{props.value}</h6>
            </div>
            {
              props.processing
                ? <img src={Preloader} style={{ width: '30px' }} />
                : <h4 onClick={props.remove}>REMOVE</h4>
            }
          </div>
        </div>
        <p className="success-msg">{props.success}</p>
      </div>
    );
  }

  if (props.error) {
    return (
      <div style={{ marginBottom: '14px' }}>
        <div className="checkout-card">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <h6>
              <img src={ErrorCircle} />
              {' '}
              {props.value}
            </h6>
            <h4 onClick={props.remove}>REMOVE</h4>
          </div>
        </div>
        <p className="error-msg">{props.error}</p>
      </div>
    );
  }
  return (
    <div className="checkout-input-card" style={{ marginBottom: '8px' }}>
      <div style={{ display: 'flex' }}>
        <input
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => props.changeValue(e.target.value)}
          style={props.value !== '' ? { color: 'black', border: 'solid 2px #000' } : {}}
        />
        {
          props.processing
            ? (
              <button style={props.value !== '' ? { backgroundColor: 'black' } : {}}>
                <img src={whitePreloader} style={{ width: '24px' }} />
              </button>
            )
            : <button onClick={props.apply} style={props.value !== '' ? { backgroundColor: 'black' } : {}}>APPLY</button>
        }
      </div>
    </div>
  );
}

export function BillCard(props) {
  return (
    <div className="checkout-card" style={{ marginTop: '16px', marginBottom: '24px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>
          Total Bill
          <span> (Incl 18% GST)</span>
        </h2>
        {
          props.discountApplied.total_amount
            ? (
              <h2>
                <span style={{ textDecoration: 'line-through', marginRight: '8px' }}>
                  &#8377;
                  {props.amount}
                </span>
                <span>
                  &#8377;
                  {props.finalAmount}
                </span>
              </h2>
            )
            : (
              <h2>
                &#8377;
                {props.amount}
              </h2>
            )
        }
      </div>
      {props.discountApplied.total_amount
        ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 className="discount-applied">
              {
                props?.discountApplied?.discount_percentage && props?.discountApplied?.discount_percentage !== 0
                  ? `${props.discountApplied.discount_percentage}% discount applied`
                  : (
                    <span>
                      &#8377;
                      {props.discountApplied.flat_amount}
                    </span>
                  )
              }
            </h2>
            <h2 className="discount-applied">
              - &#8377;
              {props?.discountApplied?.discount_applied / 100 || 0}
            </h2>
          </div>
        )
        : (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5>No Discount Applied</h5>
          </div>
        )}
    </div>
  );
}
export function DemoCard(props) {
  const [demoStatus, setDemoStatus] = useState(false);

  useEffect(() => {
    getDemoStatus();
  }, []);

  const getDemoStatus = () => {
    Get('v1/internal/w/demo')
      .then((res) => res.data.response)
      .then((res) => {
        setDemoStatus(res?.demo || false);
      })
      .catch((err) => {
        // toast.error('Error occured while fetching demo status');
      });
  };

  const updateDemoStatus = () => {
    setDemoStatus(!demoStatus);
    Post('v1/internal/w/demo', { demo: !demoStatus })
      .then((res) => {
        // toast.success('Applied for demo successfully');
      })
      .catch((err) => {
        setDemoStatus(demoStatus);
        // toast.error('Error occured while updating demo status');
      });
  };

  return (
    <div className="checkout-card" style={{ marginBottom: '8px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h6>I'd like a product demo</h6>
        {
          demoStatus
            ? <img onClick={updateDemoStatus} src={CheckBoxChecked} />
            : <img onClick={updateDemoStatus} src={CheckBoxUnchecked} />
        }

      </div>
    </div>
  );
}

export function GstCard(props) {
  return (
    <div className="checkout-card" style={{ marginBottom: '40px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h6>Add GST details to my bill</h6>
        <GstDetails />
      </div>
    </div>
  );
}

export function ReviewContainer() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    Get('v1/internal/w/customer-reviews')
      .then((res) => { setReviews(res?.data?.response?.reviews); })
      .catch((err) => { console.log(err); });
  }, []);

  const Rating = (num) => {
    if (!num) return <div />;
    num += '';
    num = num.split('.');
    const content = [];
    for (let i = 0; i < parseInt(num[0]); i++) {
      content.push(<img src={StarFull} />);
    }

    return (
      <>
        {content}
        {
          num[1] && <img src={StarHalf} />
        }
      </>
    );
  };

  if (!reviews || reviews?.length === 0) return <div />;

  return (
    <div className="review-container">
      <h1 style={{ margin: '0' }}>Unsure about how Doosra will work for you?</h1>
      <h1>Here are some customer reviews to help you decide</h1>
      {
        reviews.map((item) => (
          <div>
            <p>{Rating(item.ratings)}</p>
            <h4>{item.user}</h4>
            <h2>{item.header}</h2>
            <h3>{item.description}</h3>
          </div>
        ))
      }
      <h6>Some identities hidden for privacy, others consent taken</h6>
      <h5 onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }}>Back to top</h5>
    </div>
  );
}

export default function CheckoutNew(props) {
  const [discountCode, setDiscountCode] = useState('');
  const [discountCodeSuccess, setDiscountCodeSuccess] = useState({});
  const [discountCodeError, setDiscountCodeError] = useState('');
  const [updatingDiscountCode, setUpdatingDiscountCode] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [referralCodeSuccess, setReferralCodeSuccess] = useState(false);
  const [referralCodeError, setReferralCodeError] = useState('');
  const [updatingReferralCode, setUpdatingReferralCode] = useState(false);
  const [plans, setPlans] = useState({});
  const [changePlanModalOpen, setChangePlanModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const history = useHistory();
  const [paymentError, setPaymentError] = useState('');
  const [loading, setLoading] = useState(false);
  const [
    isPaymentConfirmationLoaderVisible,
    setIsPaymentConfirmationLoaderVisible,
  ] = useState(false);

  useEffect(() => {
    window.dataLayer.push({
      event: 'Checkout',
      pagePath: '/checkout',
      pageTitle: 'Checkout',
    });
    GoogleTag();
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_KEY, null, options);
    ReactPixel.track('InitialCheckout', {});
  }, []);

  let displayNumber;
  if (sessionStorage.getItem('selectedNumberType') === 'DEFAULT') {
    displayNumber = sessionStorage.getItem('defaultNumber');
  } else {
    displayNumber = sessionStorage.getItem('number');
  }

  useEffect(() => {
    if (!localStorage.getItem('doosra-user-sessionToken')) {
      history.push('/choose-plan');
    }

    if (!sessionStorage.getItem('selectedNumberType')) {
      history.push('/choose-number');
    }
    if (
      localStorage.getItem('doosra-user-id')
      && localStorage.getItem('doosra-user-status') === 'ACTIVE'
    ) {
      history.push('/user-dashboard');
    }

    if (
      localStorage.getItem('doosra-user-sessionToken')
      && !sessionStorage.getItem('selectedNumberType')
    ) {
      history.push('/choose-number');
    }
    window.dataLayer.push({
      event: 'Checkout',
      pagePath: '/checkout',
      pageTitle: 'Checkout',
    });
    setLoading(true);
    setTimeout(() => {
      fetchPaymentDetails();
    }, 3000);
  }, [sessionStorage.getItem('selectedPlan')]);

  useEffect(() => {
    if (!sessionStorage.getItem('selectedPlan')) {
      Get('plans', { source: 'WEB' })
        .then((res) => res.data.response)
        .then((res) => {
          sessionStorage.setItem('selectedPlan', res?.pro[1]?.plan_id);
        })
        .catch((err) => {
          setPaymentError(err?.response?.data?.error?.reason || 'An error occured');
        });
    }
  }, []);

  const fetchPaymentDetails = () => {
    if (!localStorage.getItem('doosra-user-id')) return;
    const data = { source: 'WEB' };
    if (sessionStorage.getItem('selectedPlan')) {
      data.plan_id = sessionStorage.getItem('selectedPlan');
    }
    if (sessionStorage.getItem('selectedNumberType') === 'DEFAULT') {
      data.v_mobile_no = sessionStorage.getItem('defaultNumber');
    } else {
      data.v_mobile_no = sessionStorage.getItem('number');
    }
    Post(`user/${localStorage.getItem('doosra-user-id')}/payments`, data)
      .then((res) => {
        if (res?.data?.response) {
          const data = {
            planId: res?.data?.response?.plan?.plan_id,
            planAmount: res?.data?.response?.plan?.amount,
            totalAmount: res?.data?.response?.total_amount,
            doosraNoAmount: res?.data?.response?.v_mobile_no?.amount,
            planName: res.data.response.plan.plan_type,
            doosraNumberPrice: res?.data?.response?.v_mobile_no?.amount / 100,
            doosraNumberType: res?.data?.response?.v_mobile_no?.type,
            planType: res?.data?.response?.plan?.short_title.split('_').pop(),
            planValidity: res?.data?.response?.plan?.plan_validity,
          };
          setLoading(false);
          setSelectedPlan(data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error?.reason === 'Your selection has timed out. Please try again.') {
          setLoading(true);
          const doosraNumber = sessionStorage.getItem('number') || sessionStorage.getItem('defaultNumber');
          Put(
            `user/${localStorage.getItem('doosra-user-id')}/vmn`,
            {},
            { v_mobile_no: doosraNumber },
          )
            .then((res) => {
              fetchPaymentDetails();
            })
            .catch((err) => {
              setPaymentError(err?.response?.data?.error?.reason);
              setLoading(false);
            });
        } else {
          setPaymentError(err?.response?.data?.error?.reason);
          setLoading(false);
        }
      });
  };

  const applyReferralCode = () => {
    if (referralCode === '') return;
    const userId = localStorage.getItem('doosra-user-id');
    setUpdatingReferralCode(true);
    Put(
      `user/${userId}/referral_code`,
      {},
      { referral_code: referralCode, action: 'APPLY' },
    )
      .then((res) => {
        setReferralCodeSuccess(res?.data?.response?.status);
        setReferralCodeError('');
        setUpdatingReferralCode(false);
      })
      .catch((err) => {
        setReferralCodeError(err?.response?.data?.error?.reason);
        setReferralCodeSuccess('');
        setUpdatingReferralCode(false);
      });
  };

  const removeReferralCode = () => {
    const userId = localStorage.getItem('doosra-user-id');
    setUpdatingReferralCode(true);
    Put(
      `user/${userId}/referral_code`,
      {},
      { referral_code: referralCode, action: 'REMOVE' },
    )
      .then((res) => {
        setReferralCode('');
        setReferralCodeError('');
        setReferralCodeSuccess('');
        setUpdatingReferralCode(false);
      })
      .catch((err) => {
        setReferralCodeError(err?.response?.data?.error?.reason);
        setUpdatingReferralCode(false);
      });
  };

  const applyDiscountCode = () => {
    if (discountCode === '') return;
    const data = {
      discount_coupon: discountCode,
      amount: selectedPlan.planAmount / 100,
      action: 'APPLY',
      v_mobile_no: displayNumber,
      plan_id: selectedPlan.planId,
    };
    setUpdatingDiscountCode(true);
    Post(`user/${localStorage.getItem('doosra-user-id')}/discount`, data)
      .then((res) => {
        setDiscountCodeSuccess(res?.data?.response);
        setDiscountCodeError('');
        setUpdatingDiscountCode(false);
      })
      .catch((err) => {
        setDiscountCodeError(err?.response?.data?.error?.reason);
        setDiscountCodeSuccess('');
        setUpdatingDiscountCode(false);
      });
  };

  const removeDiscountCode = () => {
    const data = {
      discount_coupon: discountCode,
      amount: selectedPlan.planAmount / 100,
      action: 'REMOVE',
      v_mobile_no: displayNumber,
      plan_id: selectedPlan.planId,
    };
    setUpdatingDiscountCode(true);
    Post(`user/${localStorage.getItem('doosra-user-id')}/discount`, data)
      .then((res) => {
        setDiscountCodeError('');
        setDiscountCode('');
        setDiscountCodeSuccess('');
        setUpdatingDiscountCode(false);
      })
      .catch((err) => {
        setDiscountCodeError(err?.response?.data?.error?.reason);
        setDiscountCodeSuccess('');
        setUpdatingDiscountCode(false);
      });
  };

  const switchPlan = () => {
    setDiscountCodeError('');
    setDiscountCodeSuccess('');
    setDiscountCode('');
    setSelectedPlan({ ...selectedPlan });
  };

  const changeValue = (type, val) => {
    if (type === 'REFERRAL') {
      setReferralCode(val);
    }
    if (type === 'DISCOUNT') {
      setDiscountCode(val);
    }
  };

  const checkoutHandler = () => {
    const data = {
      plan_id: selectedPlan.planId,
      is_initial: true,
      user_id: localStorage.getItem('doosra-user-id'),
      source: 'WEB',
    };

    if (discountCodeSuccess.discount_applied) {
      data.discount = discountCodeSuccess.discount_applied / 100;
      data.discount_percent = discountCodeSuccess.discount_percentage;
      data.coupon = discountCodeSuccess.discount_coupon;
      data.coupon_id = discountCodeSuccess.discount_coupon_id;
    }
    order(data);
  };

  const FailureRedirect = (data) => {
    history.push({
      pathname: '/failure',
      state: {
        data: data || '',
      },
    });
  };

  let finalAmount = 0;
  if (discountCodeSuccess?.total_amount) {
    finalAmount = discountCodeSuccess?.total_amount / 100;
  } else {
    finalAmount = selectedPlan?.totalAmount / 100;
    if (discountCodeSuccess?.total_amount === 0) {
      finalAmount = 0;
    }
  }

  const PaymentConfirmation = (data, confirmationData) => {
    setIsPaymentConfirmationLoaderVisible(true);
    let count = 1;
    const RetryConfirmation = (data, confirmationData) => {
      count += 1;
      Get('v1/internal/w/is-order-confirmed', confirmationData)
        .then((resp) => {
          if (resp.data.response.is_order_confirmed) {
            history.push({
              pathname: '/success',
              state: {
                event: 'purchase',
                order_value: finalAmount || '',
                enhanced_conversion_data: {
                  email: sessionStorage.getItem('userEmail') || '',
                },
                purchaseValue: finalAmount || '',
                planType: selectedPlan?.planType || '',
                planName: selectedPlan?.planName || '',
                discountAmount: data?.discount || '',
                numberType: selectedPlan?.doosraNumberType || '',
                numberPrice: selectedPlan?.doosraNumberPrice || '',
                planAmount: selectedPlan?.planAmount / 100 || '',
              },
            });
          } else {
            if (count >= 7) return FailureRedirect(data);
            setTimeout(() => {
              RetryConfirmation(data, confirmationData);
            }, 5000);
          }
        })
        .catch((err) => {
          if (count >= 7) return FailureRedirect(data);
          setTimeout(() => {
            RetryConfirmation(data, confirmationData);
          }, 5000);
        });
    };
    RetryConfirmation(data, confirmationData);
  };

  const order = (data) => {
    Post('v1/internal/w/order', data)
      .then((res) => {
        if (res?.data?.response?.status == 'payment not required') {
          return history.push({
            pathname: '/success',
            state: {
              event: 'purchase',
              order_value: 0,
              enhanced_conversion_data: {
                email: sessionStorage.getItem('userEmail') || '',
              },
              purchaseValue: 0,
              planType: selectedPlan?.planType || '',
              planName: selectedPlan?.planName || '',
              discountAmount: data?.discount || '',
              numberType: selectedPlan?.doosraNumberType || '',
              numberPrice: selectedPlan?.doosraNumberPrice || '',
              planAmount: selectedPlan?.planAmount / 100 || '',
            },
          });
        }
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          name: 'Doosra',
          description: 'payment',
          order_id: res?.data?.response?.order_details?.order_id,
          prefill: {
            email: data.email,
          },
          handler(response) {
            const verificationData = {
              payment_id: response.razorpay_payment_id,
              signature: response.razorpay_signature,
              order_id: response.razorpay_order_id,
            };
            const confirmationData = {
              order_id: response.razorpay_order_id,
              user_id: data.user_id,
            };
            data.order_id = confirmationData.order_id;
            Post('v1/internal/w/order-signature-verification', verificationData)
              .then((resp) => {
                if (resp.data.response.is_verified == true) {
                  PaymentConfirmation(data, confirmationData);
                } else {
                  console.log(resp.data.response);
                }
              })
              .catch((err) => {
                setPaymentError(err?.response?.data?.error?.reason || 'An error occured');
              });
          },
          theme: {
            color: '#686CFD',
          },
          retry: {
            enabled: true,
            max_count: 2,
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      })
      .catch((err) => {
        setPaymentError(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  return (
    <div>
      <NavBar />
      {loading && <Loading popup />}

      <CountDownBar />
      <ProgressIndicator currentStep={3} />

      <div className="checkout-container">
        <div className="checkout-heading">
          <img src={BlackLine} />
          <h1>Your bill details</h1>
          <img src={BlackLine} />
        </div>
        <h3>You’re one step away from joining the club!</h3>
        <NumberCard amount={selectedPlan.doosraNoAmount / 100 || 0} />
        <PlanCard selectedPlan={selectedPlan} switchPlan={switchPlan} amount={selectedPlan.planAmount / 100 || 0} />
        <InputCard
          type="REFERRAL"
          placeholder="Have a referral code?"
          error={referralCodeError}
          success={referralCodeSuccess}
          apply={applyReferralCode}
          remove={removeReferralCode}
          value={referralCode}
          processing={updatingReferralCode}
          changeValue={(val) => changeValue('REFERRAL', val)}
        />
        <InputCard
          type="DISCOUNT"
          placeholder="Have a discount code?"
          error={discountCodeError}
          success={discountCodeSuccess.discount_applied ? 'Discount code was applied successfully' : null}
          apply={applyDiscountCode}
          remove={removeDiscountCode}
          value={discountCode}
          processing={updatingDiscountCode}
          changeValue={(val) => changeValue('DISCOUNT', val)}
        />
        <BillCard amount={selectedPlan.totalAmount / 100 || 0} finalAmount={finalAmount} discountApplied={discountCodeSuccess} />
        <DemoCard />
        <GstCard />
        <button className="checkout-button" onClick={checkoutHandler}>
          PAY 	&#8377;
          {' '}
          {finalAmount}
          {' '}
          <img src={Proceed} style={{ marginLeft: '16px' }} />
        </button>
        <h5>All Doosra plans are non-refundable</h5>
        <h5 style={{ marginBottom: '24px' }}>Write to support@doosra.io for any queries</h5>
        <h6>Secure payments powered by</h6>
        <img src={RazorpayImage} />
      </div>
      <ReviewContainer />
      <PaymentConfirmationPopup open={isPaymentConfirmationLoaderVisible} />
    </div>
  );
}
