import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InCNavBar from './InCNavBar';
import NavbarInc from './InCNavBar';
class PageNotFound extends Component {
  render() {
    return (
      <React.Fragment>
        <InCNavBar />
        <div className="CenterContainer" >
          <h1 className="NotFound">404</h1>
          <h3 className="NotFoundText">Page not found</h3>
          <div>
            <Link className="RedirectLink" to="/login">Go back to Login</Link>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default PageNotFound;