/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import cancelButton from '../../assets/images/cancelButton.png';
import { ReactComponent as ForwardArrow } from '../../assets/images/Group 1228.svg';
import { Get, Post } from '../../utils/httpReguests';
import { sess_get, sess_post } from '../../utils/HttpRequests';
import Loading from '../Loading';
import ErrorCard from '../others/Error';

const rupees = require('../../assets/images/rupees.svg');
const unlock = require('../../assets/images/unlock.svg');
const checkmarkCircle = require('../../assets/images/red_tick_circle.svg');
const crossImg = require('../../assets/images/cross.svg');
const CheckmarkGreenBig = require('../../assets/images/checkmark-circle-green-big.svg');
const ErrorAlert = require('../../assets/images/error_alert.png');
const checkmarkCircleGreen = require('../../assets/images/checkmark_circle.svg');

export default function UpgradeToDoosraPro(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [proPlans, setProPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [discountCode, setDiscountCode] = useState('');
  const [discount, setDiscount] = useState({});
  const [discountCodeError, setDiscountCodeError] = useState('');
  const [upgradeData, setUpgradeData] = useState({});
  const [isPlanPurchaseNeeded, setIsPlanPurchaseNeeded] = useState(false);
  const [step, setStep] = useState(1);
  const [planPurchaseError, setPlanPurchaseError] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const openPopup = () => {
    setIsPopupOpen(true);
    history.push('/user-dashboard?modal=upgrade-to-pro');
  };

  useEffect(() => {
    const data = {};
    if (props?.profileData?.subscription_id) data.subscription_id = props.profileData.subscription_id;

    Get('v1/internal/w/upgrade-pro-amount', data)
      .then((res) => {
        setUpgradeData(res?.data?.response?.obj);
      })
      .catch((err) => {
        if (err?.response?.data?.error?.reason?.indexOf('validity remaining') !== -1) {
          setPlanPurchaseError(err?.response?.data?.error?.reason);
        } else {
          setError(err?.response?.data?.error?.reason);
        }
      });

    Get('plans', { source: 'WEB' })
      .then((res) => {
        setProPlans(res?.data?.response?.pro);
        setSelectedPlan(res?.data?.response?.pro[0]);
      })
      .catch((err) => {
        console.log('error fetching plans list');
      });
    const isModalOpen = new URLSearchParams(window.location.search).get('modal');
    if (isModalOpen === 'upgrade-to-pro') {
      setIsPopupOpen(true);
    }
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
    setIsPlanPurchaseNeeded(false);
    setError('');
    setDiscountCode('');
    setDiscountCodeError('');
    setDiscount({});
    // setUpgradeData({});
    history.push('/user-dashboard');
    if (step === 4) props.remountComponent();
    setStep(1);
  };

  const clearDiscountData = () => {
    setDiscountCode('');
    setDiscountCodeError('');
    setDiscount({});
  };

  const applyDiscountCode = async () => {
    const data = {
      discount_coupon: discountCode,
      amount: selectedPlan?.display_amount,
      action: 'APPLY',
      v_mobile_no: props.profileData.v_mobile_no,
      plan_id: selectedPlan?.plan_id,
    };

    if (!selectedPlan?.plan_id) return setDiscountCodeError('select plan');
    if (discountCode == '') return setDiscountCodeError('Provide code');

    await Post(`user/${localStorage.getItem('doosra-user-id')}/discount`, data)
      .then((res) => {
        setDiscount(res.data.response);
        setError('');
      })
      .catch((err) => {
        setDiscountCodeError(
          err?.response?.data?.error?.reason || 'unable to apply discount code',
        );
      });
  };

  const removeDiscountCode = async () => {
    const data = {
      discount_coupon: discountCode,
      amount: selectedPlan?.display_amount,
      action: 'APPLY',
      v_mobile_no: props.profileData.v_mobile_no,
      plan_id: selectedPlan?.plan_id,
    };
    Post(`user/${localStorage.getItem('doosra-user-id')}/discount`, data)
      .then((res) => {
        setDiscountCodeError('');
        setDiscount({});
        setDiscountCode('');
        setError('');
      })
      .catch((err) => {
        setDiscountCodeError(
          err?.response?.data?.error?.reason || 'unable to remove discount code',
        );
      });
  };

  const selectPlan = (data) => {
    setSelectedPlan(data);
  };

  const Proceed = () => {
    const { userId } = props.profileData;
    const data = { source: 'WEB' };

    if (props?.profileData?.subscription_Id) {
      data.subscription_id = props?.profileData?.subscription_Id;
    }

    if (isPlanPurchaseNeeded) {
      ProceedWithPlanPurchase();
    } else if (upgradeData?.pro_amount === 0) {
      Post('v1/internal/w/free-upgrade-pro-order', data).then((res) => {
        if (res?.data?.response?.order_details?.is_free_upgrade) {
          setStep(4);
        }
      });
    } else {
      Post('v1/internal/w/upgrade-pro-order', data)
        .then((resp) => {
          const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            name: 'Doosra',
            description: 'payment',
            order_id: resp.data.response.order_details.order_id,
            prefill: {
              name: props?.profileData?.zvr_name,
              email: props?.profileData?.zvr_email,
              contact: props?.profileData?.zvr_mobile_no,
            },

            handler: (response) => {
              const verificationData = {
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                user_id: userId,
                order_id: response.razorpay_order_id,
              };
              const confirmationData = {
                user_id: userId,
                order_id: response.razorpay_order_id,
              };
              if (props?.profileData?.subscription_Id) {
                verificationData.subscription_id = props?.profileData?.subscription_Id;
                confirmationData.subscription_id = props?.profileData?.subscription_Id;
                delete verificationData.order_id;
                delete verificationData.subscription_id;
              }
              Post(
                'v1/internal/w/pro-order-signature-verification',
                verificationData,
              )
                .then((resp) => {
                  if (resp.data.response == true) {
                    checkPaymentStatus(confirmationData);
                  } else {
                    console.log('err');
                  }
                })
                .catch((err) => {
                  console.log(err.response);
                });
            },
            theme: {
              color: '#686CFD',
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        })
        .catch((err) => {
          setStep(2);
          setPlanPurchaseError(err?.response?.data?.error?.reason);
          setIsPlanPurchaseNeeded(true);
        });
    }
  };

  const checkPaymentStatus = (confirmationData, isAdditionalPurchase) => {
    setStep(3);
    let retryCount = 1;
    const recheckPaymentStatus = (confirmationData) => {
      retryCount += 1;
      if (isAdditionalPurchase) {
        sess_get('w/is-order-confirmed', localStorage.getItem('doosra-user-sessionToken'), confirmationData)
          .then((resp) => {
            setStep(4);
          })
          .catch((err) => {
            if (retryCount === 7) {
              setStep(2);
              setError(err?.response?.data?.error?.reason || 'An error occured');
              return;
            }
            setTimeout(() => {
              recheckPaymentStatus(confirmationData);
            }, 5000);
          });
      } else {
        Get('v1/internal/w/upgrade-pro-order-confirmed', confirmationData)
          .then((resp) => {
            setStep(4);
          })
          .catch((err) => {
            if (retryCount === 7) {
              setStep(2);
              setError(err?.response?.data?.error?.reason || 'An error occured');
              return;
            }
            setTimeout(() => {
              recheckPaymentStatus(confirmationData);
            }, 5000);
          });
      }
    };
    recheckPaymentStatus(confirmationData);
  };

  const ProceedWithPlanPurchase = () => {
    // window.dataLayer.push({ 'userId': userId })
    const data = {
      upgrade_to_pro: true,
      plan_id: selectedPlan.plan_id,
      is_initial: false,
      user_id: props.profileData.user_id,
      name: props.profileData.zvr_name || '',
      email: props.profileData.zvr_email || '',
      contact: props.profileData.zvr_mobile_no || '',
    };
    if (discount?.discount_applied) {
      data.discount = discount?.discount_applied / 100;
      data.coupon = discount?.discount_coupon;
      data.coupon_id = discount?.discount_coupon_id;
    }

    if (props.autoRenew == true) {
      generateSubscription(data);
    } else {
      generateOrder(data);
    }
  };

  const generateOrder = async (data) => {
    const session_token = localStorage.getItem('doosra-user-sessionToken');

    await sess_post('w/order', session_token, data)
      .then((resp) => {
        if (resp.data.success) {
          if (resp.data.response.status == 'payment not required') {
            setStep(4); // this.props.switchScreenHandler(7);
          } else {
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY,
              name: 'Doosra',
              description: 'payment',
              order_id: resp.data.response.order_details.order_id,
              prefill: {
                name: data.name,
                email: data.email,
                contact: data.contact,
              },
              handler: (response) => {
                const verificationData = {
                  payment_id: response.razorpay_payment_id,
                  signature: response.razorpay_signature,
                  user_id: data.user_id,
                  order_id: response.razorpay_order_id,
                };
                const confirmationData = {
                  user_id: data.user_id,
                  order_id: response.razorpay_order_id,
                };
                sess_post(
                  'w/order-signature-verification',
                  session_token,
                  verificationData,
                )
                  .then((resp) => {
                    if (resp.data.response.is_verified == true) {
                      checkPaymentStatus(confirmationData, true);
                    } else {
                      console.log('err');
                    }
                  })
                  .catch((err) => {
                    console.log(err.response);
                  });
              },
              theme: {
                color: '#686CFD',
              },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const generateSubscription = async (data) => {
    await Post('v1/internal/w/subscription', data)
      .then((resp) => {
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          name: 'Doosra',
          description: 'payment',
          subscription_id: resp.data.response.subscription.id,
          'data-prefill': data.prefill,
          handler: (response) => {
            const verificationData = {
              payment_id: response.razorpay_payment_id,
              signature: response.razorpay_signature,
              user_id: data.user_id,
              subscription_id: response.razorpay_subscription_id,
            };
            const confirmationData = {
              user_id: data.user_id,
              subscription_id: response.razorpay_subscription_id,
            };
            Post(
              'v1/internal/w/subscription-signature-verification',
              verificationData,
            )
              .then((resp) => {
                if (resp.data.response.is_verified == true) {
                  sess_get(
                    'v1/internal/w/is-subscription-confirmed',
                    confirmationData,
                  )
                    .then((resp) => {
                      setStep(4);
                    })
                    .catch((err) => {
                      console.log(err.response);
                    });
                } else {
                  console.log('err');
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          },
          theme: {
            color: '#686CFD',
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let proceedAmount = 0;
  if (upgradeData?.pro_amount) {
    proceedAmount = upgradeData?.pro_amount / 100;
  }
  if (selectedPlan?.display_amount) {
    proceedAmount = upgradeData?.pro_amount / 100 + selectedPlan?.display_amount;
  }

  if (discount?.discount_applied) {
    proceedAmount = upgradeData?.pro_amount / 100 + discount?.total_amount / 100;
  }

  let popper;

  switch (step) {
    case 1:
      popper = (
        <Popup open={isPopupOpen} closeOnDocumentClick={false}>
          <div className="ModalTwo ModalScrollLarge">
            <div style={{ textAlign: 'right', padding: '10px 10px 0 10px ' }}>
              <img
                className=""
                style={{ width: '20px', cursor: 'pointer' }}
                src={crossImg}
                onClick={closePopup}
              />
            </div>
            <div className="ModalBodyTwo">
              <div className="ModalTwoTitleContainer">
                <p className="ModalTwoTitle">
                  Upgrade To Doosra
                  {' '}
                  <span style={{ fontStyle: 'italic', color: '#ed3833' }}>
                    Pro
                  </span>
                </p>
                <p className="ModalTwoSubTitle">
                  Experience the best Doosra has to offer with Pro
                </p>
              </div>
              <div className="ProductSelectCapsuleHolder">
                <span className="ProductSelectCapsule CapsuleSelected">
                  <span className="ProductSelectCapsuleHeader CapsuleSelectedText">
                    DOOSRA
                    <span className="ProductSelcetCapsuleHeaderPro CapsuleSelectedSubText">
                      {' '}
                      Pro
                    </span>
                  </span>
                </span>
              </div>

              {upgradeData?.plan_id === proPlans[1]?.plan_id ? (
                <div className="ItemSelectedBorder OptionBox">
                  Half Yearly
                  {' '}
                  <img src={rupees} className="RupeesIcon" />
                  {upgradeData?.pro_amount / 100}
                </div>
              ) : null}
              {upgradeData?.plan_id === proPlans[0]?.plan_id ? (
                <div className="ItemSelectedBorder OptionBox">
                  Annual
                  {' '}
                  <img src={rupees} className="RupeesIcon" />
                  {upgradeData?.pro_amount / 100}
                </div>
              ) : null}

              <p className="ProratedMessage">
                This is the prorated value for the remainder of your plan's
                validity.
              </p>
              {planPurchaseError ? (
                <ErrorCard error={planPurchaseError} />
              ) : null}
              <div className="WhatYouGet" style={{ paddingTop: '20px' }}>
                <h1>Doosra PRO Features</h1>
                <div className="WhatYouGetGrid">
                  <img src={checkmarkCircle} />
                  <p>Everything in Doosra Essential, plus…</p>
                  <img src={checkmarkCircle} />
                  <p>Turn off the call blocker indefinitely</p>
                  <img src={checkmarkCircle} />
                  <p>Multi-user login (upto 5 devices)</p>
                  <img src={checkmarkCircle} />
                  <p>Create Whatsapp, Telegram accounts</p>
                  <img src={checkmarkCircle} />
                  <p>No limit on Trusted Contacts</p>
                </div>
              </div>

              {error ? <ErrorCard error={error} /> : null}
              <div className="ModalButtonHolderTwo" style={{ padding: 0 }}>
                <button className="ModalButtonTwo" onClick={Proceed}>
                  <span
                    // className="ButtonTextTwo"
                    style={{ color: 'white' }}
                  >
                    {upgradeData.pro_amount
                      ? `Pay INR ${upgradeData.pro_amount / 100}`
                      : 'Proceed'}
                  </span>
                  <ForwardArrow
                    style={{ 'margin-left': '20px', height: '24px' }}
                  />
                </button>
                <button className="ModalButtonTwo Invert" onClick={closePopup}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </Popup>
      );
      break;
    case 2:
      popper = (
        <Popup open={isPopupOpen} closeOnDocumentClick={false}>
          <div className="ModalTwo ModalScrollLarge">
            <div style={{ textAlign: 'right', padding: '10px 10px 0 10px ' }}>
              <img
                className=""
                style={{ width: '20px', cursor: 'pointer' }}
                src={crossImg}
                onClick={closePopup}
              />
            </div>
            <div className="ModalBodyTwo">
              <div className="ModalTwoTitleContainer">
                <p className="ModalTwoTitle">
                  Upgrade To Doosra
                  {' '}
                  <span style={{ fontStyle: 'italic', color: '#ed3833' }}>
                    Pro
                  </span>
                </p>
                <p className="ModalTwoSubTitle">
                  Experience the best Doosra has to offer with Pro
                </p>
              </div>
              <div>
                <div
                  className={
                    selectedPlan?.plan_id === proPlans[1]?.plan_id
                      ? 'ItemSelectedBorder OptionBox'
                      : 'ItemUnselectedBorder OptionBox'
                  }
                  onClick={() => {
                    selectPlan(proPlans[1]);
                  }}
                >
                  {' '}
                  Half Yearly
                  <img src={rupees} className="RupeesIcon" />
                  {proPlans[1]?.display_amount}
                </div>
                <div
                  className={
                    selectedPlan?.plan_id === proPlans[0]?.plan_id
                      ? 'ItemSelectedBorder OptionBox'
                      : 'ItemUnselectedBorder OptionBox'
                  }
                  onClick={() => {
                    selectPlan(proPlans[0]);
                  }}
                >
                  {' '}
                  Annual
                  <img src={rupees} className="RupeesIcon" />
                  {proPlans[0]?.display_amount}
                </div>
              </div>
              {planPurchaseError ? (
                <ErrorCard error={planPurchaseError} />
              ) : null}
              {isPlanPurchaseNeeded && (
                <>
                  <div style={{ textAlign: 'center' }}>
                    {discount?.discount_applied ? (
                      <div className="SuccessBox">
                        <span className="SuccessBoxRightImage">
                          <img src={checkmarkCircleGreen} />
                        </span>
                        <span
                          className="Success Green"
                          style={{ textAlign: 'center' }}
                        >
                          Code Applied successfully
                        </span>
                        <span className="SuccessBoxLeftImage">
                          <img
                            src={cancelButton}
                            className="DiscountCancelButton"
                            onClick={removeDiscountCode}
                          />
                        </span>
                      </div>
                    ) : (
                      <div className="DiscountCodeContainer">
                        <span className="discount-code-box">
                          <input
                            className="DiscountCodeInput DiscountCodeInputTwo"
                            type="text"
                            id="discountCode"
                            placeholder="Discount code"
                            onChange={(e) => {
                              setDiscountCode(e.target.value);
                              setDiscountCodeError('');
                              setDiscount({});
                            }}
                            value={discountCode}
                          />
                          <img
                            src={cancelButton}
                            className="DiscountCancelButton"
                            onClick={clearDiscountData}
                          />
                        </span>
                        <button
                          className="discount-apply-btn"
                          onClick={applyDiscountCode}
                        >
                          <span className="ButtonText">Apply</span>
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
              {discountCodeError && <ErrorCard error={discountCodeError} />}
              <div className="WhatYouGet" style={{ paddingTop: '20px' }}>
                <h1>Doosra PRO Features</h1>
                <div className="WhatYouGetGrid">
                  <img src={checkmarkCircle} />
                  <p>Everything in Doosra Essential, plus…</p>
                  <img src={checkmarkCircle} />
                  <p>Turn off the call blocker indefinitely</p>
                  <img src={checkmarkCircle} />
                  <p>Multi-user login (upto 5 devices)</p>
                  <img src={checkmarkCircle} />
                  <p>Create Whatsapp, Telegram accounts</p>
                  <img src={checkmarkCircle} />
                  <p>No limit on Trusted Contacts</p>
                </div>
              </div>
              {error ? <ErrorCard error={error} /> : null}
              <div className="ModalButtonHolderTwo" style={{ padding: 0 }}>
                <button className="ModalButtonTwo" onClick={Proceed}>
                  {proceedAmount || proceedAmount == '0' ? (
                    <>
                      <span
                        // className="ButtonTextTwo"
                        style={{ color: 'white' }}
                      >
                        {' '}
                        Pay INR
                        {' '}
                        {proceedAmount}
                      </span>
                      <ForwardArrow
                        style={{ 'margin-left': '20px', height: '24px' }}
                      />
                    </>
                  ) : (
                    'Proceed'
                  )}
                </button>
                <button className="ModalButtonTwo Invert" onClick={closePopup}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </Popup>
      );
      break;

    case 3:
      popper = (
        <Popup open={isPopupOpen} closeOnDocumentClick={false}>
          <div className="ModalTwo">
            <div className="ModalBody " style={{ textAlign: 'center' }}>
              <Loading />
              <p style={{ margin: '0', fontWeight: '500' }}>
                We are processing your payment
              </p>
              <p style={{ margin: '0', fontWeight: '500' }}>
                Please do not refresh or close this tab
              </p>
            </div>
          </div>
        </Popup>
      );
      break;
    case 4:
      popper = (
        <Popup open={isPopupOpen} closeOnDocumentClick={false}>
          <div className="ModalTwo">
            <img className="cross-button" src={crossImg} onClick={closePopup} />
            <div className="PlanUpgradeModalHeading">Payment Successful</div>
            <div className="ModalBody " style={{ textAlign: 'center' }}>
              <img src={CheckmarkGreenBig} style={{ marginBottom: '35px' }} />
            </div>
            <div className="ModalButtonHolderTwo">
              <p
                className="Invert"
                style={{ textAlign: 'center', cursor: 'pointer' }}
                onClick={closePopup}
              >
                Close
              </p>
            </div>
          </div>
        </Popup>
      );
      break;
  }

  if (!props.profileData.doosra_pro) {
    return (
      <>
        {!props.profileData?.free_trial && props.isLimitCard && (
          <span
            style={{
              textDecoration: 'underline',
              color: '#ee3048',
              cursor: 'pointer',
            }}
            onClick={openPopup}
          >
            Upgrade to Doosra Pro
          </span>
        )}
        {!props.profileData?.free_trial && !props.isLimitCard && (
          <div className="extra-box side-box">
            <h5
              style={{ color: '#ed3833', cursor: 'pointer' }}
              onClick={openPopup}
            >
              <strong>UPGRADE TO DOOSRA PRO</strong>
            </h5>
            <span className="PlanUpgradeText">
              Unlock Whatsapp & get the ability to turn off the call blocker for
              more than 1 hour with Doosra PRO
            </span>
          </div>
        )}
        {popper}
      </>
    );
  }
  return null;
}
