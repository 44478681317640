import React from 'react';
import { toast } from 'react-toastify';
import CopyIcon from '../../assets/images/copy.svg';

export default function DoosraNumberCard(props) {
  const copyNumber = () => {
    let { number } = props;
    number = number.slice(2);
    navigator.clipboard.writeText(number);
    toast.success('Copied to clipboard', {
      autoClose: 3000,
      position: 'bottom-center',
    });
  };

  const displayDoosraNumber = (number) => {
    if (number) return number.slice(2);
    return '';
  };

  return (
    <div
      onClick={copyNumber}
      className="doosra-number-box side-box"
      style={{
        display: 'grid',
        gridTemplateColumns: '90% 10%',
        cursor: 'pointer',
      }}
    >
      <div>
        <h1
          className="side-box-element "
          style={{
            border: 'none',
            paddingBottom: '0',
            marginBottom: '0',
            fontSize: '14px',
          }}
        >
          {' '}
          Your Doosra Number
          {' '}
        </h1>
        <div
          className="side-box-element profile-box-element"
          style={{
            border: 'none',
            paddingBottom: '0',
            marginBottom: '0',
            fontSize: '24px',
          }}
        >
          <span className="side-box-bold-element">
            {displayDoosraNumber(props.number)}
          </span>
        </div>
      </div>
      <img
        className="copy-img-icon"
        style={{ margin: 'auto 0 auto auto' }}
        src={CopyIcon}
      />
    </div>
  );
}
