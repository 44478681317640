/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../../assets/css/choosePlan.css";
import RightArrow from "../../assets/images/right-arrow-circle-red.svg";
import { Get } from "../../utils/httpReguests";
import GoogleTag from "../components/GoogleTag";
import InCNavBar from "../components/NavBar";
import ProgressIndicator from "../components/ProgressIndicator";
import Steps from "../components/registrationStepBar";
const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "project",
    page: "home",
  },
  dataLayerName: "PageDataLayer",
};
function Card(props) {
  const history = useHistory();

  const selectPlan = (props) => {
    if (props.loading) return;
    sessionStorage.setItem("selectedPlan", props?.plan?.plan_id);
    history.push("/choose-number");
  };

  if (!props?.plan?.plan_id) {
    return null;
  }

  return (
    <li className="cp-card" onClick={() => selectPlan(props)}>
      <div>
        <h1
          style={
            props.name === "PRO"
              ? { fontStyle: "italic", color: "#ed3833" }
              : null
          }
        >
          {props.name}
        </h1>
        <h3>{props.type}</h3>
      </div>
      <div>
        <p>INR</p>
        {props.loading ? (
          <p>loading ...</p>
        ) : (
          <h4>{props?.plan?.amount / 100}</h4>
        )}
      </div>
      <div style={{ margin: "auto 0", textAlign: "end" }}>
        <img src={RightArrow} />
      </div>
    </li>
  );
}

function ChoosePlan(props) {
  const [plans, setPlans] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("doosra-user-sessionToken")) {
      if (localStorage.getItem("doosra-user-status") === "ACTIVE") {
        history.push("/user-dashboard");
      } else {
        history.push("/checkout");
      }
    }

    setLoading(true);
    const planId = new URLSearchParams(props.location.search).get("plan_id");
    if (planId) {
      sessionStorage.setItem("selectedPlan", planId);
      history.push("/choose-number");
    } else {
      Get("v1/internal/w/is-vn-available", {})
        .then((resp) => {
          if (resp?.data?.response?.status === false) history.push("/waiting");
        })
        .catch((err) => {
          console.log(err);
        });

      Get("plans", { source: "WEB" })
        .then((res) => {
          console.log("plans", res.data.response);
          setPlans(res?.data?.response);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    window.dataLayer.push({
      event: "ChoosePlan",
      pagePath: "/choose-plan",
      pageTitle: "ChoosePlan",
    });
    GoogleTag();
  });

  return (
    <div>
      <InCNavBar />
      <ProgressIndicator currentStep={1} />
      <div className="cp-layout">
        <div className="cp-info">
          <h1>Get started with a plan that fits your needs</h1>
        </div>
        <ul
          style={{ listStyleType: "none", padding: "0", marginBottom: "24px" }}
        >
          <Card
            name="PRO"
            type="Annual"
            plan={plans.pro?.[0]}
            loading={loading}
          />
          <Card
            name="PRO"
            type="Half Yearly"
            plan={plans?.pro?.[1]}
            loading={loading}
          />
          {/* <Card
            name="PRO"
            type="Monthly"
            plan={plans?.pro?.[2]}
            loading={loading}
          /> */}
          <Card
            name="Essential"
            type="Annual"
            plan={plans?.essential?.[1]}
            loading={loading}
          />
          <Card
            name="Essential"
            type="Half Yearly"
            plan={plans?.essential?.[0]}
            loading={loading}
          />
        </ul>
        <div className="cp-learn-more">
          <a
            style={{ cursor: "pointer" }}
            href="https://www.doosra.com/pricing-page"
          >
            Learn more
          </a>
        </div>
        <div className="cp-ekyc-message">
          Doosra requires users to complete eKYC as per government regulations.
        </div>
      </div>
    </div>
  );
}

export default ChoosePlan;
