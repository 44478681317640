import moment from "moment";
import React from "react";
import BuyMinutes from "./BuyMinutes";
import BuyMinutesNew from "./BuyMinutesPopup";
import CancelFreeTrialPopup from "./CancelFreeTrialPopup";
import CancelSubscriptionPopup from "./CancelSubscriptionPopup";
import ChangePlanInFreeTrialPopup from "./ChangePlanInFreeTrialPopup";
import RenewPlanPopup from "./RenewPlanPopup";

function PlanType(props) {
  return (
    <div className="side-box-element">
      <span className="pad-right">Plan Type :</span>

      <span className="side-box-bold-element">{props.accountPlanType}</span>
    </div>
  );
}
function ValidityElement(props) {
  return (
    <div className="side-box-element" style={{ inlineSize: 'max-content' }}>
      <span className="pad-right">Validity :</span>
      <span className="side-box-bold-element">
        {moment(props.expireOn).format("LL")}
      </span>
    </div>
  );
}
function MinuteData(props) {
  const { callMinutesData } = props;
  return (
    <div className="side-box-element" style={{ flexDirection: "column" }}>
      <div>
        <span className="pad-right">Minutes Available :</span>
        <span className="side-box-bold-element">
          {callMinutesData?.total_call_minutes}
        </span>
      </div>
      <div className="side-box-sub-element">
        <span className="pad-right">Free Monthly Minutes :</span>
        <span className="side-box-bold-element ">
          {callMinutesData?.monthly_free_minutes_remaining}
        </span>
        {callMinutesData?.monthly_free_minutes_remaining_expires_in_days ==
          0 ? (
          <p style={{ marginBottom: "0px" }}>Expires Today</p>
        ) : (
          <p style={{ marginBottom: "0px" }}>
            Expires in{" "}
            {callMinutesData?.monthly_free_minutes_remaining_expires_in_days}{" "}
            days
          </p>
        )}
      </div>
      <div className="side-box-sub-element">
        <span className="pad-right">Purchased Minutes :</span>
        <span className="side-box-bold-element ">
          {callMinutesData?.purchased_remaining_minutes}
        </span>
        {callMinutesData?.purchased_remaining_minutes == 0 ? null : (
          <p style={{ marginBottom: "0px" }}>
            Expires in{" "}
            {callMinutesData?.purchased_remaining_minutes_expires_in_days} days
          </p>
        )}
      </div>
    </div>
  );
}

export default function PlanCard(props) {
  const { profileData } = props;

  const reMountComponent = () => {
    props.getCallMinutesDetails();
    props.getUserOrdersDetails();
    props.fetchUserProfileData();
  };

  return (
    <div className="plan-box side-box">
      <h1 className="side-box-element"> Plan </h1>
      <PlanType accountPlanType={profileData.accountPlanType} />
      <ValidityElement expireOn={profileData.expire_on} />

      <MinuteData callMinutesData={props?.callMinutesData} />

      <ChangePlanInFreeTrialPopup profileData={profileData} />
      <CancelFreeTrialPopup profileData={profileData} />
      <CancelSubscriptionPopup
        profileData={profileData}
        reMountComponent={reMountComponent}
      />
      <RenewPlanPopup
        profileData={profileData}
        reMountComponent={reMountComponent}
      />
      <BuyMinutesNew
        profileData={profileData}
        reMountComponent={reMountComponent} />
    </div>
  );
}
