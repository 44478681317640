import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/UserDashboard.css';
import { Get } from '../../utils/httpReguests';
import GoogleTag from '../../v2/components/GoogleTag';
import NavBar from '../../v2/components/NavBar';
import DeviceActivityCard from './DeviceActivityCard';
import DoosraNumberCard from './DoosraNumberCard';
import InviteProgramCard from './InviteProgramCard';
import KYCMandatoryCard from './KYCMandatoryCard';
import OrderHistory from './OrderHistory';
import PlanCard from './PlanCard';
import ProfileCard from './ProfileCard';
import UpgradeToDoosraPro from './UpgradeToDoosraPro';
// import RefundCard from './RefundCard';
import RefundInProgressCard from './RefundInProgressCard';

export default function UserDashboard() {
  const [userProfile, setUserProfile] = useState({});
  const [callMinutesData, setCallMinutesData] = useState({});
  const [ordersList, setOrdersList] = useState([]);
  const [isRefundInitiated, setIsRefundInitiated] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.dataLayer.push({
      event: 'UserDashboard',
      pagePath: '/user-dashboard',
      pageTitle: 'UserDashboard',
    });
    GoogleTag();
    fetchUserProfileData();
    getCallMinutesDetails();
    getUserOrdersDetails();
  }, [isRefundInitiated]);

  const fetchUserProfileData = () => {
    Get('v1/internal/w/profile')
      .then((res) => {
        if (res?.data?.response?.profile_details?.status === 'DROPPED_OFF') {
          sessionStorage.setItem('userNumber', res?.data?.response?.profile_details?.zvr_mobile_no);
          sessionStorage.setItem('userEmail', res?.data?.response?.profile_details?.zvr_email);
          history.push('/checkout');
        }
        localStorage.setItem('doosra-user-status', res?.data?.response?.profile_details?.status);
        setUserProfile(res?.data?.response?.profile_details);
        const isRefundCompleted = res?.data?.response?.profile_details?.refund_details;
        if (isRefundCompleted) {
          setIsRefundInitiated(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const updateUserProfileData = (key, value) => {
    fetchUserProfileData();
  };

  const getCallMinutesDetails = () => {
    Get('v1/internal/w/user-call-minute-detail')
      .then((res) => {
        setCallMinutesData(res.data.response);
      })
      .catch((err) => {
        console.log('err');
      });
  };

  const getUserOrdersDetails = () => {
    Get('v1/internal/w/fetch_user_orders_history')
      .then((res) => {
        setOrdersList(
          res?.data?.response?.ordersHistory?.ordersSubscriptionsHistory,
        );
      })
      .catch((err) => {
        console.log('err');
      });
  };

  return (
    <div>
      <NavBar />
      <ToastContainer />
      <div>
        <h1 className="Account-Info">Account Info</h1>
        <div className="a_Doosra__Account-Info-1">
          <div className="account-info-card-holder">
            <DoosraNumberCard number={userProfile?.v_mobile_no} />
            <KYCMandatoryCard isKYCCompleted={userProfile?.is_kyc_completed} />
            {/* {!isRefundInitiated
              ? (
                <RefundCard
                  setIsRefundInitiated={setIsRefundInitiated}
                />
              ) : <RefundInProgressCard />} */}
            <ProfileCard
              email={userProfile.zvr_email}
              number={userProfile.zvr_mobile_no}
              name={userProfile.zvr_name}
              updateUserProfileData={(key, value) => {
                updateUserProfileData(key, value);
              }}
            />
            <InviteProgramCard refCode={userProfile.ref_code} />
            <PlanCard
              profileData={userProfile}
              callMinutesData={callMinutesData}
              getCallMinutesDetails={getCallMinutesDetails}
              getUserOrdersDetails={getUserOrdersDetails}
              fetchUserProfileData={fetchUserProfileData}
            />
            <UpgradeToDoosraPro
              profileData={userProfile}
              remountComponent={() => {
                getUserOrdersDetails();
                fetchUserProfileData();
              }}
            />
            <DeviceActivityCard doosraPro={userProfile.doosra_pro} />
          </div>
          <div className="order-history-box">
            <OrderHistory ordersList={ordersList} />
          </div>
        </div>
      </div>
    </div>
  );
}
