import React, { Component } from 'react';
import numbernotalotted from '../assets/images/number_not_allotted.png';
import InCNavbar from './InCNavBar';

class NumberNotAllotted extends Component {





  componentDidMount() {
    if (!localStorage.getItem('doosra-user-sessionToken')) {
      this.props.history.push('/login');
    }
    if (this.props.history && this.props.history.action != 'REPLACE') {
      this.props.history.push('/login');
    }
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!localStorage.getItem('doosra-user-sessionToken')) {
      this.props.history.push('/login');
    }
  }


  render() {
    return (
      <React.Fragment>
        <InCNavbar />
        <div className="CenterContainer" style={{ 'margin-top': '5%', width: '100%' }}>
          <h1 className="NumberNotAllottedHeading">Oops, you missed getting this number by few seconds.</h1>
          <h2 className="NumberNotAllottedSubHeading">A customer support representative has been notified about this and will reach out to you as soon as possible. They will help you get a brand new Doosra Number.</h2>
          <img className="NumberNotAllottedImage" src={numbernotalotted} />
        </div>
      </React.Fragment>
    )
  }
}


export default NumberNotAllotted;
