import { get, post, sess_get, sess_post, get_two } from '../utils/HttpRequests';

export const generateVoucherOrder = (data) => {

  return new Promise((resolve, reject) => {

    post('w/create-gift-vouchers', data)

      .then((resp) => {
        if (resp.data.success) {


          const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            name: "Doosra",
            description: "payment",
            order_id: resp.data.response.order_details.order_id,
            prefill: {
              email: data.email
            },
            handler: function (response) {

              paymentDetails(response, data)
                .then((resp) => {

                  return resolve(true);
                })
                .catch((err) => {
                  return reject(err);
                })
            },
            theme: {
              color: "#686CFD",
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}


const paymentDetails = (response, data) => {
  return new Promise((resolve, reject) => {
    let verificationData = {
      payment_id: response.razorpay_payment_id,
      signature: response.razorpay_signature,
      order_id: response.razorpay_order_id
    }
    let confirmationData = {
      order_id: response.razorpay_order_id
    }
    post('w/voucher-signature-verification', verificationData)
      .then((resp) => {
        if (resp.data.response.is_verified == true) {
          get('w/is-voucher-confirmed', confirmationData)
            .then((resp) => {
              resolve(resp.data);
            })
            .catch((err) => {
              reject(err.response);
            })
        } else {
          reject(resp.data.response);
        }
      })
      .catch((err) => {
        reject('err');
      })

  })
}

export const getPlans = () => {
  return new Promise((resolve, reject) => {
    get('w/main-plans')
      .then((resp) => {
        resolve(resp.data.response);
      })
      .catch((err) => {
        reject(err.response);
      })
  })
}