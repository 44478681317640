import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import '../../assets/css/auth.css';
import Loading from '../../components/Loading';
import { Post } from '../../utils/httpReguests';
import Recaptcha from '../../utils/Recaptcha';
import ErrorMessage from '../components/errorMessage';
import GoogleTag from '../components/GoogleTag';
import NavBar from '../components/NavBar';
import SuccessMessage from '../components/successMessage';
import LoginBlocked from './RateLimiter';
import whitePreloader from '../../assets/images/rolling_white_loader.gif';

function LoginWithOTP(props) {
  const [otpReceived, setOTPReceived] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [otp, setOTP] = useState('');
  const [number, setNumber] = useState('');
  const [secret, setSecret] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();

  const fetchOTP = (e, again) => {
    if (e) e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    const action = again
      ? Recaptcha.ACTIONS.LOGIN_RESEND_OTP
      : Recaptcha.ACTIONS.LOGIN_OTP;
    Recaptcha.execute({ action }).then((token) => {
      Post('v2/internal/send-otp', {
        phone_number: number,
        recaptchaToken: token,
      })
        .then((res) => {
          setSecret(res.data.response.secret);
          setOTPReceived(true);
          setError('');
          setSubmitting(false);
          if (again) {
            setSuccess(true);
          }
        })
        .catch((err) => {
          setError(err?.response?.data?.error?.reason);
          setSuccess(false);
          setSubmitting(false);
        });
    });
  };

  const submitOTP = (e) => {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);
    Recaptcha.execute({ action: Recaptcha.ACTIONS.LOGIN_VERIFY_OTP }).then(
      (token) => {
        const data = {
          recaptchaToken: token,
          otp,
          secret,
          phone_number: number,
          source: 'WEB',
        };
        Post('auth/login', data)
          .then((res) => {
            localStorage.setItem(
              'doosra-user-sessionToken',
              res.data.response['x-metro-sessiontoken'],
            );
            localStorage.setItem(
              'doosra-user-id',
              res?.data?.response?.user_id,
            );
            localStorage.setItem(
              'doosra-user-status',
              res?.data?.response?.status,
            );
            if (res.data.response.status === 'DROPPED_OFF') {
              sessionStorage.setItem(
                'userNumber',
                res?.data?.response?.v_mobile_no,
              );
              history.push('/checkout');
            } else {
              history.push('/user-dashboard');
            }
            setError('');
            setSubmitting(false);
          })
          .catch((err) => {
            setError(err?.response?.data?.error?.reason);
            if (
              err?.response?.data?.error?.code === 'ACCOUNT_LOCKED'
              || err?.response?.data?.error?.code === 'LOGIN_RATE_LIMIT_EXCEEDED'
            ) {
              props.blockLogin(err?.response?.data?.error?.reason);
            }
            setSuccess(false);
            setSubmitting(false);
          });
      },
    );
  };

  if (otpReceived) {
    return (
      <form onSubmit={submitOTP}>
        <h1>Enter OTP received on your phone</h1>
        <h3>
          OTP successfully sent
        </h3>
        <input
          autoFocus
          placeholder="XXXXXX"
          value={otp}
          onChange={(e) => {
            setOTP(e.target.value);
          }}
        />
        <div>
          {/* <p className="auth-blue" onClick={props.switchLoginType}>
            Login with Password
          </p> */}
          <p
            className="auth-red"
            onClick={() => {
              fetchOTP(null, { again: true });
            }}
          >
            Resend OTP
          </p>
        </div>
        {success && <SuccessMessage msg="OTP sent successfully" />}
        <ErrorMessage msg={error} />
        <button style={submitting ? { backgroundColor: '#939598', border: 'none' } : null} type="submit">{submitting ? <img src={whitePreloader} style={{ height: '24px' }} /> : 'VERIFY OTP'}</button>
      </form>
    );
  }

  return (
    <form>
      <h1>Login with OTP</h1>
      <input
        autoFocus
        placeholder="Your Registered Primary Mobile Number"
        value={number}
        maxLength={10}
        // onChange={(e) => {
        //   setNumber(e.target.value);
        // }}
        disabled
      />
      <div>
        {/* <p className="auth-blue" onClick={props.switchLoginType}>
          Login with Password
        </p> */}
      </div>
      <ErrorMessage msg={props.error || error} />
      <button
        disabled
        style={submitting ? { backgroundColor: '#939598', border: 'none' } : { opacity: '0.3', cursor: 'default' }}
      >
        {submitting ? <img src={whitePreloader} style={{ height: '24px' }} /> : 'SEND OTP'}
      </button>
      {/* <p className='new-account' onClick={() => history.push('/choose-plan')}>Don’t have a Doosra account? <strong style={{ color: '#ed3833' }}>Sign up</strong></p> */}
    </form>
  );
}

// function LoginWithPassword(props) {
//   const [error, setError] = useState("");
//   const [errorCode, setErrorCode] = useState('');
//   const [number, setNumeber] = useState("");
//   const [password, setPassword] = useState("");

//   const history = useHistory();

//   const loginHandler = (e) => {
//     e.preventDefault();
//     Recaptcha.execute({ action: Recaptcha.ACTIONS.LOGIN_PASSWORD }).then(
//       (token) => {
//         const data = {
//           recaptchaToken: token,
//           password,
//           phone_number: number,
//           source: "WEB",
//         };
//         Post("auth/login", data)
//           .then((res) => {
//             console.log(res?.data?.response);
//             localStorage.setItem(
//               "doosra-user-sessionToken",
//               res.data.response["x-metro-sessiontoken"]
//             );
//             localStorage.setItem(
//               "doosra-user-id",
//               res?.data?.response?.user_id
//             );
//             localStorage.setItem(
//               "doosra-user-status",
//               res?.data?.response?.status
//             );
//             if (res.data.response.status === "DROPPED_OFF") {
//               sessionStorage.setItem(
//                 "userNumber",
//                 res?.data?.response?.v_mobile_no
//               );
//               history.push("/checkout");
//             } else {
//               history.push("/user-dashboard");
//             }
//             setError("");
//             setErrorCode("");
//           })
//           .catch((err) => {
//             setError(err?.response?.data?.error?.reason);
//             setErrorCode(err?.response?.data?.error?.code);
//             if (
//               err?.response?.data?.error?.code === "ACCOUNT_LOCKED" ||
//               err?.response?.data?.error?.code === "LOGIN_RATE_LIMIT_EXCEEDED"
//             ) {
//               props.blockLogin(err?.response?.data?.error?.reason);
//             }
//           });
//       }
//     );
//   };

//   return (
//     <form onSubmit={loginHandler}>
//       <h1>Login with Password</h1>
//       <input
//         placeholder="Your Registered Number"
//         value={number}
//         maxLength={10}
//         onChange={(e) => setNumeber(e.target.value)}
//       />
//       <input
//         placeholder="Password"
//         type="password"
//         onChange={(e) => setPassword(e.target.value)}
//       />
//       <div>
//         <p className="auth-blue" onClick={props.switchLoginType}>
//           Login with OTP
//         </p>
//         <p
//           className="auth-red"
//           onClick={() => {
//             history.push("/reset-password");
//           }}
//         >
//           Forgot Password?
//         </p>
//       </div>
//       {
//         errorCode === 'MAYBE_BOT' &&
//         <React.Fragment>
//           <p style={{ margin: '0' }}>Something went wrong</p>
//           <p style={{ margin: '0', color: '#196cca', textDecoration: 'underline', cursor: 'pointer' }}
//             onClick={props.switchLoginType}>Please login via OTP</p>
//         </React.Fragment>
//       }
//       {errorCode !== 'MAYBE_BOT' && <ErrorMessage msg={error} />}
//       <button type="submit">LOGIN</button>
//     </form>
//   );
// }

export default function Login(props) {
  const [viaOTP, setViaOTP] = useState(true);
  const [token, setToken] = useState('');
  const [isLoginBlocked, setIsLoginBlocked] = useState(false);
  const [blockMessage, setBlockMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const autoLoginToken = new URLSearchParams(props?.location?.search).get(
      'token',
    );
    if (autoLoginToken) {
      setLoading(true);
      setTimeout(() => {
        AutoLogin(autoLoginToken);
      }, 1000);
    }
  }, []);

  const AutoLogin = (autoLoginToken) => {
    const modalQueryParam = new URLSearchParams(props?.location?.search).get(
      'modal',
    );
    Recaptcha.execute({ action: Recaptcha.ACTIONS.LOGIN_VERIFY_OTP }).then(
      (token) => {
        const data = {
          recaptchaToken: token,
          source: 'WEB',
          token: autoLoginToken,
        };
        Post('auth/login', data)
          .then((resp) => {
            setLoading(false);
            if (resp.data.response.user_id) {
              localStorage.setItem(
                'doosra-user-sessionToken',
                resp.data.response['x-metro-sessiontoken'],
              );
              localStorage.setItem(
                'doosra-user-id',
                resp.data.response.user_id,
              );
              // history.push('/user-dashboard');
            }
            if (resp.data.response.profile_details) {
              localStorage.setItem(
                'doosra-user-sessionToken',
                resp.data.response.session_token,
              );
              localStorage.setItem(
                'doosra-user-id',
                resp.data.response.profile_details.user_id,
              );
              // history.push('/user-dashboard');
            }
            if (modalQueryParam) {
              history.push(`/user-dashboard?modal=${modalQueryParam}`);
            } else {
              history.push('/user-dashboard');
            }
          })
          .catch((err) => {
            setLoading(false);
            setError(err.response.data.error.reason);
          });
      },
    ).catch((err) => { AutoLogin(autoLoginToken); });
  };

  const switchLoginType = () => {
    setViaOTP(!viaOTP);
    setIsLoginBlocked(false);
  };

  useEffect(() => {
    window.dataLayer.push({
      event: 'Login',
      pagePath: '/',
      pageTitle: 'Login',
    });
    GoogleTag();
  });

  useEffect(() => {
    if (localStorage.getItem('doosra-user-sessionToken')) {
      const modalQueryParam = new URLSearchParams(props?.location?.search).get(
        'modal',
      );
      if (modalQueryParam) {
        history.push({ pathname: '/user-dashboard', search: `?modal=${modalQueryParam}` });
      } else {
        history.push('/user-dashboard');
      }
    }
    setTimeout(() => {
      // Recaptcha.execute({ action: "login" }).then((token) => {
      //   setToken(token);
      // });
    }, 1000);
  }, []);

  if (isLoginBlocked) {
    return <LoginBlocked message={blockMessage} />;
  }
  return (
    <div>
      <NavBar />
      {loading && <Loading popup />}
      <div className="auth-container">
        <LoginWithOTP
          switchLoginType={switchLoginType}
          token={token}
          blockLogin={(msg) => {
            setIsLoginBlocked(true);
            setBlockMessage(msg);
          }}
          error={error}
        />
      </div>
    </div>
  );
}
