import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RegPayment from '../v2/screens/Checkout';
import CheckoutNew from '../v2/screens/CheckoutNew';
import RegSelect from '../v2/screens/ChooseNumberType';
import RegPlanSelect from '../v2/screens/ChoosePlan';
import RegEnterNumber from '../v2/screens/EnterNumber';
import RegFailure from '../v2/screens/Failure';
import Login from '../v2/screens/Login';
import RegNumbersList from '../v2/screens/NumbersList';
import RegPremium from '../v2/screens/SelectPremiumNumber';
import RegSimilar from '../v2/screens/SelectSimilarNumber';
import RegVIP from '../v2/screens/SelectVIPNumber';
import RegSuccessNew from '../v2/screens/SuccessNew';
import RegVerifyOTP from '../v2/screens/VerifyOTP';
import GiftCard from './gift_card/GiftCardMain';
import GiftCardSuccess from './gift_card/Success';
import JoinWaitlist from './JoinWaitlist';
import MaintainancePage from './MaintainancePage';
import NumberNotAllotted from './NumberNotAllotted';
import PageNotFound from './PageNotFound';
import ResetPassword from './ResetPassword';
import SecureData from './SecureData';
import NewDashboard from './userDashboard/index';

export default function MainComponent(props) {
  const isUnderMaintainance = sessionStorage.getItem('doosra-under-maintainance');
  const maintainanceMessage = sessionStorage.getItem(
    'doosra-maintainance-message',
  );
  if (isUnderMaintainance) {
    return <MaintainancePage message={maintainanceMessage} />;
  }

  return (
    <>
      <Switch>
        {/* <Route exact path="/" component={Landing} /> */}
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={ResetPassword} />
        {/* <Route exact path="/success" component={Success} /> */}
        <Route exact path="/gift-card" component={GiftCard} />
        <Route exact path="/gift-card/success" component={GiftCardSuccess} />
        {/* <PrivateRoute exact path="/user-dashboard" component={UserDashboard} /> */}
        <Route exact path="/waiting" component={JoinWaitlist} />
        <Route exact path="/unsuccessful" component={NumberNotAllotted} />
        <Route exact path="/asd-ert-vf" component={SecureData} />

        <Route exact path="/choose-plan" component={RegPlanSelect} />
        {/* <Route exact path="/choose-number" component={RegSelect} /> */}
        <Route exact path="/choose-number" component={RegNumbersList} />
        <Route exact path="/enter-number" component={RegEnterNumber} />
        <Route exact path="/verify-otp" component={RegVerifyOTP} />
        <Route exact path="/choose-vip" component={RegVIP} />
        <Route exact path="/choose-premium" component={RegPremium} />
        <Route exact path="/choose-similar" component={RegSimilar} />
        <Route exact path="/checkout" component={CheckoutNew} />
        {/* <Route exact path="/success" component={RegSuccess} /> */}
        <Route exact path="/success" component={RegSuccessNew} />
        <Route exact path="/failure" component={RegFailure} />
        <Route exact path="/user-dashboard" component={NewDashboard} />

        <Route path="/*" component={PageNotFound} />
      </Switch>
    </>
  );
}
