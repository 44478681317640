/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Popup from "reactjs-popup";
import cancelButton from "../../assets/images/cancelButton.png";
import { ReactComponent as ForwardArrow } from "../../assets/images/Group 1228.svg";
import { Get, Post } from "../../utils/httpReguests";
import Loading from "../Loading";
import ErrorCard from "../others/Error";
import PaymentErrorPopup from "../others/PaymentErrorPopup";
import PaymentConfirmationLoader from "./PaymentConfirmationLoader";

const rupees = require("../../assets/images/rupees.svg");
const unlock = require("../../assets/images/unlock.svg");
const checkmarkCircle = require("../../assets/images/red_tick_circle.svg");
const crossImg = require("../../assets/images/cross.svg");
const CheckmarkGreenBig = require("../../assets/images/checkmark-circle-green-big.svg");
const checkmarkCircleGreen = require("../../assets/images/checkmark_circle.svg");
const ErrorAlert = require("../../assets/images/error_alert.png");

export default function RenewPlanPopup(props) {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState({});
  const [discountError, setDiscountError] = useState("");
  const [success, setSuccess] = useState(false);
  const [failuer, setFailuer] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [autoRenew, isAutoRenew] = useState(false);
  const [proCapsuleSelected, setProCapsuleSelected] = useState(false);
  const [plan1, setPlan1] = useState({});
  const [plan2, setPlan2] = useState({});
  const [proPlan1, setProPlan1] = useState({});
  const [proPlan2, setProPlan2] = useState({});
  const [proPlan3, setProPlan3] = useState({});
  const [
    isPaymentConfirmationLoaderVisible,
    setIsPaymentConfirmationLoaderVisible,
  ] = useState(false);
  const history = useHistory();

  useEffect(() => {
    Get("v1/internal/w/main-plans")
      .then((res) => {
        setPlan1(res?.data?.response.plan1);
        setPlan2(res?.data?.response.plan2);
        setProPlan1(res?.data?.response.plan3);
        setProPlan2(res?.data?.response.plan4);
        setProPlan3(res?.data?.response.plan5);
      })
      .catch((err) => {
        console.log("err fetching plans list for renew plan popup");
      });
    let isModalOpen = new URLSearchParams(window.location.search).get("modal");
    if (isModalOpen === "renew-plan") {
      setIsPopupOpen(true);
    }
  }, []);

  const openPopup = () => {
    setIsPopupOpen(true);

    if (props.profileData.doosra_pro) {
      setSelectedPlan(proPlan3);
    } else {
      setSelectedPlan(plan2);
    }
    history.push("/user-dashboard?modal=renew-plan");
  };

  const closePopup = () => {
    if (success) {
      props.reMountComponent();
    }
    setLoader(false);
    setError(false);
    setDiscountCode("");
    setDiscount({});
    setDiscountError("");
    setSuccess(false);
    setSelectedPlan({});
    setIsPopupOpen(false);
    isAutoRenew(false);
    setProCapsuleSelected(false);
    setFailuer(false);
    history.push("/user-dashboard");
  };

  const clearDiscountData = () => {
    setDiscountError("");
    setDiscountCode("");
    setDiscount({});
  };

  const onChange = (e) => {
    setDiscountError("");
    setDiscountCode(e.target.value);
    setDiscount({});
  };

  const selectCapsuleHandler = (data) => {
    setProCapsuleSelected(data);
  };

  const selectPlan = (plan) => {
    console.log(plan);
    setSelectedPlan(plan);
    setDiscount({});
    setDiscountCode("");
    setDiscountError("");
    setError("");
  };

  const Proceed = () => {
    let userId = "";
    if (!selectedPlan?.plan_id) return setError("Select a plan");
    if (props.profileData) {
      userId = props?.profileData?.user_id;
    }
    const data = {
      plan_id: selectedPlan?.plan_id,
      is_initial: false,
      user_id: props.profileData.user_id,
      source: "WEB",
      name: props.profileData.zvr_name || "",
      email: props.profileData.zvr_email || "",
      contact: props.profileData.zvr_mobile_no || "",
    };
    if (discount.discount_applied) {
      data.discount = discount.discount_applied / 100;
      data.coupon = discountCode;
      data.discount_percent = discount.discount_percentage;
      data.coupon_id = discount.discount_coupon_id;
    }
    if (autoRenew) {
      generateSubscription(data);
    } else {
      generateOrder(data);
    }
  };

  const generateOrder = async (data) => {
    await Post("v1/internal/w/order", data)
      .then((resp) => {
        if (resp.data.success) {
          if (resp.data.response.status == "payment not required") {
            setSuccess(true);
          } else {
            setLoader(true);
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY,
              name: "Doosra",
              description: "payment",
              order_id: resp.data.response.order_details.order_id,
              prefill: {
                name: data.name,
                email: data.email,
                contact: data.contact,
              },
              handler: (response) => {
                const verificationData = {
                  payment_id: response.razorpay_payment_id,
                  signature: response.razorpay_signature,
                  user_id: data.user_id,
                  order_id: response.razorpay_order_id,
                };
                const confirmationData = {
                  user_id: data.user_id,
                  order_id: response.razorpay_order_id,
                };
                Post(
                  "v1/internal/w/order-signature-verification",
                  verificationData
                )
                  .then((resp) => {
                    if (resp.data.response.is_verified == true) {
                      PaymentConfirmation(data, confirmationData);
                    } else {
                      console.log("err");
                    }
                  })
                  .catch((err) => {
                    setError(err?.response?.data?.error?.reason);
                  });
              },
              theme: {
                color: "#686CFD",
              },
              modal: {
                ondismiss: (res) => {
                  setLoader(false);
                  setIsPaymentConfirmationLoaderVisible(false);
                },
              },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
          }
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.error?.reason);
      });
  };
  const FailureRedirect = () => {
    setFailuer(true);
  };

  const PaymentConfirmation = (data, confirmationData) => {
    setIsPaymentConfirmationLoaderVisible(true);
    let count = 1;
    const RetryConfirmation = (data, confirmationData) => {
      count += 1;
      Get("v1/internal/w/is-order-confirmed", confirmationData)
        .then((resp) => {
          if (resp.data.response.is_order_confirmed) {
            setSuccess(true);
            setLoader(false);
            setIsPaymentConfirmationLoaderVisible(false);
          } else {
            if (count >= 7) return FailureRedirect(data);
            setTimeout(() => {
              RetryConfirmation(data, confirmationData);
            }, 5000);
          }
        })
        .catch((err) => {
          if (count >= 7) return FailureRedirect(data);
          setTimeout(() => {
            RetryConfirmation(data, confirmationData);
          }, 5000);
        });
    };
    RetryConfirmation(data, confirmationData);
  };

  const generateSubscription = async (data) => {
    await Post("v1/internal/w/subscription", data)
      .then((resp) => {
        setLoader(true);
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          name: "Doosra",
          description: "payment",
          subscription_id: resp.data.response.subscription.id,
          "data-prefill": data.prefill,
          handler: (response) => {
            const verificationData = {
              payment_id: response.razorpay_payment_id,
              signature: response.razorpay_signature,
              user_id: data.user_id,
              subscription_id: response.razorpay_subscription_id,
            };
            const confirmationData = {
              user_id: data.user_id,
              subscription_id: response.razorpay_subscription_id,
            };
            Post(
              "v1/internal/w/subscription-signature-verification",
              verificationData
            )
              .then((resp) => {
                if (resp.data.response.is_verified == true) {
                  Get("w/is-subscription-confirmed", confirmationData)
                    .then((resp) => {
                      setSuccess(true);
                      setLoader(false);
                    })
                    .catch((err) => {
                      setError(err?.response?.data?.error?.reason);
                    });
                } else {
                  console.log("err");
                }
              })
              .catch((err) => {
                setError(err?.response?.data?.error?.reason);
              });
          },
          theme: {
            color: "#686CFD",
          },
          modal: {
            ondismiss: (res) => {
              setLoader(false);
            },
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      })
      .catch((err) => {
        setError(err?.response?.data?.error?.reason);
      });
  };

  const applyDiscountCode = async () => {
    const data = {
      discount_coupon: discountCode,
      amount: selectedPlan.amount,
      action: "APPLY",
      v_mobile_no: props.profileData.v_mobile_no,
      plan_id: selectedPlan.plan_id,
    };

    if (!selectedPlan?.plan_id) return setDiscountError("Select Plan");
    if (discountCode == "") return setDiscountError("Provide Code");
    if (selectedPlan?.amount == 0) return;
    await Post(`user/${localStorage.getItem("doosra-user-id")}/discount`, data)
      .then((res) => {
        setDiscount(res?.data?.response);
        setError("");
        setDiscountError("");
      })
      .catch((err) => {
        setDiscountError(
          err?.response?.data?.error?.reason || "unable to apply discount Code"
        );
      });
  };

  const removeDiscountCode = () => {
    const data = {
      discount_coupon: discountCode,
      amount: selectedPlan.amount,
      action: "REMOVE",
      v_mobile_no: props.profileData.v_mobile_no,
      plan_id: selectedPlan.plan_id,
    };
    Post(`user/${localStorage.getItem("doosra-user-id")}/discount`, data)
      .then((res) => {
        setError("");
        setDiscountError("");
        setDiscountCode("");
        setDiscount({});
      })
      .catch((err) => {
        setDiscountError(
          err?.response?.data?.error?.reason || "unable to remove discount Code"
        );
      });
  };

  const { profileData } = props;

  let plan1Amt = 0;
  let plan2Amt = 0;
  let proPlan1Amt = 0;
  let proPlan2Amt = 0;
  let proPlan3Amt = 0;
  let plan1Id = "";
  let plan2Id = "";
  let proPlan1Id = "";
  let proPlan2Id = "";
  let proPlan3Id = "";
  if (plan1 && plan1.amount) {
    plan1Amt = plan1.amount;
    plan2Amt = plan2.amount;
    plan1Id = plan1.plan_id;
    plan2Id = plan2.plan_id;
  }
  if (proPlan1 && proPlan1.amount) {
    proPlan1Id = proPlan1.plan_id;
    proPlan2Id = proPlan2.plan_id;
    proPlan3Id = proPlan3 ? proPlan3.plan_id : null;
    proPlan1Amt = proPlan1.amount;
    proPlan2Amt = proPlan2.amount;
    proPlan3Amt = proPlan3 ? proPlan3.amount: 0;
  }
  let proceedAmount = selectedPlan.amount / 100;
  if (discount?.discount_applied) {
    proceedAmount -= discount.discount_applied / 100;
  }
  let capsuleDisplay;
  let selectorCapsule = 1;
  if (profileData && profileData.doosra_pro) {
    selectorCapsule = 3;
  } else {
    selectorCapsule = 2;
  }
  switch (selectorCapsule) {
    case 1:
      capsuleDisplay = (
        <div className="ProductSelectCapsuleHolder">
          <span
            className={
              proCapsuleSelected
                ? "ProductSelectCapsule"
                : "ProductSelectCapsule CapsuleSelected"
            }
            onClick={() => {
              selectCapsuleHandler(false);
            }}
          >
            <span
              className={
                proCapsuleSelected
                  ? "ProductSelectCapsuleHeader "
                  : "ProductSelectCapsuleHeader CapsuleSelectedText"
              }
            >
              Essential
            </span>
          </span>
          <span
            className={
              proCapsuleSelected
                ? "ProductSelectCapsule CapsuleSelected"
                : "ProductSelectCapsule"
            }
            onClick={() => {
              selectCapsuleHandler(true);
            }}
          >
            <span
              className={
                proCapsuleSelected
                  ? "ProductSelectCapsuleHeader CapsuleSelectedText"
                  : "ProductSelectCapsuleHeader"
              }
            >
              DOOSRA
              <span
                className={
                  proCapsuleSelected
                    ? "ProductSelcetCapsuleHeaderPro CapsuleSelectedSubText"
                    : "ProductSelcetCapsuleHeaderPro"
                }
              >
                {" "}
                Pro
              </span>
            </span>
          </span>
        </div>
      );
      break;
    case 2:
      capsuleDisplay = (
        <div className="ProductSelectCapsuleHolder">
          <span className="ProductSelectCapsule CapsuleSelected">
            <span className="ProductSelectCapsuleHeader CapsuleSelectedText">
              Essential
            </span>
          </span>
        </div>
      );
      break;
    case 3:
      capsuleDisplay = (
        <div className="ProductSelectCapsuleHolder">
          <span className="ProductSelectCapsule CapsuleSelected">
            <span className="ProductSelectCapsuleHeader CapsuleSelectedText">
              DOOSRA
              <span className="ProductSelcetCapsuleHeaderPro CapsuleSelectedSubText">
                {" "}
                Pro
              </span>
            </span>
          </span>
        </div>
      );
      break;
  }

  const popper = (
    <Popup
      open={isPopupOpen}
      closeOnDocumentClick={false}
      className="ModalScroll"
    >
      <>
        {loader ? (
          <Loading />
        ) : (
          <>
            {success ? (
              <div className="ModalTwo">
                <img
                  className="cross-button"
                  src={crossImg}
                  onClick={closePopup}
                />
                <div className="PlanUpgradeModalHeading">
                  Payment Successful
                </div>
                <div className="ModalBody " style={{ textAlign: "center" }}>
                  <img
                    src={CheckmarkGreenBig}
                    style={{ marginBottom: "35px" }}
                  />
                </div>
                <div className="ModalButtonHolderTwo">
                  <p
                    className="Invert"
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={closePopup}
                  >
                    Close
                  </p>
                </div>
              </div>
            ) : (
              <div className="ModalTwo ModalScrollLarge">
                <div
                  style={{ textAlign: "right", padding: "10px 10px 0 10px " }}
                >
                  <img
                    className=""
                    style={{ width: "20px" }}
                    src={crossImg}
                    onClick={closePopup}
                  />
                </div>
                <div className="ModalBodyTwo">
                  <div />
                  <div
                    className="ModalTwoTitleContainer"
                    style={{ paddingTop: "0px" }}
                  >
                    <p className="ModalTwoTitle">
                      Select a paid plan to continue
                    </p>
                    <p className="ModalTwoSubTitle">
                      Get started by choosing one of
                      <br /> these fully-loaded plans.
                    </p>
                  </div>

                  {capsuleDisplay}

                  {profileData.doosra_pro || proCapsuleSelected ? (
                    <>
                      {proPlan3Id ? <div
                        className={
                          selectedPlan.plan_id == proPlan3Id
                            ? "ItemSelectedBorder OptionBox"
                            : "ItemUnselectedBorder OptionBox"
                        }
                        onClick={() => {
                          selectPlan(proPlan3);
                        }}
                      >
                        {" "}
                        Two Years <s><img src={rupees} className="RupeesIcon" />1999</s>
                        <img src={rupees} className="RupeesIcon" />
                        {proPlan3Amt / 100}
                      </div>:null}
                      <div
                        className={
                          selectedPlan.plan_id == proPlan2Id
                            ? "ItemSelectedBorder OptionBox"
                            : "ItemUnselectedBorder OptionBox"
                        }
                        onClick={() => {
                          selectPlan(proPlan2);
                        }}
                      >
                        {" "}
                        Annual
                        <img src={rupees} className="RupeesIcon" />
                        {proPlan2Amt / 100}
                      </div>
                      <div
                        className={
                          selectedPlan?.plan_id == proPlan1Id
                            ? "ItemSelectedBorder OptionBox"
                            : "ItemUnselectedBorder OptionBox"
                        }
                        onClick={() => {
                          selectPlan(proPlan1);
                        }}
                      >
                        {" "}
                        Half Yearly
                        <img src={rupees} className="RupeesIcon" />
                        {proPlan1Amt / 100}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={
                          selectedPlan?.plan_id == plan1Id
                            ? "ItemSelectedBorder OptionBox"
                            : "ItemUnselectedBorder OptionBox"
                        }
                        onClick={() => {
                          selectPlan(plan1);
                        }}
                      >
                        {" "}
                        Half Yearly
                        <img src={rupees} className="RupeesIcon" />
                        {plan1Amt / 100}
                      </div>
                      <div
                        className={
                          selectedPlan?.plan_id == plan2Id
                            ? "ItemSelectedBorder OptionBox"
                            : "ItemUnselectedBorder OptionBox"
                        }
                        onClick={() => {
                          selectPlan(plan2);
                        }}
                      >
                        {" "}
                        Annual
                        <img src={rupees} className="RupeesIcon" />
                        {plan2Amt / 100}
                      </div>
                    </>
                  )}

                  {/*
                        <div className="" style={{ 'textAlign': 'center' }}>
                        <img src={
                          (autoRenew) ?
                            checked_checkbox :
                            unchecked_checkbox
                          }
                          defaultChecked={autoRenew}
                          onClick={() => {setAutoRenew(!autoRenew)}}
                          style={{
                            'max-width': '18px',
                            'margin-right': '10px'
                          }}
                          />
                          <label for="autoRenew" >Auto-renew
                <a className="TermsLink">  </a>
                </label>
                </div>
              */}
                  <div style={{ textAlign: "center" }}>
                    {discount?.discount_coupon ? (
                      <div className="SuccessBox">
                        <span className="SuccessBoxRightImage">
                          <img src={checkmarkCircleGreen} />
                        </span>
                        <span
                          className="Success Green"
                          style={{ textAlign: "center" }}
                        >
                          Code Applied successfully
                        </span>
                        <span className="SuccessBoxLeftImage">
                          <img
                            src={cancelButton}
                            className="DiscountCancelButton"
                            onClick={removeDiscountCode}
                          />
                        </span>
                      </div>
                    ) : (
                      <div className="DiscountCodeContainer">
                        <span className="discount-code-box">
                          <input
                            className="DiscountCodeInput DiscountCodeInputTwo"
                            type="text"
                            id="discountCode"
                            placeholder="Discount code"
                            onChange={onChange}
                            value={discountCode}
                          />
                          <img
                            src={cancelButton}
                            className="DiscountCancelButton"
                            onClick={clearDiscountData}
                          />
                        </span>
                        <button
                          className="discount-apply-btn"
                          onClick={applyDiscountCode}
                        >
                          <span className="ButtonText">Apply</span>
                        </button>
                      </div>
                    )}
                  </div>
                  <p className="Error" style={{ textAlign: "center" }}>
                    {discountError || null}
                  </p>
                  {error && <ErrorCard error={error} />}
                  {profileData.doosra_pro || proCapsuleSelected ? (
                    <div className="WhatYouGet">
                      <h1>Doosra PRO Features</h1>
                      <div className="WhatYouGetGrid">
                        <img src={checkmarkCircle} />
                        <p>Everything in Doosra Essential, plus…</p>
                        <img src={checkmarkCircle} />
                        <p>Turn off the call blocker indefinitely</p>
                        <img src={checkmarkCircle} />
                        <p>Multi-user login (upto 5 devices)</p>
                        <img src={checkmarkCircle} />
                        <p>Create Whatsapp, Telegram accounts</p>
                        <img src={checkmarkCircle} />
                        <p>No limit on Trusted Contacts</p>
                      </div>
                    </div>
                  ) : (
                    <div className="WhatYouGet">
                      <h1>Doosra Essential Features</h1>
                      <div className="WhatYouGetGrid">
                        <img src={checkmarkCircle} />
                        <p> No ads, because you're not the product</p>
                        <img src={checkmarkCircle} />
                        <p> Unlimited Voicemail</p>
                        <img src={checkmarkCircle} />
                        <p> Premium & VIP Number Options</p>
                        <img src={checkmarkCircle} />
                        <p> 100 Minutes of secure call back per month</p>
                        <img src={checkmarkCircle} />
                        <p> 10 Trusted Contacts</p>
                      </div>
                    </div>
                  )}

                  <div className="ModalButtonHolderTwo" style={{ padding: 0 }}>
                    <button className="ModalButtonTwo" onClick={Proceed}>
                      {proceedAmount || proceedAmount == "0" ? (
                        <>
                          <span
                            // className="ButtonTextTwo"
                            style={{ color: "white" }}
                          >
                            {" "}
                            Pay INR {proceedAmount}
                          </span>
                          <ForwardArrow
                            style={{ "margin-left": "20px", height: "24px" }}
                          />
                        </>
                      ) : (
                        "Proceed"
                      )}
                    </button>

                    <button
                      className="ModalButtonTwo Invert"
                      onClick={closePopup}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </Popup>
  );

  if (!props?.profileData?.is_auto_renewal) {
    return (
      <div className="side-box-element">
        <span>
          <h5
            style={{ color: "#ed3833", cursor: "pointer", opacity: "0.5" }}
            // onClick={openPopup}
          >
            <strong>RENEW</strong>
          </h5>
          <span className="PlanUpgradeText">
            Extend your plan validity and enjoy Doosra uninterrupted
          </span>

          {popper}
        </span>
        <PaymentErrorPopup onClose={closePopup} open={failuer} />
        <PaymentConfirmationLoader open={isPaymentConfirmationLoaderVisible} />
      </div>
    );
  }
  return null;
}
