import { useEffect } from "react";
import { useHistory } from "react-router";

export default function LogicalRouter() {
  const history = useHistory();

  useEffect(() => {
    if (
      localStorage.getItem("doosra-user-id") &&
      localStorage.getItem("doosra-user-status") === "ACTIVE"
    ) {
      history.push("/user-dashboard");
    }

    if (
      localStorage.getItem("doosra-user-sessionToken") &&
      !sessionStorage.getItem("selectedNumberType")
    ) {
      history.push("/choose-number");
    }
  }, []);
  return null;
}
