/* eslint-disable */
import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import '../../styles/editNameModal.css';
import { SelectDeleteReason } from './SelectDeleteReason';
import { Post } from '../../utils/httpReguests';
import Recaptcha from '../../utils/Recaptcha';
import { toast } from 'react-toastify';

const crossImg = require('../../assets/images/cross.svg');

export default function SelectReasonModal(props) {
  const [error, setError] = useState('');
  const handleClose = (e) => {
    if (e) e.preventDefault();
    props.closePopup();
  };

  const handleContinue = (e) => {
    if (e) e.preventDefault();
    if(props.reason !== ""){
      fetchOTP();
    }
    else{
      toast.error("Please select option to procced.")
    }  
  };

  const fetchOTP = () => {
    const action = Recaptcha.ACTIONS.LOGIN_OTP
    Recaptcha.execute({ action }).then((token) => {
      Post('v2/internal/send-otp', {
        phone_number: props.number,
        recaptchaToken: token,
      })
        .then((res) => {
          toast("OTP sent to your registered number successfully.");
          props.getSecret(res.data.response.secret);
          props.continueToStep3();
          props.closePopup();
        })
        .catch((err) => {
          setError(err?.response?.data?.error?.reason);
          toast.error(error ? error : 'an error occured', {
            autoClose: 2000
          })
          props.closePopup();
        });
    });
  };

  return (
    <div>
      <Modal show={props.isVisible} centered>
          <img className="cross-button" src={crossImg} onClick={handleClose} />
          <span className="steps">Step 2 of 3</span>
          <h5 className="delete-account-head">
              <strong>We’d love for you to tell us why you’re deleting your account</strong>
          </h5> 
          <span 
              className="delete-account-subhead"
          >
              Choose an option that fits from the list. Help us become better!
          </span> 
          <SelectDeleteReason 
              selectedReason = {(reason) => { 
                props.selectedDeleteReason(reason);
              }}
          />    
          <div 
              className='accept-button'
              onClick={ handleContinue }
          >
            <span className='button-text'>Proceed</span>
          </div>
          <span 
              className="Back"
              onClick={ handleClose }
          >
              Back
          </span>
      </Modal>
    </div>  
  );
};