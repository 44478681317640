/* eslint-disable */
import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { Get } from '../utils/httpReguests';

const rupees = require('../assets/images/rupees.svg');

const checkmarkCircle = require('../assets/images/red_tick_circle.svg');
const crossImg = require('../assets/images/cross.svg');

class DroppedOffUserPlanSelectModal extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      plan1: null,
      plan2: null,
      proPlan1: null,
      proPlan2: null,
      proPlan3: null,
      selectedPlanId: null,
      selectedPlanAmount: null,
      proCapsuleSelected: true,
    };
  }

  componentDidMount() {
    Get('plans', { source: 'WEB' })
      .then((res) => res.data.response)
      .then((res) => {
        this.setState({
          plan1: res.essential[0],
          plan2: res.essential[1],
          proPlan1: res.pro[1],
          proPlan2: res.pro[0],
          proPlan3: res.pro[2],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  selectCapsuleHandler = (data) => {
    this.setState({
      proCapsuleSelected: data,
    });
  };

  selectPlan = (plan) => {
    console.log('plan was switched');
    this.setState({
      selectedPlanId: plan.plan_id,
      selectedPlanAmount: plan.amount,
      selectedPlanName: plan.description,
    });
  };

  confirmPlan = () => {
    console.log('change plan', this.state);
    if (!this.state.selectedPlanId) return;
    console.log('selected plan', this.state.selectedPlanId);
    console.log('currentplan', this.props);
    if (this.state.selectedPlanId === this.props.currentPlanId) {
      return this.props.closeModal();
    }
    console.log('was changed');
    sessionStorage.setItem('selectedPlan', this.state.selectedPlanId);
    sessionStorage.setItem('selectedPlanName', this.state.selectedPlanName);
    this.props.switchPlan();
    this.props.closeModal();
  };

  render() {
    const {
      plan1,
      plan2,
      proPlan1,
      proPlan2,
      proPlan3,
      proCapsuleSelected,
      selectedPlanId,
    } = this.state;
    let plan1Amt = 0;
    let plan2Amt = 0;
    let proPlan1Amt = 0;
    let proPlan2Amt = 0;
    let proPlan3Amt = 0;
    let plan1Id = '';
    let plan2Id = '';
    let proPlan1Id = '';
    let proPlan2Id = '';
    let proPlan3Id = '';
    if (plan1 && plan1.amount) {
      plan1Amt = plan1.amount;
      plan2Amt = plan2.amount;
      plan1Id = plan1.plan_id;
      plan2Id = plan2.plan_id;
    }
    if (proPlan1 && proPlan1.amount) {
      proPlan2Amt = proPlan2.amount;
      proPlan1Amt = proPlan1.amount;
      proPlan3Amt = proPlan3 ? proPlan3.amount : 0;
      proPlan1Id = proPlan1.plan_id;
      proPlan2Id = proPlan2.plan_id;
      proPlan3Id = proPlan3 ? proPlan3.plan_id: null;
    }

    const popper = (
      <Popup
        open={this.props.isOpen}
        closeOnDocumentClick={false}
        className="ModalScroll"
      >
        <div className="ModalTwo ModalScrollLarge">
          <div style={{ marginTop: '4px', padding: '10px 10px 0 10px ' }}>
            <img
              // className="cross-button"
              style={{
                cursor: 'pointer',
                height: '24px',
                width: '24px',
                float: 'right',
              }}
              src={crossImg}
              onClick={this.props.closeModal}
            />
            <div className="ModalBodyTwo" style={{ marginTop: '26px' }}>
              <div
                className="ModalTwoTitleContainer"
                style={{ paddingTop: '0px' }}
              >
                <p className="ModalTwoTitle">Choose Plan</p>

                {plan1 && plan1.amount && (
                  <p className="ModalTwoSubTitle">
                    Get started by choosing one of
                    <br />
                    {' '}
                    these fully-loaded plans.
                  </p>
                )}
              </div>
              {plan1 && plan1.amount && (
                <div className="ProductSelectCapsuleHolder">
                  <span
                    className={
                      proCapsuleSelected
                        ? 'ProductSelectCapsule'
                        : 'ProductSelectCapsule CapsuleSelected'
                    }
                    onClick={() => {
                      this.selectCapsuleHandler(false);
                    }}
                  >
                    <span
                      className={
                        proCapsuleSelected
                          ? 'ProductSelectCapsuleHeader '
                          : 'ProductSelectCapsuleHeader CapsuleSelectedText'
                      }
                    >
                      Essential
                    </span>
                  </span>

                  <span
                    className={
                      proCapsuleSelected
                        ? 'ProductSelectCapsule CapsuleSelected'
                        : 'ProductSelectCapsule'
                    }
                    onClick={() => {
                      this.selectCapsuleHandler(true);
                    }}
                  >
                    <span
                      className={
                        proCapsuleSelected
                          ? 'ProductSelectCapsuleHeader CapsuleSelectedText'
                          : 'ProductSelectCapsuleHeader'
                      }
                    >
                      DOOSRA
                      <span
                        className={
                          proCapsuleSelected
                            ? 'ProductSelcetCapsuleHeaderPro CapsuleSelectedSubText'
                            : 'ProductSelcetCapsuleHeaderPro'
                        }
                      >
                        {' '}
                        Pro
                      </span>
                    </span>
                  </span>
                </div>
              )}
              {this.state.proCapsuleSelected ? (
                <>
                  <div
                    className={
                      selectedPlanId == proPlan2Id
                        ? 'ItemSelectedBorder OptionBox'
                        : 'ItemUnselectedBorder OptionBox'
                    }
                    onClick={() => {
                      this.selectPlan(proPlan2);
                    }}
                  >
                    <p>
                      {' '}
                      Annual &#8377;
                      {proPlan2Amt / 100}
                    </p>
                  </div>
                  <div
                    className={
                      selectedPlanId == proPlan1Id
                        ? 'ItemSelectedBorder OptionBox'
                        : 'ItemUnselectedBorder OptionBox'
                    }
                    onClick={() => {
                      this.selectPlan(proPlan1);
                    }}
                  >
                    Half Yearly &#8377;
                    {proPlan1Amt / 100}
                  </div>
                  {/* { proPlan3Id ? <div
                    className={
                      selectedPlanId == proPlan3Id
                        ? 'ItemSelectedBorder OptionBox'
                        : 'ItemUnselectedBorder OptionBox'
                    }
                    onClick={() => {
                      this.selectPlan(proPlan3);
                    }}
                  >
                    Monthly &#8377;
                    {proPlan3Amt / 100}
                  </div> : null } */}
                </>
              ) : (
                <>
                  <div
                    className={
                      selectedPlanId == plan2Id
                        ? 'ItemSelectedBorder OptionBox'
                        : 'ItemUnselectedBorder OptionBox'
                    }
                    onClick={() => {
                      this.selectPlan(plan2);
                    }}
                  >
                    <p>
                      {' '}
                      Annual &#8377;
                      {plan2Amt / 100}
                    </p>
                  </div>
                  <div
                    className={
                      selectedPlanId == plan1Id
                        ? 'ItemSelectedBorder OptionBox'
                        : 'ItemUnselectedBorder OptionBox'
                    }
                    onClick={() => {
                      this.selectPlan(plan1);
                    }}
                  >
                    Half Yearly &#8377;
                    {plan1Amt / 100}
                  </div>
                </>
              )}
              {plan1 && plan1.amount && (
                <>
                  {this.state.proCapsuleSelected ? (
                    <div className="WhatYouGet">
                      <h1>Doosra PRO Features</h1>
                      <div className="WhatYouGetGrid">
                        <img src={checkmarkCircle} />
                        <p>Everything in Doosra Essential, plus…</p>
                        <img src={checkmarkCircle} />
                        <p>Turn off the call blocker indefinitely</p>
                        <img src={checkmarkCircle} />
                        <p>Multi-user login (upto 5 devices)</p>
                        <img src={checkmarkCircle} />
                        <p>Create Whatsapp, Telegram accounts</p>
                        <img src={checkmarkCircle} />
                        <p>No limit on Trusted Contacts</p>
                      </div>
                    </div>
                  ) : (
                    <div className="WhatYouGet">
                      <h1>Doosra Essential Features</h1>
                      <div className="WhatYouGetGrid">
                        <img src={checkmarkCircle} />
                        <p> No ads, because you're not the product</p>
                        <img src={checkmarkCircle} />
                        <p> Unlimited Voicemail</p>
                        <img src={checkmarkCircle} />
                        <p> Premium & VIP Number Options</p>
                        <img src={checkmarkCircle} />
                        <p> 100 Minutes of secure call back per month</p>
                        <img src={checkmarkCircle} />
                        <p> 10 Trusted Contacts</p>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="ModalButtonHolderTwo" style={{ padding: 0 }}>
                <button className="ModalButtonTwo" onClick={this.confirmPlan}>
                  Proceed
                </button>
                <button
                  className="ModalButtonTwo Invert"
                  onClick={this.props.closeModal}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    );

    return <span>{popper}</span>;
  }
}

export default DroppedOffUserPlanSelectModal;
