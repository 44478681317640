import React, { useEffect, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useHistory } from 'react-router';
import { Post } from '../../utils/httpReguests';
import Recaptcha from '../../utils/Recaptcha';
import ErrorMessage from '../components/errorMessage';
import GoogleTag from '../components/GoogleTag';
import LogicalRouter from '../components/LogicalRouter';
import NavBar from '../components/NavBar';
import StepBar from '../components/registrationStepBar';
import ToSPopup from '../components/termsOfService';
import whitePreloader from '../../assets/images/rolling_white_loader.gif';
import CountDownBar from '../components/CountdownBar';
import ReactPixel from 'react-facebook-pixel';
import ProgressIndicator from '../components/ProgressIndicator';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}

export default function Checkout(props) {
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [accept, setAccept] = useState(false);
  const [error, setError] = useState('');
  const [toSOpen, setToSOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (
      sessionStorage.getItem('selectedPlan')
      || localStorage.getItem('doosra-user-sessionToken')
    ) {
      // history
    } else {
      history.push('/choose-plan');
    }
  }, []);

  useEffect(() => {
    const dataLayer = {
      event: 'EnterNumber',
      pagePath: '/enter-number',
      pageTitle: 'EnterNumber',
    };
    GoogleTag(dataLayer);
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_KEY, null, options);
    ReactPixel.track('AddToCart', {});
  }, []);

  const GetOtpHandler = (e) => {
    e.preventDefault();
    if (loading) return;
    if (email === '') return setError('Provide email address');
    if (!accept) {
      setError('Please agree to the T&C before proceeding');
      return;
    }
    setLoading(true);
    setError('');

    Recaptcha.execute({ action: Recaptcha.ACTIONS.SIGNUP_OTP }).then(
      (token) => {
        const data = {
          phone_number: number,
          recaptchaToken: token,
        };
        if (email !== '') data.email = email;
        Post('v2/internal/send-otp-new-number', data)
          .then((res) => {
            setLoading(false);
            sessionStorage.setItem(
              'userNumber',
              res?.data?.response?.phone_number,
            );
            sessionStorage.setItem(
              'userEmail',
              res?.data?.response?.email,
            );
            sessionStorage.setItem('secret', res?.data?.response?.secret);
            setError('');
            history.push('/verify-otp');
          })
          .catch((err) => {
            setLoading(false);
            setError(err?.response?.data?.error?.reason);
          });
      },
    );
  };

  const goBackHandler = (point) => {
    history.push(point);
  };

  return (
    <div>
      <LogicalRouter />
      <NavBar />
      <CountDownBar />
      <ProgressIndicator currentStep={2} />
      <div style={{ textAlign: 'center' }}>
        <h1 className="title">Enter your phone number to receive an OTP</h1>

        <form className="input-form" onSubmit={GetOtpHandler}>
          <input
            className="form-input"
            placeholder="Your email address"
            value={email}
            type="email"
            style={email !== '' ? { borderColor: 'black', marginBottom: '46px' } : { marginBottom: '46px' }}
            onChange={(e) => setEmail(e.target.value)}

          />
          <input
            className="form-input"
            placeholder="+91 Your Mobile Number"
            value={number}
            maxLength={10}
            style={number !== '' ? { borderColor: 'black' } : {}}
            onChange={(e) => setNumber(e.target.value)}
          />
          <p className="form-extras">Your phone number is required for us to send you OTPs for sign-up and login. It is not shared with any 3rd parties and we promise you will not be spammed</p>
          <div className="t-n-c">
            <input
              type="checkbox"
              value={accept}
              onClick={(e) => {
                setAccept(e.target.value);
                setError('');
              }}
              style={{ marginRight: '7px' }}
            />
            <span>I agree to the &nbsp;</span>
            <strong
              onClick={() => setToSOpen(true)}
              style={{ cursor: 'pointer' }}
            >
              Terms Of Service
            </strong>
            <ToSPopup isOpen={toSOpen} close={() => setToSOpen(false)} />
          </div>
          <ErrorMessage msg={props.error || error} />
          <div className="form-button-container">
            <button
              className="form-back-btn"
              type="button"
              onClick={() => goBackHandler('choose-number')}
            >
              <AiOutlineLeft className="form-btn-icon" />
              {' '}
              GO BACK
            </button>
            {
              loading
                ? (
                  <button className="form-proceed-btn" style={{ backgroundColor: '#939598', border: 'none' }} type="submit">
                    <img src={whitePreloader} style={{ width: '24px' }} />
                  </button>
                )
                : (
                  <button className="form-proceed-btn" type="submit">
                    SEND OTP
                    {' '}
                    <AiOutlineRight className="form-btn-icon" />
                  </button>
                )
            }
          </div>
        </form>
      </div>
    </div>
  );
}
