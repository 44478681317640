import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ReactComponent as RedTick } from '../../assets/images/red_tick_circle.svg';
import one from '../../assets/images/one_red_circle.png';
import two from '../../assets/images/two_red_circle.png';
import three from '../../assets/images/three_red_circle.png';
import '../../assets/css/successPage.css';
import Lottie from 'react-lottie';
import animation from '../../assets/success_background.json';
import { useHistory } from 'react-router-dom';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import GoogleTag from '../components/GoogleTag';
import NavBar from '../components/NavBar';
import Steps from '../components/registrationStepBar';
import ReactPixel from 'react-facebook-pixel';
import ProgressIndicator from '../components/ProgressIndicator';
import AppStore from '../../assets/images/app_store.png';
import PlayStore from '../../assets/images/play_store.png';
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  // here is where we will declare lottie animation
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function SuccessNew(props) {
  const [isLinkVisible, setIsLinkVisible] = useState(false);
  const [size, setSize] = useState([0, 0]);
  const history = useHistory();

  useEffect(() => {
    const a = props?.location?.state;
    const token = localStorage.getItem('doosra-user-sessionToken');
    if (!token) history.push('/');
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_KEY, null, options);
    ReactPixel.track('Purchase', { value: a?.purchaseValue || 0, currency: 'INR' });

    if (token && history.action === 'POP') {
      history.push('/user-dashboard');
    }

    if (token && (history.action === 'REPLACE' || history.action === 'PUSH')) {
      window.dataLayer.push({
        event: 'Success',
        pagePath: '/success',
        pageTitle: 'success',
        ...a,
      });
      GoogleTag();

      refresh();
    }

    setTimeout(() => {
      setIsLinkVisible(true);
    }, 15000);
  }, []);

  const refresh = () => {
    setTimeout(() => {
      history.push('/user-dashboard');
    }, 30000);
  };


  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.screen.width, window.screen.height]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);



  let backgroudWidth = size[0] < 600 ? size[0] : 1000;
  let backgroudHeight = size[1] < 600 ? 720 : 1060;




  return (
    <>
      <NavBar />
      <Lottie options={defaultOptions}
        isStopped={false}
        isPaused={false}
        height={backgroudHeight} width={backgroudWidth} style={{ position: 'absolute', zIndex: -1, left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto' }} />
      <ProgressIndicator currentStep={4} />
      <div className='success-page'>
        <h1>You’re all done!</h1>
        <h2>Congratulations on joining Doosra</h2>
        <div className='success-page-container'>
          <h1>3 quick things to do next:</h1>
          <div>
            <img src={one} className="Tick" />
            <p>Download the Doosra app on your phone</p>
          </div>
          <div>
            <img src={two} className="Tick" />
            <p>Login and complete eKYC on the app</p>
          </div>
          <div>
            <img src={three} className="Tick" />
            <p>Create a secondary WhatsApp account</p>
          </div>
          <div className='store-container'>
            <a href="https://apps.apple.com/in/app/doosra/id1513242801">
              <img src={AppStore} className="AppStore" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.ten20infomedia.doosra&hl=en"
              className="PlayStoreSpacing"
            >
              <img src={PlayStore} className="PlayStore" />
            </a>
          </div>
        </div>
        {isLinkVisible
          && (
            <button className="to-account" onClick={() => { history.push('/user-dashboard'); }} type="submit">
              Go to your account
              {' '}
              <AiOutlineRight className="form-btn-icon" />
            </button>
          )}
      </div>

    </>
  )
}