import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../styles/editNameModal.css';
import { Put } from '../../utils/httpReguests';
import Recaptcha from '../../utils/Recaptcha';

const crossImg = require('../../assets/images/cross.svg');

const defaultState = {
  newName: '',
  userMessage: '',
};

export default function EditNamePopup(props) {
  const [newName, setNewName] = useState('');
  const [userMessage, setUserMessage] = useState('');

  const handleClose = (e) => {
    if (e) e.preventDefault();
    props.closePopup();
    setNewName('');
    setUserMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const action = Recaptcha.ACTIONS.LOGIN_RESEND_OTP;
    await Recaptcha.execute({ action }).then((token) => {
      const data = {
        recaptchaToken: token,
        name: newName,
      };
      Put(
        'v1/internal/w/update_user_profile',
        localStorage.getItem('doosra-user-sessionToken'),
        data,
      )
        .then((res) => {
          props.updateUserProfileData('zvr_name', newName);
          handleClose();
        })
        .catch((e) => {
          setUserMessage(
            e?.response?.data?.error?.reason || 'unable to update Name',
          );
        });
    });
  };

  return (
    <Modal show={props.isVisible} centered>
      <img className="cross-button" src={crossImg} onClick={handleClose} />
      <form>
        <h1>Edit Name </h1>
        {
          props?.name
          && <input disabled type="text" value={props.name} />
        }
        <input
          type="text"
          value={newName}
          onChange={(e) => {
            setNewName(e.target.value);
          }}
        />
        <span className="user-Message">{userMessage}</span>
        <div className="button-group">
          <button onClick={handleSubmit}>Update</button>
          <button onClick={handleClose} className="cancel-button">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
