import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { STATES_LIST } from '../../utils/Constants';
import { Get, Post, Put } from '../../utils/httpReguests';

const crossImg = require('../../assets/images/cross.svg');

const contentStyle = { width: '90%', maxWidth: '400px' };
const inputStyle = {
  border: 'none',
  borderBottom: '2px solid black',
  padding: '8px',
};

export default function GstDetails(props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [stateList, setStateList] = useState([]);

  const closePopup = () => {
    setPopupOpen(false);
    // setData({});
    if (!success) setData({});
    setError('');
    setSubmitting(false);
  };

  useEffect(() => {
    setStateList(Object.keys(STATES_LIST));

    Get('v1/internal/w/user/gst-details')
      .then((res) => {
        if (res?.data?.response?.gstDetails) {
          setData({
            addr: res?.data?.response?.gstDetails?.address,
            state: res?.data?.response?.gstDetails?.placeOfSupplyState,
            gst: res?.data?.response?.gstDetails?.gstin,
            name: res?.data?.response?.gstDetails?.name,
          });
        }
      })
      .then((err) => console.log(err));
  }, []);

  const submitData = () => {
    if (!data.addr || !data.state || !data.gst || !data.name) return setError('Fill empty fields');
    const state_code = STATES_LIST[data.state];
    setSubmitting(true);
    Post('v1/internal/w/user/gst-details', { ...data, state_code })
      .then((res) => {
        toast.success('details updated successfully');
        setPopupOpen(false);
        setError('');
        setSubmitting(false);
        setSuccess(true);
      })
      .catch((err) => { setError(err?.response?.data?.error?.reason); setSubmitting(false); });
  };

  return (
    <>

      <h4 onClick={() => setPopupOpen(true)}>
        {
          (!data.addr || !data.state || !data.gst || !data.name)
            ? 'ADD'
            : 'EDIT'
        }
      </h4>

      <Popup open={popupOpen} closeOnDocumentClick={false} closeOnEscape={false} {...{ contentStyle }}>
        <div className="ModalTwo" style={{ height: 'auto' }}>
          <div
            style={{ textAlign: 'right', padding: '10px 10px 0 10px ' }}
          >
            <img
              className=""
              style={{ width: '20px' }}
              src={crossImg}
              onClick={closePopup}
            />
          </div>
          <div className="ModalBodyTwo">
            <div
              className="ModalTwoTitleContainer"
              style={{ paddingTop: '0px' }}
            >
              <p className="ModalTwoTitle">
                Enter your GST details
              </p>

            </div>
            <div style={{
              display: 'flex', flexDirection: 'column', gap: '24px', marginBottom: '20px',
            }}
            >
              <input
                placeholder="Enter your name here"
                value={data.name}
                style={inputStyle}
                onChange={(e) => { setData({ ...data, name: e.target.value }); }}
              />
              <input
                placeholder="Enter your address here"
                value={data.addr}
                style={inputStyle}
                onChange={(e) => { setData({ ...data, addr: e.target.value }); }}
              />
              <input
                placeholder="Enter your GSTIN number here"
                value={data.gst}
                style={inputStyle}
                // onChange={(e) => { setData({ ...data, gst: e.target.value }) }}
                onChange={(e) => { setData({ ...data, gst: e.target.value.toUpperCase() }); }}
              />
              <select
                style={{ padding: '12px', border: '2px solid black', backgroundColor: 'white' }}
                onChange={(e) => { setData({ ...data, state: e.target.value }); }}
              >
                <option value="" selected hidden>Select a place of supply</option>
                {
                  stateList.length > 0 && stateList.map((state) => (
                    <option value={state}>{state}</option>
                  ))
                }
              </select>
            </div>
            <p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
            <div className="ModalButtonHolderTwo" style={{ padding: 0 }}>
              {!submitting
                ? (
                  <>
                    <button className="ModalButtonTwo" onClick={submitData}>
                      SAVE
                    </button>

                    <button
                      className="ModalButtonTwo Invert"
                      onClick={closePopup}
                    >
                      CLOSE
                    </button>
                  </>
                )
                : (
                  <button className="ModalButtonTwo">
                    Submitting ...
                  </button>
                )}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}
