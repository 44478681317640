/* eslint-disable */
import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../styles/editNameModal.css';

const crossImg = require('../../assets/images/cross.svg');

export default function TermsAndConditionsModal(props) {
  const handleClose = (e) => {
    if (e) e.preventDefault();
    props.closePopup();
  };

  const handleContinue = (e) => {
    if (e) e.preventDefault();
    props.continueToStep2()
    props.closePopup();
  };

  const toPrivacyPolicy = () => {
    window.location.assign('https://www.doosra.com/privacy-policy');
  }

  const toSupportMailer = () => {
    window.location.assign('https://mail.google.com/mail/?view=cm&fs=1&to=support@doosra.io&su=Regarding Delete Account&body=Type your query here');
  }

  return (
    <Modal show={props.isVisible} centered>
        <img className="cross-button" src={crossImg} onClick={handleClose} />
        <span className="steps">Step 1 of 3</span>  
        <h5 className="delete-account-head"><strong>Delete my account</strong></h5> 
        <span 
            className="delete-account-subhead"
        >
            Some important things you should know before you delete your account
        </span>
        <p 
            className="delete-account-info"
        >
            Immediately upon account deletion you will be logged out of all Doosra properties 
            and will unable to access your current account and all its associated data. 
            Your Doosra plan will be terminated along with its validity.
        </p>
        <p 
            className="delete-account-info"
        >
            Your Doosra number will no longer be accessible to you along with your SMS data, 
            call logs, voicemail, and other meta data.
        </p>
        <p 
            className="delete-account-info"
        > 
            While most of your sensitive data will be deleted, 
            analytics data and information of your KYC will be retained on our servers for 
            18 months for government compliance reasons and then will be permanently deleted. 
            We strongly recommend you read our
            <span className="text-style-1" onClick={toPrivacyPolicy}> privacy policy </span>to see how we treat your data.
        </p>
        < p className="delete-account-info">   
            <span 
                className="text-style-2"
            >
                Please note that you will not be able to sign up for 
                Doosra for a period of 18 months post account deletion.
            </span>
        </p>
        <div className="Rectangle-9565">
            <span className="delete-account-info-inlay"> 
                Alternatively if you’re having issues with Doosra’s services you 
                could also try getting in touch with
                <span className="text-style-3" onClick={toSupportMailer}> our support team</span>
            </span>
        </div>
        <span className='delete-account-footer'>
            We’re sad to see you go but we respect your decision and 
            hope we meet again soon…
        </span>
        <div 
            className='accept-button'
            onClick={ handleContinue }
        >
           <span className='button-text'>I Understand</span>
        </div>
        <span 
            className="Back"
            onClick={ handleClose }
        >
            Back
        </span>
    </Modal>
  );
}