import React from "react";
import { toast } from "react-toastify";
import CopyIcon from "../../assets/images/copy.svg";

export default function InviteProgramCard(props) {
  const copyInviteCode = () => {
    navigator.clipboard.writeText(props?.refCode);
    toast.success("Copied to clipboard", {
      autoClose: 3000,
      position: "bottom-center",
    });
  };

  return (
    <div onClick={copyInviteCode} className="invite-box side-box">
      <h1 className="side-box-element"> Invite Program </h1>
      <div style={{ marginBottom: "20px" }}>
        <p
          className="PlanUpgradeText"
          style={{ opacity: "1", fontSize: "18px" }}
        >
          Invite your friends and family to Doosra & win scratch cards with
          guaranteed cash rewards!
        </p>
        <p
          className="PlanUpgradeText"
          style={{ opacity: "1", fontSize: "16px" }}
        >
          Log into the app to see your rewards and cash balance
        </p>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "90% 10%",
          cursor: "pointer",
        }}
      >
        <div>
          <h1
            className="side-box-element "
            style={{
              border: "none",
              paddingBottom: "0",
              marginBottom: "0",
              fontSize: "14px",
            }}
          >
            {" "}
            Your Invite Code{" "}
          </h1>
          <div
            className="side-box-element profile-box-element"
            style={{
              border: "none",
              paddingBottom: "0",
              marginBottom: "0",
              fontSize: "24px",
            }}
          >
            <span className="side-box-bold-element">{props.refCode}</span>
          </div>
        </div>
        <img
          className="copy-img-icon"
          style={{ margin: "auto 0 auto auto" }}
          src={CopyIcon}
        />
      </div>
    </div>
  );
}
