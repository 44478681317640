import * as actionType from "../actions/actionTypes";

const initialState = {
  isFreeTrial: false,
  optedForUpgradeFromFree: false,
  optedForUpgradeFromFreeinStep3: false,
  planId: "",
  plan_id: "",
  step: 1,
  waitStep: "A",
  error: "",
  primaryNumber: "",
  secret: "",
  OTPSecretError: "",
  OTP: "",
  OTPError: "",
  virtualNumberAvailable: true,
  GeneralPremiumPrice: {},
  GeneralSimilarPrice: {},
  GeneralVIPPrice: {},
  defaultDoosraNumber: "",
  defaultDoosraNumberBlockedAt: "",
  defaultDoosraNumberBlockedFor: "",
  VIPNumbers: [],
  PremiumNumbers: [],
  SimilarNumbers: [],
  selectedDoosraNumber: "",
  selectedDoosraNumberBlockedAt: "",
  selectedDoosraNumberBlockedFor: "",
  selectedNumberType: "",
  doosraNumberBlockedAt: "",
  isReferralCodeValid: "",
  referralCode: "",
  planAmount: 0,
  doosraNumberAmount: 0,
  totalAmount: "",
  planDuration: 0,
  registerError: "",
  nextPlan: {},
  previousPlan: {},
  profileData: {},
  sessionToken: null,
  profileStatus: "",
  discountCodeError: "",
  discountBody: {},
  generatedOrderDetails: {},
  paymentDetails: {},
  paymentError: "",
  mainPlans: {},
  discountedPlans: {},
  proPlans: {},
  previousPlanId: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.STEP_ONE:
      return {
        ...state,
        step: 1,
      };
    case actionType.STEP_TWO:
      return {
        ...state,
        step: 2,
      };
    case actionType.STEP_THREE:
      return {
        ...state,
        step: 3,
      };
    case actionType.STEP_FOUR:
      return {
        ...state,
        step: 4,
      };
    case actionType.STEP_FIVE:
      return {
        ...state,
        step: 5,
      };
    case actionType.STEP_SIX:
      return {
        ...state,
        step: 6,
      };
    case actionType.STEP_SEVEN:
      return {
        ...state,
        step: 7,
      };
    case actionType.PREVIOUS_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    case actionType.NEXT_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case actionType.GET_PLAN_ID:
      return {
        ...state,
        planId: action.payload,
      };
    case actionType.UPDATE_PLAN_ID:
      return {
        ...state,
        planId: action.payload,
        previousPlanId: state.planId,
      };
    case actionType.GET_OTP_SECRET_SUCCESS:
      return {
        ...state,
        primaryNumber: action.payload.phone_number,
        secret: action.payload.secret,
        error: "",
      };
    case actionType.GET_OTP_SECRET_FAIL:
      return {
        ...state,
        OTPSecretError: action.payload,
      };
    case actionType.OTP_VERIFY_SUCCESS:
      return {
        ...state,
        OTP: action.payload,
      };
    case actionType.OTP_VERIFY_FAIL:
      return {
        ...state,
        OTPError: action.payload,
      };
    case actionType.VN_AVAILABLE:
      return {
        ...state,
        virtualNumberAvailable: true,
      };
    case actionType.VN_UNAVAILABLE:
      if (state.step === 2) {
        return {
          ...state,
          virtualNumberAvailable: false,
          step: 8,
        };
      } else {
        return {
          ...state,
          virtualNumberAvailable: false,
        };
      }
    case actionType.DEFAULT_VIRTUAL_NUMBER:
      return {
        ...state,
        defaultDoosraNumber: action.payload.v_mobile_no,
        defaultDoosraNumberBlockedFor: action.payload.blocked_for,
        defaultDoosraNumberBlockedAt: Date.now(),
      };
    case actionType.VIP_NUMBERS:
      return {
        ...state,
        VIPNumbers: action.payload,
      };
    case actionType.CLEAR_VIP_NUMBERS_LIST:
      return {
        ...state,
        VIPNumbers: [],
      };
    case actionType.PREMIUM_NUMBERS:
      return {
        ...state,
        PremiumNumbers: action.payload,
      };
    case actionType.CLEAR_PREMIUM_NUMBERS_LIST:
      return {
        ...state,
        PremiumNumbers: [],
      };
    case actionType.SIMILAR_NUMBERS:
      return {
        ...state,
        SimilarNumbers: action.payload,
      };
    case actionType.CLEAR_SIMILAR_NUMBERS_LIST:
      return {
        ...state,
        SimilarNumbers: [],
      };
    case actionType.DOOSRA_NUMBER_RESERVE_TIME_OVER:
      return {
        ...state,
        selectedDoosraNumber: "",
        selectedDoosraNumberBlockedAt: "",
        selectedDoosraNumberBlockedFor: "",
        defaultDoosraNumber: "",
        defaultDoosraNumberBlockedAt: "",
        defaultDoosraNumberBlockedFor: "",
        step: 3,
      };
    case actionType.GET_VMN_PRICE_LIST:
      return {
        ...state,
        GeneralPremiumPrice: action.payload.premium_price,
        GeneralSimilarPrice: action.payload.similar_price,
        GeneralVIPPrice: action.payload.vip_price,
      };
    case actionType.SELECT_DOOSRA_NUMBER:
      return {
        ...state,
        selectedDoosraNumber: action.payload.v_mobile_no,
        selectedDoosraNumberBlockedAt: Date.now(),
        selectedDoosraNumberBlockedFor: action.payload.blocked_for,
      };
    case actionType.SWITCH_TO_DEFAULT_DOOSRA_NUMBER:
      return {
        ...state,
        selectedDoosraNumber: "",
        selectedDoosraNumberBlockedAt: "",
        selectedDoosraNumberBlockedFor: "",
      };
    case actionType.NUMBER_ALLOTMENT_FAIL:
      return {
        ...state,
        SimilarNumbers: state.SimilarNumbers.map((numberObj) =>
          numberObj.v_mobile_no == action.payload.v_mobile_no
            ? { ...numberObj, available: false }
            : numberObj
        ),
        PremiumNumbers: state.PremiumNumbers.map((numberObj) =>
          numberObj.v_mobile_no == action.payload.v_mobile_no
            ? { ...numberObj, available: false }
            : numberObj
        ),
        VIPNumbers: state.VIPNumbers.map((numberObj) =>
          numberObj.v_mobile_no == action.payload.v_mobile_no
            ? { ...numberObj, available: false }
            : numberObj
        ),
      };

    case actionType.DOOSRA_NUMBER_PRICE:
      return {
        ...state,
        doosraNumberAmount: "",
      };
    case actionType.REFERRAL_CODE_INVALID:
      return {
        ...state,
        isReferralCodeValid: false,
        referralCode: "",
      };
    case actionType.REFERRAL_CODE_VALID:
      return {
        ...state,
        isReferralCodeValid: true,
        referralCode: action.payload,
      };
    case actionType.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        sessionToken: action.payload["x-metro-sessiontoken"],
        profileData: action.payload.profile,
        registerError: "",
      };
    case actionType.CREATE_ACCOUNT_FAIL:
      return {
        ...state,
        registerError: action.payload,
      };
    case actionType.FETCH_PAYMENT_DETAILS_SUCCESS:
      // console.log('got data', action.payload)
      return {
        ...state,
        detailsForPayment: action.payload,
        planAmount: action.payload.plan_amount,
        totalAmount: action.payload.total_amount,
        doosraNumberAmount: action.payload.v_mobile_no_amount,
        planDuration: action.payload.credits,
        nextPlan: action.payload.next_plan,
        previousPlan: action.payload.previous_plan,
        planId: action.payload.plan_id,
        plan_id: action.payload.plan_id,
      };
    case actionType.DISCOUNT_CODE_INVALID:
      return {
        ...state,
        discountCodeError: action.payload,
        discountBody: {},
        paymentError: "",
      };
    case actionType.DISCOUNT_CODE_VALID:
      return {
        ...state,
        discountCodeError: "",
        paymentError: "",
        discountBody: action.payload,
      };
    case actionType.CLEAR_DISCOUNT_CODE:
      return {
        ...state,
        discountCodeError: "",
        paymentError: "",
        discountBody: {},
      };
    case actionType.GENERATE_ORDER_SUCCESS:
      return {
        ...state,
        generatedOrderDetails: action.payload,
      };
    case actionType.PAYMENT_SUCCESS:
      return {
        ...state,
        paymentDetails: action.payload,
        paymentError: "",
      };
    case actionType.PAYMENT_FAIL:
      return {
        ...state,
        paymentError: action.payload,
      };
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        sessionToken: action.payload.session_token,
        profileData: action.payload.profile_details,
        primaryNumber: action.payload.profile_details.zvr_mobile_no,
      };
    case actionType.FETCH_USER_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        profileData: action.payload,
        primaryNumber: action.payload.zvr_mobile_no,
        profileStatus: action.payload.status,
      };
    }
    case actionType.REDIRECT_DROPPED_OFF_USER:
      return {
        ...state,
        step: 3,
      };
    case actionType.SET_FREE_TRIAL_STATUS:
      return {
        ...state,
        isFreeTrial: action.payload,
      };
    case actionType.UPGRADE_FROM_FREE_TRIAL:
      return {
        ...state,
        optedForUpgradeFromFree: true,
      };
    case actionType.UPGRADE_FROM_FREE_TRIAL_STEP_3:
      return {
        ...state,
        optedForUpgradeFromFreeinStep3: true,
      };
    case actionType.CANCEL_UPGRADE_FROM_FREE:
      return {
        ...state,
        optedForUpgradeFromFree: false,
        optedForUpgradeFromFreeinStep3: false,
      };
    case actionType.REDIRECT_BACK_WITHOUT_TRIAL_UPGRADE:
      return {
        ...state,
        optedForUpgradeFromFree: false,
        step: 5,
        planId: state.previousPlanId,
      };
    case actionType.GET_MAIN_PLANS:
      return {
        ...state,
        mainPlans: action.payload,
      };
    case actionType.GET_DISCOUNTED_PLANS:
      return {
        ...state,
        discountedPlans: action.payload,
      };
    case actionType.GET_PRO_PLANS:
      return {
        ...state,
        proPlans: action.payload,
      };
    case actionType.JUMP_TO_PAGE:
      return {
        ...state,
        step: action.payload,
      };
    case actionType.CLEAR_REGISTER_ERROR:
      return {
        ...state,
        OTPSecretError: "",
      };
    case actionType.LOGOUT:
      return {
        ...state,
        ...initialState,
      };
    case actionType.UPDATE_PROFILE_STATUS:
      return {
        ...state,
        profileStatus: action.payload,
      };

    default:
      return state;
  }
}
