import React, { useEffect, useLayoutEffect, useState } from 'react';
import step1 from '../../assets/images/step_1.png';
import step2 from '../../assets/images/step_2.png';
import step3 from '../../assets/images/step_3.png';
import step4 from '../../assets/images/step_4.png';
import step1Mobile from '../../assets/images/step_1_mobile.png';
import step2Mobile from '../../assets/images/step_2_mobile.png';
import step3Mobile from '../../assets/images/step_3_mobile.png';
import step4Mobile from '../../assets/images/step_4_mobile.png';
import '../../assets/css/progressbar.css'


export default function ProgressIndicator(props) {
  const [size, setSize] = useState([0, 0]);
  let progressBar;

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.screen.width);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);


  switch (props?.currentStep) {
    case 1:

      progressBar = size < 600 ? step1Mobile : step1;
      break;
    case 2:
      progressBar = size < 600 ? step2Mobile : step2;
      break;
    case 3:
      progressBar = size < 600 ? step3Mobile : step3;
      break;
    case 4:
      progressBar = size < 600 ? step4Mobile : step4;
      break;
    default: progressBar = '';
  }

  return (
    <div className='progress-bar-container'>
      <img src={progressBar} />
    </div>
  )
}