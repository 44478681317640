import { combineReducers } from "redux";
import { LOGOUT } from "../actions/actionTypes";
import account from "./account";
import register from "./register";
import system from "./system";
// Combine all reducers.
const appReducer = combineReducers({
  register: register,
  account: account,
  system: system,
  logout: false,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
    localStorage.removeItem("doosra-user-sessionToken");
    localStorage.removeItem("doosra-user-id");
  }

  return appReducer(state, action);
};

export default rootReducer;
