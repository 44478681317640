import React from "react";
import Tick from "../../assets/images/checkmark_circle_white.svg";

export default function SuccessMessage(props) {
  return (
    <p
      style={{
        fontSize: "16px",
        fontWeight: "600",
        color: "#53b416",
        margin: "0",
      }}
    >
      <img
        src={Tick}
        style={{ marginRight: "8px", position: "relative", top: "-2px" }}
      />
      {props.msg}
    </p>
  );
}
