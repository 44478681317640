export default function GoogleTag() {
  let googleKey = '';
  if (process.env.REACT_APP_ENV === 'PROD') {
    googleKey = 'GTM-T9H83RS';
  } else {
    googleKey = 'GTM-WHPMRZZ';
  }

  function a(w, d, s, l, i) {
    // eslint-disable-next-line no-param-reassign
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  }
  a(window, document, 'script', 'dataLayer', googleKey);

  return null;
}
