import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// let googleKey = "";
// if (process.env.REACT_APP_ENV === "PROD") {
//   googleKey = "GTM-NRRGWGJ";
// } else {
//   googleKey = "GTM-WHPMRZZ";
// }

// const tagManagerArgs = {
//   gtmId: googleKey,
//   dataLayerName: "SuccessDataLayer",
// };

// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
