import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "../../assets/css/done.css";
import Alert from "../../assets/images/alert_black_background.svg";
import Copy from "../../assets/images/copy_red.svg";
import NavBar from "../components/NavBar";
import { Get } from "../../utils/httpReguests";

export default function Faliure(props) {
  const history = useHistory();
  console.log(history.location.state.data);
  let confirmationData = {
    order_id: history?.location?.state?.data?.order_id,
    user_id: history?.location?.state?.data?.user_id
  }
  Get("v1/internal/w/is-order-confirmed", confirmationData)
        .then((resp) => {
          if (resp.data.response.is_order_confirmed) {
            history.push({
              pathname: "/success",
              state: {
              },
            });
          } else {
            // payment not failed
          }
        })
        .catch((err) => {
          console.log(err);
        });

  // useEffect(() => {
  //   const a = props?.location?.state;
  //   const token = localStorage.getItem("doosra-user-sessionToken");
  //   if (!token) history.push("/");

  //   if (token && history.action === "POP") {
  //     history.push("/user-dashboard");
  //   }

  //   if (token && (history.action === "REPLACE" || history.action === "PUSH")) {
  //     refresh();
  //   }
  // }, []);

  // const refresh = () => {
  //   return;
  //   setTimeout(() => {
  //     history.push("/user-dashboard");
  //   }, 20000);
  // };

  const handleClick = (type) => {
    let value = "";
    if (type === "number") value = "8886660467";
    if (type === "email") value = "support@doosra.io";

    navigator.clipboard.writeText(value);
    toast.success(`Copied ${type} to clipboard`, {
      autoClose: 3000,
      position: "bottom-center",
    });
  };

  return (
    <div>
      <NavBar />
      <ToastContainer />
      <div style={{ textAlign: "center", margin: "36px 10% 0" }}>
        <img src={Alert} style={{ width: "104px", height: "104px" }} />

        <h1
          style={{
            maxWidth: "650px",
            fontSize: "clamp(24px,34px,34px)",
            fontWeight: "bold",
            margin: "40px auto 60px",
          }}
        >
          Its taking longer than usual to process your payment
        </h1>
        <p
          style={{
            maxWidth: "435px",
            fontSize: "clamp(16px,26px,26px)",
            margin: "32px auto",
          }}
        >
          <p style={{ marginBottom: "26px" }}>
            Please try refreshing this page to check payment status.
          </p>
          <p style={{ margin: "0" }}>Worry not.</p>
          You will not be double-charged for this transaction.
        </p>
        <p style={{ fontSize: "18px", fontWeight: "500" }}>CONTACT SUPPORT</p>
        <div
          onClick={() => handleClick("number")}
          style={{
            display: "flex",
            backgroundColor: "#f1f2f2",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "450px",
            margin: "16px auto",
            borderRadius: "4px",
            padding: "16px 0",
            cursor: "pointer",
          }}
        >
          <div style={{ visibility: "hidden" }}>hidden</div>
          <p
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#ed3833",
              margin: "0",
            }}
          >
            8886660467
          </p>
          <img
            src={Copy}
            style={{ height: "22px", width: "19px", marginRight: "24px" }}
          />
        </div>
        <div
          onClick={() => handleClick("email")}
          style={{
            display: "flex",
            backgroundColor: "#f1f2f2",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "450px",
            margin: "16px auto",
            borderRadius: "4px",
            padding: "16px 0",
            cursor: "pointer",
          }}
        >
          <div style={{ visibility: "hidden" }}>hidden</div>
          <p
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#ed3833",
              margin: "0",
            }}
          >
            support@doosra.io
          </p>
          <img
            src={Copy}
            style={{ height: "22px", width: "19px", marginRight: "24px" }}
          />
        </div>
      </div>
    </div>
  );
}
