import React from "react";
import { toast, ToastContainer } from "react-toastify";
import Copy from "../../assets/images/copy_red.svg";
import Lock from "../../assets/images/lock_locked_red.svg";
import NavBar from "../components/NavBar";

// function getWindowDimensions() {
//   const { innerWidth: width } = window;
//   return {
//     width,
//   };
// }

export default function UserBlocked(props) {
  //   const [width, setWidth] = useState(getWindowDimensions());

  //   useEffect(() => {
  //     function handleResize() {
  //       setWidth(getWindowDimensions());
  //     }
  //     console.log("width", width);
  //     window.addEventListener("resize", handleResize);
  //     // return () => window.removeEventListener("resize", handleResize);
  //   }, []);

  const handleClick = (type) => {
    let value = "";
    if (type === "number") value = "8886660467";
    if (type === "email") value = "support@doosra.io";

    // if (width <= 600) {
    //   if (type === "email") {
    //     window.open("mailto:support@doosra.io");
    //   } else {
    //     document.location.href = "tel:+8886660467";
    //   }
    // } else {
    navigator.clipboard.writeText(value);
    toast.success(`Copied ${type} to clipboard`, {
      autoClose: 3000,
      position: "bottom-center",
    });
    // }
  };

  return (
    <div>
      <NavBar />
      <ToastContainer />
      <div style={{ textAlign: "center", margin: "36px 10% 0" }}>
        <img src={Lock} style={{ width: "80px", height: "104px" }} />

        <h1
          style={{
            maxWidth: "650px",
            fontSize: "clamp(24px,34px,34px)",
            fontWeight: "bold",
            margin: "40px auto 60px",
          }}
        >
          {props.message}
        </h1>
        <h3
          style={{
            maxWidth: "435px",
            fontSize: "clamp(16px,26px,26px)",
            margin: "32px auto",
            fontWeight: "normal",
          }}
        >
          Please contact support to regain access to your account
        </h3>
        <div
          onClick={() => handleClick("number")}
          style={{
            display: "flex",
            backgroundColor: "#f1f2f2",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "450px",
            margin: "16px auto",
            borderRadius: "4px",
            padding: "16px 0",
            cursor: "pointer",
          }}
        >
          <div style={{ visibility: "hidden" }}>hidden</div>
          <p
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#ed3833",
              margin: "0",
            }}
          >
            8886660467
          </p>
          <img
            src={Copy}
            style={{ height: "22px", width: "19px", marginRight: "24px" }}
          />
        </div>
        <div
          onClick={() => handleClick("email")}
          style={{
            display: "flex",
            backgroundColor: "#f1f2f2",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "450px",
            margin: "16px auto",
            borderRadius: "4px",
            padding: "16px 0",
            cursor: "pointer",
          }}
        >
          <div style={{ visibility: "hidden" }}>hidden</div>
          <p
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#ed3833",
              margin: "0",
            }}
          >
            support@doosra.io
          </p>
          <img
            src={Copy}
            style={{ height: "22px", width: "19px", marginRight: "24px" }}
          />
        </div>
      </div>
    </div>
  );
}
