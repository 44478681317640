import * as actionType from "../actions/actionTypes";

const initialState = {
  isUnderMaintainance: false,
  maintainanceMessage: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.MAINTAINANCE:
      return {
        ...state,
        isUnderMaintainance: true,
        maintainanceMessage: action.payload,
      };

    default:
      return {
        ...state,
        isUnderMaintainance: false,
        maintainanceMessage: "",
      };
  }
}
