/* eslint-disable */
import React, { useState } from "react";
import EditIcon from "../../assets/images/edit.svg";
import EditEmailPopup from "./EditEmailPopup";
import EditNamePopup from "./EditNamePopup";
import EditPrimaryNumberPopup from "./EditPrimaryNumberPopup";
import TermsAndConditionsPopup from './TermsAndConditionsPopup';
import SelectReasonPopup from './SelectReasonPopup';
import ConfirmDeleteAccountPopup from './ConfirmDeleteAccountPopup';

export default function ProfileCard(props) {
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);
  const [isNamePopupOpen, setIsNamePopupOpen] = useState(false);
  const [isNumberPopupOpen, setIsNumberPopupOpen] = useState(false);
  const [isDeleteAccountPopupOpen, setIsDeleteAccountPopupOpen] = useState(false);
  const [isContinueToDelete, setIsContinueToDelete] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [deteteReason, setDeleteReason] = useState('');
  const [otpSecret, setOtpSecret] = useState('')

  const closeEditPopup = () => {
    setIsNamePopupOpen(false);
    setIsNumberPopupOpen(false);
    setIsEmailPopupOpen(false);
    setIsDeleteAccountPopupOpen(false);
  };
  return (
    <div className="profile-box side-box">
      <h1 className="side-box-element"> Profile </h1>
      <div className="side-box-element profile-box-element">
        <span className="side-box-bold-element">
          {props.name ? (
            props.name
          ) : (
            <span style={{ color: "#999999", fontWeight: "normal" }}>Name</span>
          )}
        </span>
        <img
          onClick={() => {
            setIsNamePopupOpen(true);
          }}
          closePopup={() => {
            closeEditPopup();
          }}
          className="edit-img-icon"
          src={EditIcon}
        />
      </div>
      <div className="side-box-element  profile-box-element">
        <span>{props.number}</span>
        <img
          onClick={() => {
            setIsNumberPopupOpen(true);
          }}
          closePopup={() => {
            closeEditPopup();
          }}
          className="edit-img-icon"
          src={EditIcon}
        />
      </div>
      <div className="side-box-element  profile-box-element">
        <span>
          {props.email ? (
            props.email
          ) : (
            <span style={{ color: "#999999" }}>Email</span>
          )}
        </span>
        <img
          onClick={() => {
            setIsEmailPopupOpen(true);
          }}
          className="edit-img-icon"
          src={EditIcon}
        />
      </div>
      <div className="side-box-element  profile-box-element">
        <span>
        <h6 
          style={{ color: '#ed3833', cursor: 'pointer', 'font-weight':"600", "font-family": "Montserrat" }}
          onClick={ ()=> { setIsDeleteAccountPopupOpen(true)}}
        >
          Delete My Account
        </h6>
        </span>
      </div>
      <EditNamePopup
        closePopup={() => {
          closeEditPopup();
        }}
        updateUserProfileData={(key, value) => {
          props.updateUserProfileData(key, value);
        }}
        isVisible={isNamePopupOpen}
        name={props.name}
      />
      <EditPrimaryNumberPopup
        closePopup={() => {
          closeEditPopup();
        }}
        updateUserProfileData={(key, value) => {
          props.updateUserProfileData(key, value);
        }}
        isVisible={isNumberPopupOpen}
        number={props.number}
      />
      <EditEmailPopup
        closePopup={() => {
          closeEditPopup();
        }}
        updateUserProfileData={(key, value) => {
          props.updateUserProfileData(key, value);
        }}
        isVisible={isEmailPopupOpen}
        email={props.email}
      />
      <TermsAndConditionsPopup 
        closePopup={() => {
          closeEditPopup();
        }}
        continueToStep2={()=> {setIsContinueToDelete(true)}}
        isVisible={isDeleteAccountPopupOpen}
      />
      <SelectReasonPopup 
        closePopup={() => {
          setIsContinueToDelete(false);
        }}
        selectedDeleteReason = {(reason) => {
          setDeleteReason(reason);
        }}
        getSecret = {(secret) => {setOtpSecret(secret)}}
        number={props.number}
        reason={deteteReason}
        continueToStep3={()=>{setIsConfirmDelete(true)}}
        isVisible={isContinueToDelete}
      />
      <ConfirmDeleteAccountPopup
        closePopup={() => {
          setIsConfirmDelete(false);
        }}
        getSecret = {(secret) => {setOtpSecret(secret)}}
        secret={otpSecret}
        reason={deteteReason}
        number={props.number}
        isVisible={isConfirmDelete} 
      />
    </div>
  );
}
