import * as actionType from "../actions/actionTypes";

const initialState = {
  profileDetails: {},
  loginError: "",
  resetPasswordError: "",
  resetLevel: "enterNumber",
  primaryNumber: "",
  resetSecret: "",
  sessionToken: null,
  userId: null,
  passwordReset: false,
  profileDetails: {},
  loggedOut: false,
  token: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.GET_SESSION_TOKEN_FROM_LOCAL:
      return {
        ...state,
        sessionToken: action.payload,
      };
    case actionType.GET_USER_ID_FROM_LOCAL:
      return {
        ...state,
        userId: action.payload,
      };
    case actionType.LOGIN_FAIL:
      return {
        ...state,
        loginError: action.payload,
      };
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        loginError: "",
        sessionToken: action.payload.session_token,
        profileDetails: action.payload.profile_details,
      };
    case actionType.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        sessionToken: action.payload["x-metro-sessiontoken"],
      };

    case actionType.GET_OTP_FOR_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        resetPasswordError: "",
        resetLevel: "enterOTP",
        primaryNumber: action.payload.phone_number,
        resetSecret: action.payload.secret,
      };
    case actionType.GET_OTP_FOR_PASSWORD_RESET_FAIL:
      return {
        ...state,
        resetPasswordError: action.payload,
      };
    case actionType.VERIFY_OTP_FOR_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordError: "",
        otp: action.payload,
        token: action.token,
        resetLevel: "enterNewPassword",
      };
    case actionType.VERIFY_OTP_FOR_RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordError: action.payload,
      };
    case actionType.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordError: "",
        passwordReset: true,
      };
    case actionType.RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordError: action.payload,
      };
    case actionType.LOGOUT:
      return {
        ...state,
        ...initialState,
        loggedOut: true,
      };
    case actionType.LOGOUT_FALSE:
      return {
        ...state,
        loggedOut: false,
      };
    case actionType.RESET_FORGOT_PASSWORD_DATA:
      return {
        ...state,
        resetLevel: "enterNumber",
        primaryNumber: "",
        resetSecret: "",
        token: null,
        resetPasswordError: "",
        passwordReset: false,
      };
    default:
      return state;
  }
}
