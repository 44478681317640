import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Android from '../../assets/images/android_icon.svg';
import Apple from '../../assets/images/apple_icon.svg';
import Web from '../../assets/images/web_icon.svg';
import { Delete, Get } from '../../utils/httpReguests';

const DeviceCard = (props) => {
  const displayIcon = (login_on) => {
    let device_info = '';
    if (login_on === 'WEB') {
      device_info = (props?.device_info?.browser
          && props?.device_info?.os
          && `${
            `${props.device_info.browser.name
            } ${
              props.device_info.browser.major}`
          } (${props?.device_info?.os?.name})`)
        || login_on;
    } else {
      device_info = (props?.device_info?.device
          && props?.device_info?.os
          && `${props.device_info.device.vendor
          } ${
            props.device_info.device.model}`)
        || login_on;
    }
    switch (login_on) {
      case 'ANDROID':
        return (
          <>
            <img src={Android} style={{ marginRight: '8px' }} />
            <span style={{ textTransform: 'capitalize' }}>{device_info}</span>
          </>
        );
        break;
      case 'IAP_IOS':
        return (
          <>
            <img src={Apple} style={{ marginRight: '8px' }} />
            <span style={{ textTransform: 'capitalize' }}>{device_info}</span>
          </>
        );
        break;
      case 'WEB':
      default:
        return (
          <>
            <img src={Web} style={{ marginRight: '8px' }} />
            <span style={{ textTransform: 'capitalize' }}>{device_info}</span>
          </>
        );
        break;
    }
  };
  return (
    <>
      <li style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            {displayIcon(props.login_on)}
          </div>
          <p style={{ margin: 0, color: '#787878', fontSize: '0.88rem' }}>
            {moment(props?.login_time).format('DD/MM/YYYY hh:mm A')
              || '--/--/--'}
          </p>
        </div>
        <div style={{ textAlign: 'right' }}>
          <p style={{ margin: 0 }}>{props.ip || '0.0.0.0'}</p>
          <p style={{ margin: 0, color: '#787878', fontSize: '0.88rem' }}>
            {props?.ip_info
              && `${props?.ip_info?.city}, ${props?.ip_info?.country}`}
          </p>
        </div>
      </li>
      <hr style={{ borderBottom: 'solid 1px #707070' }} />
    </>
  );
};

export default function DeviceActivityCard(props) {
  const [isCardActive, setIsCardActive] = useState(false);
  const [loggedInDevices, setLoggedInDevices] = useState([]);
  const history = useHistory();
  const showLoggedInDevices = () => {
    setIsCardActive(true);
    Get('v1/internal/w/logged-in-devices')
      .then((res) => {
        setLoggedInDevices(res?.data?.response?.docs);
      })
      .catch((err) => {
        console.log('err fetching loggedin devices list');
      });
  };

  const logoutAllDevices = () => {
    Delete('v1/internal/w/logged-in-devices')
      .then((res) => {
        if (res?.data?.response?.docs === 'success') {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/');
        }
        setIsCardActive([]);
        setLoggedInDevices([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    //   use 'd-none' class to hide card
    <div className="account-info-card">
      <h1>Device Activity</h1>
      <h5>Manage all the sessions currently active on your account</h5>

      {isCardActive ? (
        <div>
          <hr />
          <ul style={{ listStyleType: 'none', margin: '0', padding: '0' }}>
            {loggedInDevices.length > 0
              ? loggedInDevices.map((item) => <DeviceCard {...item} />)
              : null}
          </ul>
          {props.doosraPro ? (
            <>
              <h5>
                You are currently logged in to your Doosra Pro Account on these
                devices.
              </h5>
              <h5>You can use your account on upto 5 mobile devices.</h5>
            </>
          ) : (
            <>
              <h5>
                You are currently signed in to your Doosra Essential Account on
                these devices.
                {' '}
              </h5>
              <h5>
                You can only use your Doosra Essential Account on only 1 mobile
                device.
              </h5>
              <h5>
                {' '}
                {/* <UpgradeToDoosraPro
                  isLimitCard={true}
                  profileData={this.state.profileData}
                  reMountComponent={this.reMountComponent}
                  subscriptionId={upcommingSubscriptionId}
                />{" "} */}
                for 5 device logins.
              </h5>
            </>
          )}
          <h2 onClick={logoutAllDevices}>LOG OUT OF ALL DEVICES</h2>
        </div>
      ) : (
        <h2 onClick={showLoggedInDevices}>EXPAND</h2>
      )}
    </div>
  );
}
