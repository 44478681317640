import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../assets/base.css';


class WaitSteps extends Component {
  render() {

    return (
      <div style={{ 'text-align': 'center' }}>
        <div className="Stepsbar">
          <span className={(this.props.step == 'B') ? 'CurrentStep' : 'Step'}>Enter number</span>

          <span className={(this.props.step == 'C') ? 'CurrentStep' : 'Step'}>Verify OTP</span>

          <span className={(this.props.step == 'D') ? 'CurrentStep' : 'Step'}>Join The Waitlist</span>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  WaitStep: state.register.waitStep,
  virtualNumberAvailable: state.register.virtualNumberAvailable
})


export default connect(mapStateToProps, {})(WaitSteps);