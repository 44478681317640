import React from 'react';
import Popup from 'reactjs-popup';
import CancelButton from '../../assets/images/cancelButton.png';
import SadEmoji from '../../assets/images/sad_emoji_grey.svg';

const contentStyle = { maxWidth: '500px', width: '80%' };

export default function PaymentErrorPopup(props) {
  return (
    <Popup
      open={props.open}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      {...{ contentStyle }}
    >
      <div
        className=""
        style={{
          textAlign: 'center',
          background: 'white',
          height: 'auto',
          padding: '16px',
          borderRadius: '6px',
        }}
      >
        <p style={{ textAlign: 'right' }}>
          <img
            src={CancelButton}
            style={{ width: '24px', height: '24px', cursor: 'pointer' }}
            onClick={props.onClose}
          />
        </p>
        <h1 style={{ fontSize: '31px' }}>Uh ho!</h1>
        <h4 style={{ fontSize: '18px' }}>Something went wrong here</h4>
        <img
          src={SadEmoji}
          style={{ width: '200px', height: '200px', margin: '40px auto' }}
        />
        <p
          style={{
            margin: '0 auto 24px auto',
            fontWeight: '500',
            color: '#808080',
            maxWidth: '280px',
          }}
        >
          Sorry for the inconvenience! Please reach out to support for more
          details if the issue persists
        </p>
        <a
          style={{
            margin: '0',
            fontWeight: '500',
            textDecoration: 'underline',
            color: '#ed3833',
            cursor: 'pointer',
          }}
          href="mailto:support@doosra.io"
        >
          support@doosra.io
        </a>
      </div>
    </Popup>
  );
}
