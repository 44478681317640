import { post } from "../utils/HttpRequests";

export const submitSecureDataNumber = (data) => {
  return new Promise((resolve, reject) => {
    post("w/add-number-to-coupon", data)
      .then((resp) => {
        resolve(resp.data.response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
