import React from 'react';
const ErrorAlert = require("../../assets/images/error_alert.png");

function Error(props) {
  return (
    <div
      style={{
        border: "1px solid #ed3833",
        display: "flex",
        padding: "12px",
        margin: '20px 0'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <img
          src={ErrorAlert}
          style={{
            height: "16px",
            width: "16px",
          }}
        />
      </div>
      <p
        className="Error"
        style={{ textAlign: "left", color: "#ed3833", padding: '0 25px' }}
      >
        {props.error}
      </p>
    </div>
  )
}


export default Error;