import * as actionType from './actionTypes';
import { get, post, sess_get, sess_post, post_v2 } from '../utils/HttpRequests';

export const setTokenFromLocal = () => dispatch => {
  let sessionToken = localStorage.getItem('doosra-user-sessionToken');
  let userId = localStorage.getItem('doosra-user-id');
  if (sessionToken !== null || sessionToken !== '') {
    dispatch({
      type: actionType.GET_SESSION_TOKEN_FROM_LOCAL,
      payload: sessionToken
    })
  }
  if (userId !== null || userId !== '') {
    dispatch({
      type: actionType.GET_USER_ID_FROM_LOCAL,
      payload: userId
    })
  }
}


export const login = (data) => dispatch => {

  post('w/login', data)
    .then((resp) => {

      if (resp.data.response) {
        localStorage.setItem('doosra-user-sessionToken', resp.data.response.session_token);
        localStorage.setItem('doosra-user-id', resp.data.response.profile_details.user_id);
        dispatch({
          type: actionType.LOGIN_SUCCESS,
          payload: resp.data.response
        })

      } else {
        dispatch({
          type: actionType.LOGIN_FAIL,
          payload: 'login failed'
        })
      }
    })
    .catch((err) => {
      dispatch({
        type: actionType.LOGIN_FAIL,
        payload: err.response.data.error.reason
      })
    })
}

export const autoLogin = (data) => dispatch => {
  return post('w/auto_login', data)
    .then((resp) => {

      if (resp.data.response) {
        localStorage.setItem('doosra-user-sessionToken', resp.data.response.session_token);
        localStorage.setItem('doosra-user-id', resp.data.response.profile_details.user_id);
        dispatch({
          type: actionType.LOGIN_SUCCESS,
          payload: resp.data.response
        })

      } else {
        // dispatch({
        //   type: actionType.LOGIN_FAIL,
        //   payload: 'login failed'
        // })
      }
      return Promise.resolve();
    })
    .catch((err) => {
      // dispatch({
      //   type: actionType.LOGIN_FAIL,
      //   payload: err.response.data.error.reason
      // })
      return Promise.reject();
    })
}

export const logout = (sessionToken) => dispatch => {
  // localStorage.getItem('doosra-user-sessionToken');

  localStorage.removeItem('doosra-user-sessionToken');
  localStorage.removeItem('doosra-user-id');
  sess_post('w/profile/logout', sessionToken)
    .then((response) => {
      if (response.data !== undefined && response.data.success) {
        dispatch({
          type: actionType.LOGOUT
        })
      }
    })
    .catch((err) => {
      console.log(err);
    })
}

export const setLogOutFalse = () => dispatch => {
  dispatch({
    type: actionType.LOGOUT_FALSE
  })
}

export const getOtpforPasswordReset = (phone_number, token) => dispatch => {

  post('w/profile/verify', { phone_number: phone_number, recaptchaToken: token })
    .then((resp) => {
      if (resp.data.response.is_registered_user) {
        post_v2('w/send-otp', { phone_number: phone_number,  recaptchaToken: token })
          .then((resp) => {
            dispatch({
              type: actionType.GET_OTP_FOR_PASSWORD_RESET_SUCCESS,
              payload: resp.data.response
            })
          })
          .catch(err => {
            dispatch({
              type: actionType.GET_OTP_FOR_PASSWORD_RESET_FAIL,
              payload: err.response.data.error.reason
            })
          })
      } else {
        dispatch({
          type: actionType.GET_OTP_FOR_PASSWORD_RESET_FAIL,
          payload: 'number not registered'
        })
      }
    })
    .catch((err) => {
      dispatch({
        type: actionType.GET_OTP_FOR_PASSWORD_RESET_FAIL,
        payload: err.response.data.error.reason
      })
    })
}


export const resendOTPforPasswordReset = (phone_number) => dispatch => {
  post_v2('w/send-otp', { phone_number: phone_number })
    .then((resp) => {

      dispatch({
        type: actionType.GET_OTP_FOR_PASSWORD_RESET_SUCCESS,
        payload: resp.data.response
      })

    })
    .catch((err) => {
      dispatch({
        type: actionType.GET_OTP_FOR_PASSWORD_RESET_FAIL,
        payload: err.response.data.error.reason
      })
    })
}

export const verifyOTPforPasswordReset = (data) => dispatch => {
  // dispatch({
  //   type: actionType.STEP_THREE
  // })

  post_v2('w/verify-otp', data)
    .then((resp) => {
      // console.log(resp.data);
      if (resp.data.response.isValidOTP) {
        dispatch({
          type: actionType.VERIFY_OTP_FOR_RESET_PASSWORD_SUCCESS,
          payload: data.otp,
          token: resp.data.response.token
        })

      } else {
        dispatch({
          type: actionType.VERIFY_OTP_FOR_RESET_PASSWORD_FAIL,
          payload: 'Invalid OTP'
        })
      }
    })
    .catch((err) => {

      dispatch({
        type: actionType.VERIFY_OTP_FOR_RESET_PASSWORD_FAIL,
        payload: err.response.data.error.reason
      })
    })


}


export const resetPassword = (data) => dispatch => {

  post_v2('w/forgot-password', data)
    .then((resp) => {
      if (resp.data.response.status === 'Updated successfully') {
        dispatch({
          type: actionType.RESET_PASSWORD_SUCCESS,
          payload: resp.data.response.status
        })
      }
    })
    .catch((err) => {
      dispatch({
        type: actionType.RESET_PASSWORD_FAIL,
        payload: err.response.data.error.reason
      })
    })
}


export const getProfileDetails = () => dispatch => {
  let sessionToken = localStorage.getItem('doosra-user-sessionToken');
  sess_get('w/profile', sessionToken)
    .then(resp => {
      dispatch({
        type: actionType.FETCH_USER_PROFILE_DETAILS_SUCCESS,
        payload: resp.data.response.profile_details
      })
      if (resp.data.response.profile_details.status == "DROPPED_OFF") {
        dispatch({
          type: actionType.STEP_THREE
        })
      }
    })
    .catch(err => {
      console.log('err');
    })
}

export const resetForgotPasswordData = () => dispatch => {
  dispatch({
    type: actionType.RESET_FORGOT_PASSWORD_DATA
  })
}