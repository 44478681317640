import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import '../../assets/css/modal.css';
import CheckmarkBlackBig from '../../assets/images/checkmark-circle-black-big.svg';
import CrossImg from '../../assets/images/cross.svg';
import { Post } from '../../utils/httpReguests';

export default function CancelFreeTrialPopup(props) {
  const [success, setSuccess] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [planAmount, setPlanAmount] = useState(0);
  const [error, setError] = useState('');

  const Proceed = async (e) => {
    e.preventDefault();
    await Post('v1/internal/w/cancel-upcoming-subscription', {
      subscription_id: props.orderHistory.subscription_id,
    })
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        setError(
          err?.response?.data?.error?.reason || 'error canceling free trial',
        );
      });
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const closePopupWithUpdate = () => {
    setIsPopupOpen(false);
    setSuccess(false);
    // this.props.reMountComponent();
  };

  const popper = (
    <Popup open={isPopupOpen} closeOnDocumentClick={false}>
      <img
        className="cross-button"
        src={CrossImg}
        onClick={success ? closePopupWithUpdate : closePopup}
      />
      {success ? (
        <div className="Modal">
          <div className="PlanUpgradeModalHeading">Free Trial Cancelled</div>
          <div className="ModalBody " style={{ textAlign: 'center' }}>
            <img src={CheckmarkBlackBig} style={{ marginBottom: '35px' }} />
            <div className="PlanUpgradeModalSubHeading">
              <p style={{ marginBottom: '0px' }}>
                You can continue using your Doosra
              </p>
              <p>7 Day Free Trial till its validity expires.</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="Modal">
          <div
            className="PlanUpgradeModalHeading "
            style={{ color: '#ee3048' }}
          >
            Cancel your free trial?
          </div>
          <div className="PlanUpgradeModalSubHeading">
            <p style={{ marginBottom: '0px' }}>
              You only get 1 free trial per account,
            </p>
            <p>are you sure you want to proceed with this?</p>
          </div>
          <div className="ModalBody " style={{ textAlign: 'center' }}>
            <strong>What didn’t you like?</strong>
            <p>
              Write to us at
              {' '}
              <strong>feedback@doosra.io</strong>
            </p>
          </div>
          <div className="ModalButtonHolder">
            <button className="ModalButtonTwo" onClick={Proceed}>
              Yes, cancel my free trial
            </button>
            <button className="ModalButtonTwo Invert" onClick={closePopup}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </Popup>
  );
  if (
    props.profileData?.free_trial
    && props?.profileData.free_trial_cancelled == false
  ) {
    return (
      <div className="side-box-element">
        <span style={{ opacity: '0.5' }}>
          <span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => setIsPopupOpen(true)}
            >
              Cancel Free Trial
            </span>
            {popper}
          </span>
        </span>
      </div>
    );
  }
  return null;
}
