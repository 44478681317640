import React, { Component } from "react";
import { Redirect } from "react-router";
import "../../assets/css/giftSection.css";
import GoogleTag from "../../v2/components/GoogleTag";
import InCNavbar from "../InCNavBar";
import ProvideEmail from "./ProvideEmail";
import SelectGift from "./SelectGift";

class GiftCardMain extends Component {
  constructor() {
    super();
    this.state = {
      currentView: 1,
      email: "",
      couponsData: {},
      couponsTotal: 0,
      discountCode: "",
    };
  }

  componentDidMount() {
    window.dataLayer.push({
      event: "GiftCard",
      pagePath: "/gift-card",
      pageTitle: "GiftCard",
    });
    GoogleTag();
  }

  selectCoupons = (data, amount, discount) => {
    this.setState({
      couponsData: data,
      currentView: 2,
      couponsTotal: amount,
      discountCode: discount,
    });
  };

  makePayment = () => {
    this.setState({ currentView: 3 });
  };

  render() {
    let display;
    switch (this.state.currentView) {
      case 1:
        display = <SelectGift selectCoupons={this.selectCoupons} />;
        break;
      case 2:
        display = (
          <ProvideEmail
            makePayment={this.makePayment}
            coupons={this.state.couponsData}
            discount={this.state.discountCode}
          />
        );
        break;
      case 3:
        display = (
          <Redirect
            to={{
              pathname: "gift-card/success",
              state: { couponsTotal: this.state.couponsTotal },
            }}
          />
        );
        break;
    }

    return (
      <React.Fragment>
        <InCNavbar />
        <div style={{ "text-align": "center" }}>{display}</div>
      </React.Fragment>
    );
  }
}

export default GiftCardMain;
