import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import RedLoader from '../assets/images/loader_red.gif';

export default function Loading(props) {
  if (props.popup) {
    return (
      <Popup open closeOnDocumentClick={false}>
        <img src={RedLoader} />
        ;
      </Popup>
    );
  }

  return <img src={RedLoader} />;
}
