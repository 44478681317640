import React from 'react';
import CountDownTimer from './timerNew';
import WhiteCart from '../../assets/images/cart_white.png';

export default function CountDownBar(props) {
  let displayNumber = '';
  switch (sessionStorage.getItem('selectedNumberType')) {
    case 'VIP':
    case 'SIMILAR':
    case 'PREMIUM':
      displayNumber = sessionStorage.getItem('number');
      if (displayNumber) { displayNumber = displayNumber.substring(2); } else { displayNumber = ''; }
      break;
    case 'DEFAULT':
      displayNumber = sessionStorage.getItem('defaultNumber');
      if (displayNumber) { displayNumber = displayNumber.substring(2); } else { displayNumber = ''; }
      break;
    default:
      displayNumber = '';
      break;
  }

  if (displayNumber === '') {
    return <div />;
  }

  return (
    <div className="countdown-bar">
      <div className="countdown-bar-content">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

          <img src={WhiteCart} />
          <p style={{ margin: '0', marginLeft: '12px' }}>{`We have reserved ${displayNumber} for 5 mins, please complete checkout within this time to claim this number`}</p>
        </div>
        <CountDownTimer />
      </div>
    </div>
  );
}
