import React from "react";
import Popup from "reactjs-popup";
import Preloader from "../../assets/images/preloader_circle_red.gif";
export default function PaymentConfirmationLoader(props) {
  return (
    <Popup open={props.open} closeOnDocumentClick={false} closeOnEscape={false}>
      <div
        className=""
        style={{
          minWidth: "300px",
          textAlign: "center",
          background: "white",
          height: "auto",
          padding: "30px",
          borderRadius: "6px",
        }}
      >
        {props.count}
        <img src={Preloader} style={{ width: "64x", height: "64px" }} />
        <p style={{ margin: "0", fontWeight: "500" }}>
          We are processing your payment
        </p>
        <p style={{ margin: "0", fontWeight: "500" }}>
          Please do not refresh or close this tab
        </p>
      </div>
    </Popup>
  );
}
