import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Popup from "reactjs-popup";
import "../../assets/css/modal.css";

function Pop(props) {
  return (
    <Popup open={props.open} closeOnDocumentClick={false}>
      <div className="Modal">
        <div className="ModalHeading">Uh-oh!</div>
        <div className="ModalBody">
          Looks like you are out of time. No worries, click <strong>OK</strong>{" "}
          to go back and choose a number.
        </div>
        <div className="ModalButtonHolder">
          <button className="ModalButton" onClick={props.closePopup}>
            OK
          </button>
        </div>
      </div>
    </Popup>
  );
}

export default function App() {
  const [timer, setTimer] = React.useState(null);
  const [isZero, setIsZero] = React.useState(false);
  const history = useHistory();
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {
    const timeNow = moment();
    let timeAllotted;
    let timeBlock;
    if (sessionStorage.getItem("selectedNumberType") === "DEFAULT") {
      timeAllotted = moment(sessionStorage.getItem("defaultNumberBlockedAt"));
      timeBlock = sessionStorage.getItem("defaultNumberBlockedFor");
    } else {
      timeAllotted = moment(sessionStorage.getItem("numberBlockedAt"));
      timeBlock = sessionStorage.getItem("numberBlockedFor");
    }

    const diff = timeAllotted.diff(timeNow, "seconds") + timeBlock * 60;

    setTimer(diff);
  }, [
    sessionStorage.getItem("selectedNumberType"),
    sessionStorage.getItem("defaultNumber"),
    sessionStorage.getItem("number"),
  ]);

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    return () => clear();
  }, []);

  React.useEffect(() => {
    if (timer && timer <= 0) {
      clear();
      setIsZero(true);
    }
  }, [timer]);

  const closePopup = () => {
    setIsZero(false);
    history.push("/choose-number");
  };

  const formatTime = (secs) => {
    if (secs <= 0) {
      return "00:00";
    }
    let minutes = Math.floor(secs / 60) % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    let seconds = secs % 60;
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  };

  return (
    <React.Fragment >
      <h5 > {formatTime(timer)} </h5>
      <Pop open={isZero} closePopup={closePopup} />
    </React.Fragment>
  );
}
