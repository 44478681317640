import React from 'react';
import Popup from 'reactjs-popup';

export default function ToSPopup(props) {
  const TnC = (
    <>
      <h5>Terms and Conditions</h5>
      THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES THERE UNDER AS APPLICABLE. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
      <br />
      For the purposes of these terms and conditions, wherever the context so requires “you” or “your” shall mean any natural person accessing the Doosra website, mobile application or other digital properties which shall be together called the “platform” or “site”​.
      <br />
      For the purposes of these terms and conditions, wherever the context so requires “Company”, “we”, “us” or “our” shall mean Ten20 InfoMedia Private Ltd having its registered office at ​4th & 5th Floor, Gutenberg IT Park, Kala Jyothi Building, Survey No. 185, Kondapur, Hyderabad, Rangareddy, Telangana- 500084
      <br />
      This page contains the terms and conditions concerning your use of the Site. By accessing, browsing and /or using this Site, you agree to be bound by the following terms and conditions as may be amended from time to time. ​You agree that the following terms and conditions shall apply or any other document signed by you in this regard. ​These terms and conditions include additional terms and policies which are referenced elsewhere in this document. If you do not agree to these terms, please do not access the Site or avail any service through the Site.
      <br />
      <br />
      <h6>A. Your Information and Privacy</h6>
      <br />
      1. By accessing or using the platform, you confirm that you have read, understood and agreed to conform to these terms and conditions; you also warrant the accuracy, truthfulness and currency of all information that you have submitted while accessing or using the ​platform​. If you provide any information which is or we have reasonable grounds to suspect that the information is untrue or incorrect, Company may reserve the right to suspend or terminate your access to the Site.
      <br />
      2. In a few areas of the Site (including but not limited to the registration page) we may ask you to provide personal information and for this purpose, you permit us to contact you. This allows us to provide and inform you of the latest services and features that most likely meet your needs, and to customize our Site to make your experience safer and easier and will enable us to use the information you provide us with to keep you informed of our latest products and services after your visit to the ​platform.
      <br />
      3. Access to specific features of the Site shall be restricted. Only users with the appropriate credentials can access the Site. The user identity shall be provided by the Company and authentication can be done either by sending an OTP (one-time password) to the user's primary mobile number or via a password that shall be defined by the user. The user shall, at all times, have access to the primary number so that periodic authentication can be done via an OTP to login to secure aspects of the site or mobile application. The password, if provided, during the sign-up process, shall be encrypted and stored on the Site server. We strongly recommend that the password be retained confidential. The password is an authentication tool and we recommend that each User periodically changes the password. You shall remain solely responsible for maintaining the confidentiality of your username, password or account details which you have created for availing services through the ​platform. You agree to immediately notify the Company of any unauthorised use of your password or account or any other security breach.
      <br />
      4. You agree that we may collect certain non-personally identifiable information about our visitors when you visit our Site. We use the non-personally identifiable information that we collect to improve the design and content of our service pages and to enable us to personalize your Internet experience. We also may use this information in the aggregate to analyse service usage.
      <br />
      5. Company may rely on the authority of anyone accessing your account using your password and You agree that the Company has no responsibility and liability with regard to the privacy, safety and security of any information (including personal information) that you may share voluntarily with third parties for availing their products or services, through the Site or any compromise of the confidentiality of your account/ password or any authorized access to your account/password.
      <br />
      6. You authorize and give consent to the support representatives of Ten20 Infomedia Private Limited (“Doosra") to contact you via SMS/other messages or calls or commercial communication, and other services on the primary number listed in your Doosra account, whether these numbers are registered with National Do Not Call Registry/ listed in National Customer Preference Register or not. You confirm that by sending any of such messages/calls, you will not hold Doosra, its third party service provider liable/institute complaint under the Telecom Commercial Communications Customer Preference(TRAI) Regulations, 2010 or such other applicable regulations including any amendment thereof, as may be applicable from time to time. This authorization will be auto renewed every month. Should you wish to no longer be contacted for the purposes of using the service, please write an email to opt-out@doosra.io and we will make the necessary communication preference changes within 48 hours. Please note that we will not be able to answer customer queries including account balance, status, renewal status or any other service provided by Doosra via phone in case the authorization is revoked.
      <br />
      <h5>B. Technical Requirement:</h5>
      <br />
      1. If your PC does not support relevant technology including but not limited to encryption you may not be able to use certain services or access certain information on the Site. You are therefore advised to obtain, install and maintain suitable equipment and software to access the Site. You agree that you are solely responsible for the security of your computer or password or your data. Company is not responsible for the backup or restoration of your data/material.
      <br />
      <h6>
        <strong>Your use of Contents:</strong>
      </h6>
      <br />
      2. The content (information, communications, images, logos, trade names / trademarks, videos and sounds contained on or available through Site) is provided by Ten20 InfoMedia Private Limited, its affiliates, independent content providers and third parties. The contents available on the Site are properties of Ten20 InfoMedia Private Limited, its affiliates, independent content providers or third parties. All rights reserved. The contents of the Site cannot be reproduced, modified, transferred, distributed, republished, downloaded, posted or transmitted in any form or by any means including but not limited to electronic, mechanical photocopying or recording without the prior written permission of Ten20 InfoMedia Private Limited. You agree that the material and content contained within or provided by the Site is permitted for your own personal use only and may not be used for commercial purposes or distributed commercially. You may use the content for your personal use, provided you retain all copyright and proprietary notices on the content. You will not use the Site and its content in any way which has an unlawful or fraudulent purpose or effect. The Company reserves the right to charge for access to certain information on the platform.​ Ten20 InfoMedia Private Limited will inform you where a charge will be incurred by you for accessing certain services or information on the ​platform​.
      <br />
      3. The Company shall not assume or accept any responsibility or liability for the contents which are made available by independent content providers or third parties on the Site or any other website linked with the ​platform​. You agree to access and / or avail such contents at your own risk, responsibility and liability​.
      <br />
      4. Company may rely on the authority of anyone accessing your account using your password and You agree that the Company has no responsibility and liability with regard to the privacy, safety and security of any information (including personal information) that you may share voluntarily with third parties for availing their products or services, through the Site or any compromise of the confidentiality of your account/ password or any authorized access to your account/password.
      <br />
      5.Your use of the platform/site and/or the services rendered through the platform/site, shall not in any manner be construed to mean a transfer, marketing, re-branding, reselling, or assignment of the phone number/identity number/services of the telecom service provider, by the Company to you.
      <br />
      <h5>C. No Warranty:</h5>
      <br />
      1. While the Company shall make all reasonable efforts to update or correct any information that appears on this Site, we do not make any representation or warranty (expressed or implied) in relation to or in any way guarantee the accuracy of information available on the Site at any given time or make any representation or warranty with regard to merchantability, title, fitness for a purpose or non-infringement. Ten20 InfoMedia Private Limited would not be responsible for any misinformation, inconvenience, loss or financial or other damage caused to you due to the use of this Site. Information is supplied upon the condition that the persons receiving the same will make their own determination as to its suitability for their purposes prior to its use or in connection with the making of any decision using such information. Any use of this Site or the information available on this Site is at your own risk. Neither the Company and its affiliates, nor their directors, officers, employees or agents shall be liable for any loss, damage or expense arising out of any access to, use of, or reliance upon, this Site or the information and Content available on this Site, or any website linked to this Site. The Site including the content or information therein is provided on “​as is basis”​.
      <br />
      2. The Site may contain forward-looking statements or projections. They are made on reasonable assumptions and are subject to various risks, uncertainties and factors that could cause actual outcome/results to be materially different.
      <br />
      3. The Company shall use all reasonable care to maintain the Site in a fully operating condition. It is not responsible for the results of any defects that may exist on the Site. No warranties are given that Site or its content will be uninterrupted or virus-free or error-free or that it will be suitable for the particular purpose that you have in mind when using it. The Company does not accept responsibility for any defects that may exist or for any costs, loss of profits, loss of data, or consequential losses arising from your use of, or inability to use or access or a failure, suspension or withdrawal of all or part of the service at any time. It is a condition of the Company for allowing you access to the information on Site that you accept that the Company will not be liable for any action you take relying on the information on the ​platform​. If your use of the materials or information from Site results in the need for servicing, repair or correction of equipment or data, you assume any costs relating to the above, we would not be liable for the same.
      <br />
      4. This Site may be linked to other websites on the World Wide Web that are not under the control of or maintained by the Company. Such links do not indicate any responsibility or endorsement on our part for the concerned external website, its contents or the links displayed on it. These links are provided only as a convenience, in order to help you find relevant websites, services and/or products that may be of interest to you, quickly and easily. Ten20 InfoMedia Private Limited makes no representations or warranties, express or implied, concerning any products, services, content or information found on any linked websites. The access to these websites is at your risk. The Company is not responsible for any errors, omissions or representations on any of our pages or on any links on any of their pages. Company is not responsible for the data collection activities of these websites. You are requested to verify the accuracy of all information on your own before undertaking any reliance and consult the terms and conditions of the relevant website. It is your responsibility to decide whether any services and/or products available through any of these websites are suitable for your purposes. We do not endorse or recommend the products or services offered through these websites. The Company informs you that it is not responsible for any transactional errors, misinformation and frauds taking place on those sites.
      <br />
      <h5>D. Intellectual Property Rights:</h5>
      <br />
      1. Ten20 InfoMedia Private Limited and any other Company product or service names referred to on the Site are trademarks of Ten20 InfoMedia Private Limited. Your access to the Site or use thereof shall not grant in your favour, by implication, estoppel or otherwise, any license, right, title or interest to use any marks appearing on the Site without the prior written consent of the Company or the third party owner thereof.
      <br />
      2. By accessing or using platform, you confirm to acknowledge and abide by all intellectual and industrial property rights.
      <br />
      3. This Site may contain factual references to the names of certain companies, products and individuals. Ten20 InfoMedia Private Limited makes no representation of having a business relationship or tie-up with any such companies, products or individuals. All trade names shall continue to remain owned by their respective owners.
      <br />
      <h5>E. Referral Rewards:</h5>
      <br />
      1. Ten20 InfoMedia Private Limited reserves the right to extend, cancel, discontinue, suspend or prematurely withdraw the Referral Rewards accrued by the user at any time during its validity as may be required in view of business exigencies and/or changes in regulatory guidelines and/or statutory changes without any notice to You and the same shall be binding on the participating Subscribers availing this Offer.
      <br />
      2. You agree that Referral Rewards are being made purely on a “best endeavour” basis and subject to acceptance of these Terms and Conditions.
      <br />
      <h5>F. Disclaimers:</h5>
      <br />
      1. You agree that this Site is a platform which is being provided purely on a “best endeavour” basis and subject to acceptance of these Terms and Conditions. Your access or use of the Site is voluntary and you expressly agree to access Site at your sole risk. The Company shall not be responsible or liable for any loss, injury or any other liability (financial or otherwise) to any user arising due to access or use of the platform​.
      <br />
      2. The service is accessed via the World Wide Web which is independent of the Site and the Company​. Your use of the World Wide Web is solely at your own risk and subject to all applicable national and international laws and regulations. The Company has no responsibility for any information or service obtained by you on the World Wide Web.
      <br />
      3. In your use of the Site, you may enter into correspondence with, purchase contents, goods and/or services from, or participate in promotions by advertisers or members or sponsors of the Site. Unless otherwise stated, any such correspondence, advertisement, purchase or promotion, including the delivery of and the payment for goods and/or services, and any other terms, conditions, warranty or representation associated with such correspondence, purchase or promotion, is solely between you and the concerned third party and the contract so entered into is strictly a bipartite contract. While Ten20 InfoMedia Private Limited shall take all reasonable care in arranging for such third parties, the Company does not guarantee or otherwise assure quality or timeliness of the services or performance otherwise of/by any such third party. We do not guarantee that the third parties will perform any of the transactions entered into on this Site. You agree that The Company has no liability, obligation or responsibility whatsoever for any such correspondence, purchase or promotion between you and any such third party. You shall independently agree upon the manner and terms and conditions of delivery, payment, insurance etc. with the other registered users/third parties that you transact with.
      <br />
      4. The Site is a secure website and the Company will take all reasonable steps to maintain the Site as a secure website. However, even secure websites are susceptible to infiltration and/or corruption. There are a number of transactional facilities on the platform,​ wherein you can pay your bills, or get certain services activated by paying the requisite amount online. Except for the payment facilities available through the Site, the provision of services is governed by the relevant service contract. The Company would not in any way be responsible for any damage or loss caused to you as a result of a financial transaction on the Site. The Company would also not be responsible or liable in any manner if your credit card / net banking is misused/used fraudulently while being used on the Site. Ten20 InfoMedia Private Limited would not be responsible or liable in any manner for any error or defects that may exist or for any costs, loss of profits or consequential losses that may arise from your use of or inability to use, or access or a failure, suspension or withdrawal of all or part of the facilities available through the Site at any time. The Company accepts no liability or responsibility for loss of data or breach of confidentiality or other consequences howsoever occurring. In case there has to be a reversal of money paid online, it would be entirely dependent on the payment gateway's terms and conditions and the
      Company would not be responsible or liable in any manner for quick reversal or for expediting the process.
      <br />
      <h5>G. Limitation of Liability:</h5>
      1. In no event Ten20 InfoMedia Private Limited including its affiliates, directors, officers, agents, employees shall be liable for any loss or damage whatsoever including not limited to direct, indirect, punitive, incidental and consequential damages, loss of profit, loss of data, loss of goodwill resulting from the use or inability to use the Site whether based on warranty, contract, tort or in any other means or action.
      <br />
      2. The Company, to the fullest extent permitted by law, disclaims all warranties and liability related to your access or use of the Site. Recognizing the special circumstances relating to the Site you agree to waive as against the Company, its affiliates, officers and employees, to the fullest extent permissible by law, any claims including for loss of business howsoever arising from or relating to the use of the Site. Without prejudice to the above the Company's maximum liability, if any, is restricted to the transaction fees paid to Ten20 Infomedia Private Limited by you in respect of the relevant service.
      <br />
      <h5>H. Indemnity:</h5>
      1. You shall indemnify and keep indemnified the Company and its officers, directors, employees, customers, affiliates, agents and licensors harmless from and against any and all claims (including third party claim), losses, suits, proceedings, action, liabilities, damages, expenses and costs (including attorney’s fees and court costs) which the Company may incur, pay or become responsible for as a result of breach or alleged breach of your representations or obligations under the Terms and Conditions, any failure by you to comply with applicable law, breach of privacy, defamation, infringement of intellectual property rights of the Company and any third party claim in respect of misuse of any information of a third party. Ten20 InfoMedia Private Limited shall have the right to defend itself, pursuant to this Clause, subject to your indemnification. These indemnity obligations will survive termination of your relationship with Company or you ceasing to use the Site.
      <br />
      <h5>I. Cookies Policy</h5>
      Company uses cookies on the platform. By using the Service, you consent to the use of cookies. Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.
      <br />
      1. Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you. Cookies can be "persistent" or "session" cookies.
      <br />
      2. When you use and access the Service, we may place a number of cookies files in your web browser. We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences, to enable advertisements delivery, including behavioral advertising. We use both session and persistent cookies on the Service and we use different types of cookies to run the Service. Essential cookie
      - We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.
      <br />
      3. In addition to our own cookies, we may also use various third-parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.
      <br />
      4. If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
      <br />
      <h5>J. Restrictions on usage:</h5>
      1. You agree not to cause, nor knowingly allow others to cause, any nuisance, annoyance, or inconvenience, whether to the Company or any of its customers or users of the Siteby any means. You also agree not to use the Site to transmit or post any material which is defamatory, offensive or obscene or menacing character or which may in our judgement cause nuisance, annoyance, inconvenience to the Company, its users, or any other person or which is illegal. You will not use the Site such that you would cause the whole or part of the Site to be interrupted, damaged, or rendered less efficient or impaired in any way. The Company does not routinely monitor your postings to the Site but reserves the right to do so. In our efforts to promote good citizenship within the Internet community, if we become aware of inappropriate use of the Site or any of its services, the Company will respond in any way that, in its sole discretion, it deems appropriate. You are prohibited from probing / testing / violating the security of the Site or attempting to including, without limitation (i) accessing data not intended for you or logging into a service or account which you are not authorized to access, (ii) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures, (iii) attempting to interfere with service to any user, host or network including, without limitation, via means of submitting a virus or "trojan horse", overloading, "flooding", "mail bombing" or "crashing" or (iv) sending unsolicited electronic mail including, promotions and/or advertising of other products or services.
      <br />
      2. You may not mirror any material contained on the Siteon any other server without the prior written consent of Ten20 Infomedia Private Limited. Any unauthorised use of the contents of the Site may be in breach of copyright laws or trademark laws.
      <br />
      <h5>K. Suspension or Modifications of Terms and Conditions:</h5>
      You accept and agree that the Company may suspend or vary the whole or any part of the Site for any reason, at any time at its sole discretion without prior notice. The Company hereby reserves the right to make subsequent changes to, the Site or its content and services may be modified, varied, supplemented or withdrawn, if so required in view of business exigencies and/or change in regulatory guidelines issued / amended by the relevant government authority and/or statutory changes and the same shall be binding on you. Such variations etc. will be deemed effective and in force immediately upon posting of the varied terms on the Site. You are therefore advised to check this Site regularly for any update(s) or amendment(s) made to these Terms and Conditions and in the event the modified or amended terms and conditions are not acceptable to You, You should discontinue accessing the Site. Your continued usage of the Site in any manner will constitute your acceptance of the latest revised terms and conditions. These terms and conditions along with a separate written or electronic contract if any between you and Company constitutes the complete agreement between you and Company. The failure to exercise or enforce any provision shall not be deemed as a waiver of that provision. If any provisions of these terms and conditions are held to be void or unlawful, it shall not affect the validity of the balance provisions. Company may assign its rights and obligations herein any time without notice to you. Your rights and obligations are not assignable without our consent.
      <br />
      <h5>L. Governing Law and Jurisdiction:</h5>
      These terms and conditions are governed in accordance with the laws of India. Any dispute shall be subject to the exclusive jurisdiction of courts in Hyderabad.
    </>
  );
  return (
    <Popup open={props.isOpen} closeOnDocumentClick={false}>
      <div
        style={{
          maxHeight: '600px',
          maxWidth: '530px',
          width: '96%',
          border: '12px solid black',
          backgroundColor: '#ffffff',
          padding: '20px 30px',
          margin: '10px',
        }}
      >
        <div style={{ maxHeight: '450px', height: '80%', overflow: 'scroll' }}>
          <div className="ModalHeading">Doosra Terms and Conditions</div>

          <div className="ModalBody">
            {' '}
            {TnC}
          </div>
        </div>

        <div className="ModalButtonHolder">
          <button className="ModalButton ButtonInvert" onClick={props.close}>
            Close
          </button>
        </div>
      </div>
    </Popup>
  );
}
