import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {
  getOtpforPasswordReset,
  resendOTPforPasswordReset,
  resetForgotPasswordData,
  resetPassword,
  verifyOTPforPasswordReset,
} from "../actions/UserAccountActions";
import Recaptcha from "../utils/Recaptcha";
import InCNavbar from "./InCNavBar";
import PasswordCheck from "./PasswordCheck";
const GreenTick = require("../assets/images/checkmark_green.svg");
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
      otp: "",
      password: "",
      confirmPassword: "",
      resetLevel: "enterNumber",
      charNumberValid: false,
      specialCharValid: false,
      uppercaseValid: false,
      lowercaseValid: false,
      numberValid: false,
      otpResent: false,
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem("doosra-user-sessionToken") &&
      localStorage.getItem("doosra-user-sessionToken") != null
    ) {
      this.props.history.push("/user-dashboard");
    }
  }

  checkPasswordLength = (password) => {
    if (password.length >= 6) {
      this.setState({
        charNumberValid: true,
      });
    } else {
      this.setState({
        charNumberValid: false,
      });
    }
  };

  // Check for special characters
  checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      this.setState({
        specialCharValid: true,
      });
    } else {
      this.setState({
        specialCharValid: false,
      });
    }
  };

  // Check for an uppercase character
  checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      this.setState({
        uppercaseValid: true,
      });
    } else {
      this.setState({
        uppercaseValid: false,
      });
    }
  };

  // Check for an lowercase character
  checkLowercase = (password) => {
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      this.setState({
        lowercaseValid: true,
      });
    } else {
      this.setState({
        lowercaseValid: false,
      });
    }
  };

  // Check for a number
  checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      this.setState({
        numberValid: true,
      });
    } else {
      this.setState({
        numberValid: false,
      });
    }
  };

  componentWillUnmount() {
    this.props.resetForgotPasswordData();
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.id == "password") {
      this.checkPasswordLength(e.target.value);
      this.checkSpecialCharacters(e.target.value);
      this.checkUppercase(e.target.value);
      this.checkLowercase(e.target.value);
      this.checkNumber(e.target.value);
    }
  };

  handleSubmitNumber = (e) => {
    Recaptcha.execute({ action: Recaptcha.ACTIONS.FORGOT_PASSWORD_OTP }).then(
      (token) => {
        this.props.getOtpforPasswordReset(this.state.number, token);
      }
    );
  };

  handleSubmitOTP = (e) => {
    let data = {
      phone_number: this.props.primaryNumber,
      secret: this.props.secret,
      otp: this.state.otp,
    };
    Recaptcha.executeAndTriggerApi(
      { action: Recaptcha.ACTIONS.FORGOT_PASSWORD_VERIFY_OTP },
      this.props,
      data
    );
  };

  handleResetAndLogin = (e) => {
    let data = {
      new_password: this.state.password,
      confirm_password: this.state.confirmPassword,
      phone_number: this.props.primaryNumber,
      secret: this.props.secret,
      otp: this.state.otp,
      token: this.props.token,
    };
    if (!this.state.charNumberValid) return;
    if (!this.state.specialCharValid) return;
    if (!this.state.uppercaseValid) return;
    if (!this.state.numberValid) return;

    this.props.resetPassword(data);
  };

  render() {
    if (this.props.passwordReset) {
      return <Redirect to="/login" />;
    }

    let display = "";
    let enterNumber = (
      <div>
        <Helmet>
          <title>
            Reset Password | Doosra Privacy-Focused Mobile Number App
          </title>
          <meta
            name="description"
            content="
    To reset your password, please enter your mobile number. We'll send you an 
    OTP to reset your password quickly. Click here to reset your password and 
    log in."
          />
        </Helmet>
        <h1 className="PageHeading">Forgot Password</h1>
        <div style={{ "text-align": "center" }}>
          <input
            className="Inputs"
            type="text"
            id="number"
            placeholder="+91 Your Mobile Number"
            value={this.state.number}
            onChange={this.handleChange}
            maxLength="10"
            minLength="10"
            pattern="[0-9]*"
          />
        </div>
        <p className="Error" style={{ "text-align": "center" }}>
          {this.props.resetPasswordError}
        </p>
        <div style={{ "text-align": "center" }}>
          <button
            className="Button"
            onClick={this.handleSubmitNumber}
            style={{ width: "180px" }}
          >
            <span className="ButtonText">Submit</span>
          </button>
        </div>
      </div>
    );

    let enterOTP = (
      <div>
        <h1 className="PageHeading">Enter OTP</h1>
        <div style={{ "text-align": "center" }}>
          <input
            className="InputTwo"
            type="text"
            id="otp"
            placeholder="XXXXXX"
            value={this.state.otp}
            onChange={this.handleChange}
          />
        </div>
        <div></div>
        <div className="ResendOTPHolder ResetPasswordHolder">
          <a
            className="ResendOTP"
            onClick={() => {
              this.props.resendOTPforPasswordReset(this.props.primaryNumber);
              this.setState({ otpResent: true });
            }}
          >
            Re-send OTP
          </a>
        </div>
        {this.state.otpResent && (
          <div className="OTPResent">
            <img src={GreenTick} />
            <p>OTP resent successfully</p>
          </div>
        )}
        <p className="Error" style={{ "text-align": "center" }}>
          {this.props.resetPasswordError}
        </p>

        <div style={{ "text-align": "center" }}>
          <button
            className="Button"
            onClick={this.handleSubmitOTP}
            style={{ width: "180px" }}
          >
            <span className="ButtonText">Verify OTP</span>
          </button>
        </div>
      </div>
    );

    let enterNewPassword = (
      <div>
        <h1 className="PageHeading">New Password</h1>
        <div style={{ "text-align": "center" }}>
          <input
            className="Inputs"
            style={{ "font-size": "18px" }}
            type="password"
            id="password"
            placeholder="Create Password"
            value={this.state.password}
            onChange={this.handleChange}
          />
        </div>
        <div style={{ "text-align": "center" }}>
          <input
            className="Inputs"
            style={{ "font-size": "18px" }}
            type="password"
            id="confirmPassword"
            placeholder="Re-enter Password"
            value={this.state.confirmPassword}
            onChange={this.handleChange}
          />

          <PasswordCheck
            charNumberValid={this.state.charNumberValid}
            specialCharValid={this.state.specialCharValid}
            uppercaseValid={this.state.uppercaseValid}
            lowercaseValid={this.state.lowercaseValid}
            numberValid={this.state.numberValid}
          />
          <p className="Error" style={{ "text-align": "center" }}>
            {this.props.resetPasswordError}
          </p>

          <div style={{ "text-align": "center" }}>
            <button className="Button" onClick={this.handleResetAndLogin}>
              <span className="ButtonText">Reset and Login</span>
            </button>
          </div>
        </div>
      </div>
    );

    switch (this.props.resetLevel) {
      case "enterNumber":
        display = enterNumber;
        break;
      case "enterOTP":
        display = enterOTP;
        break;
      case "enterNewPassword":
        display = enterNewPassword;
        break;
      default:
        display = enterNumber;
        break;
    }

    return (
      <React.Fragment>
        <InCNavbar />
        <div className="CenterContainerForgotPassword">{display}</div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  resetPasswordError: state.account.resetPasswordError,
  resetLevel: state.account.resetLevel,
  secret: state.account.resetSecret,
  primaryNumber: state.account.primaryNumber,
  passwordReset: state.account.passwordReset,
  token: state.account.token,
});

export default connect(mapStateToProps, {
  getOtpforPasswordReset,
  resendOTPforPasswordReset,
  verifyOTPforPasswordReset,
  resetPassword,
  resetForgotPasswordData,
})(ResetPassword);
