import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { useHistory } from "react-router";
import { Get, Put } from "../../utils/httpReguests";
import ErrorMessage from "../components/errorMessage";
import GoogleTag from "../components/GoogleTag";
import LogicalRouter from "../components/LogicalRouter";
import NavBar from "../components/NavBar";
import StepBar from "../components/registrationStepBar";
import { ReactComponent as Cart } from '../../assets/images/cart_small_blue.svg';
import Loading from "../../components/Loading";
import whitePreloader from '../../assets/images/rolling_white_loader.gif';
import { toast } from "react-toastify";
import ProgressIndicator from "../components/ProgressIndicator";

export default function Checkout(props) {
  const [numbersList, setNumbersList] = useState([]);
  const [error, setError] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const data = {
    type: "SIMILAR",
    phone_number: sessionStorage.getItem("userNumber"),
    max: true
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("selectedPlan") ||
      localStorage.getItem("doosra-user-sessionToken")
    ) {
      // history
    } else {
      history.push("/choose-plan");
    }
    setLoading(true);
    Get("vmns", data)
      .then((res) => {
        if (data?.type === "SIMILAR") {
          const { regex_matches, nearest_matches } =
            res?.data?.response?.similar;
          const list = regex_matches.concat(nearest_matches);
          setNumbersList(list);
          setLoading(false);
        } else {
          setNumbersList(res?.data?.response[props.numberType.toLowerCase()]);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'an error occured');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    window.dataLayer.push({
      event: "ChooseSimilar",
      pagePath: "/choose-similar",
      pageTitle: "ChooseSimilar",
    });
    GoogleTag();
  });

  const selectNumber = (numberInfo) => {
    if (loading) return;
    setLoading(true);
    Put(`vmns/${numberInfo?._id}/block`, {}, { type: "SIMILAR" })
      .then((res) => {
        sessionStorage.setItem(
          "numberBlockedAt",
          res?.data?.response?.blocked_at
        );
        sessionStorage.setItem(
          "numberBlockedFor",
          res?.data?.response?.blocked_for
        );
        sessionStorage.setItem("number", res?.data?.response?.v_mobile_no);

        Put(
          `user/${localStorage.getItem("doosra-user-id")}/vmn`,
          {},
          { v_mobile_no: numberInfo.v_mobile_no }
        )
          .then((res) => {
            history.push("/checkout");
          })
          .catch((err) => {
            setError(err?.response?.data?.error?.reason);
            setLoading(false);
            toast.error(err?.response?.data?.error?.reason || 'an error occured');
          });
      })
      .catch((err) => {
        let newNumberList = numbersList.map((item) => {
          if (item._id === numberInfo._id) {
            item.notAvailable = true;
          }
          return item;
        });
        setNumbersList(newNumberList);
        setLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'an error occured');
      });
  };

  let numberCard = <div />;

  if (numbersList.length > 0) {
    numberCard = numbersList.map((item) => (
      <div className='number-card-two' onClick={() => selectNumber(item)}>
        {loading ?
          <div style={{ textAlign: 'center', width: '100%' }}>
            <img src={whitePreloader} style={{ height: '30px' }} />
          </div>
          :
          <React.Fragment>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
              <h5 style={{ margin: 0, fontSize: '18px', fontWeight: 'bold' }}>{item.display_vmn}</h5>
              <h6 style={{ margin: 0, fontSize: '14px', fontWeight: '600' }}>&#8377; {item.similar_price / 100}</h6>
            </div>

            <button
              style={{
                border: '0', borderRadius: '4px', height: '32px', width: '95px', boxSizing: 'border-box', fontSize: '12px', fontWeight: '600', backgroundColor: 'white', color: '#196cca'
              }}
            >
              ADD TO
              {' '}
              <Cart style={{ marginBottom: '3px', marginLeft: '4px' }} />
            </button>
          </React.Fragment>
        }
      </div>
    ));
  }

  const goBackHandler = (point) => {
    history.push(point);
  };

  return (
    <div>
      <LogicalRouter />
      <NavBar />
      {loading && <Loading popup />}
      <ProgressIndicator currentStep={2} />
      <div style={{ marginBottom: '80px' }}>
        <div className="number-card-section">
          <h1 >Personalise your Doosra Number</h1>
          <h2 >
            Additional one time payment for that perfect number
          </h2>
        </div>
        <ul className="number-card-container">{numberCard}</ul>
        <div style={{ textAlign: "center" }}>
          <ErrorMessage msg={error} />
        </div>
        <div className="more-card" onClick={() => goBackHandler("/choose-number")}>
          <AiOutlineLeft className="form-btn-icon" style={{ marginBottom: '-2px', marginRight: '6px' }} />
          <h6 style={{ margin: 0 }}>GO BACK</h6>
        </div>
      </div>
    </div>
  );
}
