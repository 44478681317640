import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../styles/editNameModal.css';
import Recaptcha from '../../utils/Recaptcha';
import { Post, Put } from '../../utils/httpReguests';

const crossImg = require('../../assets/images/cross.svg');

export default function EditPrimaryNumberPopup(props) {
  const [newNumber, setNewNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [secret, setSecret] = useState('');
  const [step, setStep] = useState(1);
  const [preUpdateOtp, setPreUpdateOtp] = useState('');
  const [preUpdateSecret, setPreUpdateSecret] = useState('');
  const [otpSent, setOtpSent] = useState('');

  const handleClose = (e) => {
    if (e) e.preventDefault();
    props.closePopup();
    clearData();
  };

  const clearData = () => {
    setOtpSent('');
    setPreUpdateOtp('');
    setPreUpdateSecret('');
    setStep(1);
    setSecret('');
    setUserMessage('');
    setOtp('');
    setNewNumber('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const action = Recaptcha.ACTIONS.LOGIN_RESEND_OTP;
    await Recaptcha.execute({ action }).then((token) => {
      const data = {
        new_phone_number: newNumber,
        otp,
        secret,
        on: 'WEB',
        type: 'phone_number',
        source: 'WEB',
        recaptchaToken: token,
      };
      Put('v1/internal/w/update_user_profile', {}, data)
        .then((res) => {
          props.updateUserProfileData('zvr_mobile_no', newNumber);
          handleClose();
        })
        .catch((e) => {
          setUserMessage(
            e?.response?.data?.error?.reason || 'Unable to update Number',
          );
          setOtpSent('');
        });
    });
  };

  const sendotp = async (e) => {
    e.preventDefault();
    const action = Recaptcha.ACTIONS.LOGIN_RESEND_OTP;
    await Recaptcha.execute({ action }).then((token) => {
      const data = {
        phone_number: newNumber,
        recaptchaToken: token,
      };
      Post('v2/internal/w/send-otp', data)
        .then((res) => {
          setUserMessage('');
          setSecret(res?.data?.response?.secret);
          setOtpSent('OTP has been sent');
        })
        .catch((e) => {
          setUserMessage(
            e?.response?.data?.error?.reason || 'unable to update number',
          );
          setOtpSent('');
        });
    });
  };

  const getPreUpdateOtp = async (e) => {
    e.preventDefault();
    const action = Recaptcha.ACTIONS.LOGIN_RESEND_OTP;
    await Recaptcha.execute({ action }).then((token) => {
      const data = {
        phone_number: props.number,
        recaptchaToken: token,
      };
      Post('v2/internal/send-otp', data)
        .then((res) => {
          setUserMessage('');
          setOtpSent('OTP has been sent');
          setPreUpdateSecret(res?.data?.response?.secret);
        })
        .catch((err) => {
          setUserMessage(
            err?.response?.data?.error?.reason || 'error triggering OTP',
          );
          setOtpSent('');
        });
    });
  };

  const submitPreUpdateOTP = async (e) => {
    e.preventDefault();
    const action = Recaptcha.ACTIONS.LOGIN_RESEND_OTP;
    await Recaptcha.execute({ action }).then((token) => {
      const data = {
        phone_number: props.number,
        otp: preUpdateOtp,
        secret: preUpdateSecret,
        on: 'WEB',
        type: 'phone_number',
        source: 'WEB',
        recaptchaToken: token,
      };
      Post('v1/internal/verify-primary-number', data)
        .then((res) => {
          setStep(2);
          setUserMessage('');
          setOtpSent('');
        })
        .catch((e) => {
          setUserMessage(e?.response?.data?.error?.reason || 'invalid OTP');
          setOtpSent('');
        });
    });
  };

  if (step === 1) {
    return (
      <Modal show={props.isVisible} centered>
        <img className="cross-button" src={crossImg} onClick={handleClose} />
        <form>
          <h1>Verify Number </h1>
          <div className="new-number-otp-box">
            <input
              type="tel"
              placeholder="New number"
              value={props.number}
              disabled
              onChange={(e) => {
                setNewNumber(e.target.value);
              }}
            />
            <button onClick={getPreUpdateOtp} style={{ width: '110px' }}>
              Send OTP
            </button>
          </div>

          <input
            type="text"
            placeholder="Enter OTP"
            value={preUpdateOtp}
            onChange={(e) => {
              setPreUpdateOtp(e.target.value);
            }}
          />
          <span
            className="user-Message"
            style={{ color: 'green', margin: '6px auto' }}
          >
            {otpSent}
          </span>
          <span className="user-Message">{userMessage}</span>
          <div className="button-group">
            <button onClick={submitPreUpdateOTP}>Verify</button>
            <button onClick={handleClose} className="cancel-button">
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    );
  }

  return (
    <Modal show={props.isVisible} centered>
      <img className="cross-button" src={crossImg} onClick={handleClose} />
      <form>
        <h1>Edit Number </h1>
        <div className="new-number-otp-box">
          <input
            type="tel"
            placeholder="New number"
            value={newNumber}
            maxLength={10}
            onChange={(e) => {
              setNewNumber(e.target.value);
            }}
          />
          <button onClick={sendotp} style={{ width: '110px' }}>
            Send OTP
          </button>
        </div>
        <input
          type="text"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
        />
        <span
          className="user-Message"
          style={{ color: 'green', margin: '6px auto' }}
        >
          {otpSent}
        </span>
        <span className="user-Message">{userMessage}</span>
        <div className="button-group">
          <button onClick={handleSubmit}>Update</button>
          <button onClick={handleClose} className="cancel-button">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
