import React from "react";
import "../assets/css/maintainancePage.css";
import Maintainance from "../assets/images/maintainance.png";
function MaintainancePage(props) {
  return (
    <div className="maintainance-container">
      <img src={Maintainance} />
      <h2>We’re down for maintenance</h2>
      <h3>{"We should be back very soon, hold on tight!"}</h3>
    </div>
  );
}

export default MaintainancePage;
