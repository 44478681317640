export const GET_USER_ID_FROM_LOCAL = "GET_USER_ID_FROM_LOCAL";
export const GET_SESSION_TOKEN_FROM_LOCAL = "GET_SESSION_TOKEN_FROM_LOCAL";

export const VN_AVAILABLE = "VN_AVAILABLE";
export const VN_UNAVAILABLE = "VN_UNAVAILABLE";

export const GET_PLAN_ID = "GET_PLAN_ID";
export const UPDATE_PLAN_ID = "UPDATE_PLAN_ID";

export const STEP_ONE = "STEP_ONE";
export const STEP_TWO = "STEP_TWO";
export const STEP_THREE = "STEP_THREE";
export const STEP_FOUR = "STEP_FOUR";
export const STEP_FIVE = "STEP_FIVE";
export const STEP_SIX = "STEP_SIX";
export const STEP_SEVEN = "STEP_SEVEN";
export const JUMP_TO_PAGE = "JUMP_TO_PAGE";

export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const NEXT_STEP = "NEXT_STEP";

export const ERROR = "ERROR";

export const GET_OTP_SECRET_SUCCESS = "GET_OTP_SECRET_SUCCESS";
export const GET_OTP_SECRET_FAIL = "GET_OTP_SECRET_FAIL";

export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAIL = " OTP_VERIFY_FAIL";

export const DOOSRA_NUMBER_PRICE = "DOOSRA_NUMBER_PRICE";
export const DEFAULT_VIRTUAL_NUMBER = "DEFAULT_VIRTUAL_NUMBER";
export const SIMILAR_NUMBERS = "SIMILAR_NUMBERS";
export const VIP_NUMBERS = "VIP_NUMBERS";
export const PREMIUM_NUMBERS = "PREMIUM_NUMBERS";
export const SELECT_DOOSRA_NUMBER = "SELECT_DOOSRA_NUMBER";
export const SWITCH_TO_DEFAULT_DOOSRA_NUMBER =
  "SWITCH_TO_DEFAULT_DOOSRA_NUMBER";
export const DOOSRA_NUMBER_RESERVE_TIME_OVER =
  "DOOSRA_NUMBER_RESERVE_TIME_OVER";

export const CLEAR_VIP_NUMBERS_LIST = "CLEAR_VIP_NUMBERS_LIST";
export const CLEAR_PREMIUM_NUMBERS_LIST = "CLEAR_PREMIUM_NUMBERS_LIST";
export const CLEAR_SIMILAR_NUMBERS_LIST = "CLEAR_SIMILAR_NUMBERS_LIST";

export const GET_VMN_PRICE_LIST = "GET_VMN_PRICE_LIST";

export const REFERRAL_CODE_VALID = "REFERRAL_CODE_VALID";
export const REFERRAL_CODE_INVALID = "REFERRAL_CODE_INVALID";

export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAIL = "CREATE_ACCOUNT_FAIL";

export const FETCH_PAYMENT_DETAILS_SUCCESS = "FETCH_PAYMENT_DETAILS_SUCCESS";
export const FETCH_PAYMENT_DETAILS_FAIL = "FETCH_PAYMENT_DETAILS_FAIL";

export const DISCOUNT_CODE_VALID = "DISCOUNT_CODE_VALID";
export const DISCOUNT_CODE_INVALID = "DISCOUNT_CODE_INVALID";
export const CLEAR_DISCOUNT_CODE = "CLEAR_DISCOUNT_CODE";

export const GENERATE_ORDER_SUCCESS = "GENERATE_ORDER_SUCCESS";
export const GENERATE_ORDER_FAIL = "GENERATE_ORDER_FAIL";

export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAIL = "PAYMENT_FAIL";

export const GENERATE_SUBSCRIPTION_SUCCESS = "GENERATE_SUBSCRIPTION_SUCCESS";

// export const NUMBER_ALLOTMENT_SUCCESS = "NUMBER_ALLOTMENT_SUCCESS";
export const NUMBER_ALLOTMENT_FAIL = "NUMBER_ALLOTMENT_FAIL";

export const REDIRECT_DROPPED_OFF_USER = "REDIRECT_DROPPED_OFF_USER";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_FALSE = "LOGOUT_FALSE";

export const GET_OTP_FOR_PASSWORD_RESET_SUCCESS =
  "GET_OTP_FOR_PASSWORD_RESET_SUCCESS";
export const GET_OTP_FOR_PASSWORD_RESET_FAIL =
  "GET_OTP_FOR_PASSWORD_RESET_FAIL";

export const VERIFY_OTP_FOR_RESET_PASSWORD_SUCCESS =
  "VERIFY_OTP_FOR_RESET_PASSWORD_SUCCESS";
export const VERIFY_OTP_FOR_RESET_PASSWORD_FAIL =
  "VERIFY_OTP_FOR_RESET_PASSWORD_FAIL";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const FETCH_USER_PROFILE_DETAILS_SUCCESS =
  "FETCH_USER_PROFILE_DETAILS_SUCCESS";

export const SET_FREE_TRIAL_STATUS = "SET_FREE_TRIAL_STATUS";
export const GET_MAIN_PLANS = "GET_MAIN_PLANS";
export const GET_DISCOUNTED_PLANS = "GET_DISCOUNTED_PLANS";
export const GET_PRO_PLANS = "GET_PRO_PLANS";
export const UPGRADE_FROM_FREE_TRIAL = "UPGRADE_FROM_FREE_TRIAL";
export const UPGRADE_FROM_FREE_TRIAL_STEP_3 = "UPGRADE_FROM_FREE_TRIAL_STEP_3";
export const REDIRECT_BACK_WITHOUT_TRIAL_UPGRADE =
  "REDIRECT_BACK_WITHOUT_TRIAL_UPGRADE";
export const CANCEL_UPGRADE_FROM_FREE = "CANCEL_UPGRADE_FROM_FREE";

export const RESET_FORGOT_PASSWORD_DATA = "RESET_FORGOT_PASSWORD_DATA";

export const CLEAR_REGISTER_ERROR = "CLEAR_REGISTER_ERROR";

export const UPDATE_PROFILE_STATUS = "UPDATE_PROFILE_STATUS";

export const MAINTAINANCE = "MAINTAINANCE";
