import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import '../../assets/css/modal.css';
import addIcon from '../../assets/images/add-icon.png';
import subtractIcon from '../../assets/images/subtract-icon.png';
import { Get, Post } from '../../utils/httpReguests';
import Loading from '../Loading';

const crossImg = require('../../assets/images/cross.svg');
const CheckmarkGreenBig = require('../../assets/images/checkmark-circle-green-big.svg');
const rupees = require('../../assets/images/rupees.svg');

export default function BuyMinutes(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [minPackDetails, setMinPackDetails] = useState({});
  const [count, setCount] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(0);
  const history = useHistory();

  useEffect(() => {
    Get('v1/internal/w/all-minute-packs')
      .then((res) => {
        setMinPackDetails(res?.data?.response?.minutePacks[0]);
        setTotal(res?.data?.response?.minutePacks[0]?.amount / 100 || 0);
      })
      .catch((err) => {
        setError('Error fetching minute pack details');
      });
    const isModalOpen = new URLSearchParams(window.location.search).get('modal');
    if (isModalOpen === 'buy-minutes') {
      setIsPopupOpen(true);
    }
  }, []);

  const onAdd = () => {
    if (count >= 20) return;
    setCount(count + 1);
    setTotal((minPackDetails?.amount / 100) * (count + 1));
  };

  const onSubtract = () => {
    if (count <= 1) return;
    setCount(count - 1);
    setTotal((minPackDetails?.amount / 100) * (count - 1));
  };

  const Proceed = async () => {
    if (count === 0) return;
    const data = {
      min_pack_id: minPackDetails?.min_pack_id,
      user_id: props?.profileData?.user_id,
      quantity: count,
    };
    await Post('v1/internal/w/minute-pack-order', data)
      .then((res) => {
        if (res.data.success) {
          const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            name: 'Doosra',
            description: 'payment',
            order_id: res.data.response.order_details.order_id,
            prefill: {
              name: data.name,
              email: data.email,
              contact: data.contact,
            },
            handler: (response) => {
              const verificationData = {
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
                user_id: data.user_id,
                order_id: response.razorpay_order_id,
              };
              const confirmationData = {
                user_id: data.user_id,
                order_id: response.razorpay_order_id,
              };
              Post(
                'v1/internal/w/order-signature-verification',
                verificationData,
              )
                .then((resp) => {
                  if (resp.data.response.is_verified == true) {
                    setLoading(true);
                    RetryConfirmation(confirmationData);
                  } else {
                    console.log('err');
                  }
                })
                .catch((err) => {
                  console.log(err.response);
                });
            },
            theme: {
              color: '#686CFD',
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        }
      })
      .catch((err) => {
        console.log('err');
      });
  };

  const RetryConfirmation = (confirmationData) => {
    let retryCount = 1;

    const retry = (confirmationData) => {
      retryCount++;
      Get('v1/internal/w/is-order-confirmed', confirmationData)
        .then((resp) => {
          setLoading(false);
          setSuccess(true);
        })
        .catch((err) => {
          setTimeout(() => {
            if (retryCount < 7) retry(confirmationData);
          }, 5000);
        });
    };
    retry(confirmationData);
  };

  const openPopup = () => {
    setIsPopupOpen(true);
    history.push('/user-dashboard?modal=buy-minutes');
  };

  const closePopup = () => {
    setCount(1);
    setTotal(minPackDetails?.amount / 100 || 0);
    setIsPopupOpen(false);
    setLoading(false);
    history.push('/user-dashboard');
  };

  const closePopupWithUpdate = () => {
    setIsPopupOpen(false);
    setTotal(minPackDetails?.amount / 100 || 0);
    setCount(1);
    setSuccess(false);
    setLoading(false);
    props.reMountComponent();
  };

  const popper = (
    <Popup open={isPopupOpen} closeOnDocumentClick={false}>
      {success ? (
        <>
          <img
            className="cross-button"
            src={crossImg}
            onClick={closePopupWithUpdate}
          />
          <div className="Modal">
            <div
              className="PlanUpgradeModalHeading"
              style={{ paddingBottom: '5px' }}
            >
              Payment Successful
            </div>
            {/* <div className="PlanUpgradeModalSubHeading">
              <p>
                {selectedMinuteDuration} minutes have been added to your account
              </p>
            </div> */}
            <div className="ModalBody " style={{ textAlign: 'center' }}>
              <img src={CheckmarkGreenBig} style={{ marginBottom: '35px' }} />
            </div>
            <div className="ModalButtonHolder">
              <button
                className="ModalButtonTwo Invert"
                onClick={closePopupWithUpdate}
              >
                Close
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="ModalTwo">
          <div className="ModalBodyTwo BuyMinutesModalBody">
            <div className="ModalTwoTitleContainer">
              <p className="ModalTwoTitle">Add more call minutes</p>
              <p className="ModalTwoSubTitle">
                Call minutes can be added for more secure call-back and incoming
                call minutes
              </p>
            </div>

            <div
              className="NumberContentHolderTwo"
              style={{ borderColor: 'black' }}
            >
              <div className="SelectNumberNum">
                {minPackDetails?.minutes}
                {' '}
                minutes
              </div>
              <div
                className="SelectNumeberPrice"
                style={{ textAlign: 'right', paddingRight: '10px' }}
              >
                <img src={rupees} className="RupeeIcon" />
                {minPackDetails?.amount / 100 || 0}
              </div>
              <div className="CounterContainer">
                <span className="PlusMinus" onClick={onSubtract}>
                  <img src={subtractIcon} className="PlusMinusIcon" />
                </span>
                <span className="Counter">{count}</span>
                <span className="PlusMinus" onClick={onAdd}>
                  <img src={addIcon} className="PlusMinusIcon" />
                </span>
              </div>
            </div>
            <div className="NumberContentHolderTwo">
              <span className="SelectNumberNum">TOTAL</span>
              <span />
              <span
                className="SelectNumeberPrice"
                style={{ textAlign: 'right', paddingRight: '20px' }}
              >
                <img src={rupees} className="RupeeIcon" />
                {total}
              </span>
            </div>
            {
              loading ? <div style={{ textAlign: 'center' }}><Loading /></div>

                : (
                  <div
                    className="ModalButtonHolderTwo"
                    style={{ padding: 0, paddingTop: '15px' }}
                  >
                    <button className="ModalButtonTwo" onClick={Proceed}>
                      Proceed to Checkout
                    </button>
                    <button className="ModalButtonTwo Invert" onClick={closePopup}>
                      Back
                    </button>
                  </div>
                )
            }
          </div>
        </div>
      )}
    </Popup>
  );

  return (
    <span>
      <h5 style={{ color: '#ed3833', cursor: 'pointer' }} onClick={openPopup}>
        <strong> BUY MINUTES</strong>
      </h5>
      <span className="PlanUpgradeText">
        Add more secure call-back and incoming call minutes
      </span>
      {popper}
    </span>
  );
}
