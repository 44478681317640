import moment from 'moment';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../../styles/editNameModal.css';
import { Post } from '../../utils/httpReguests';
import Loading from '../Loading';

const crossImg = require('../../assets/images/cross.svg');

export default function CancelSubscriptionPopup(props) {
  const [error, setError] = useState('');
  const [isCancelSubPopupOpen, setIsCancelSubPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = (e) => {
    e.preventDefault();

    setIsCancelSubPopupOpen(false);
    setError('');
  };

  const remountComponent = () => {
    props.reMountComponent();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await Post('v1/internal/w/cancel-subscription', {
      subscription_id: props?.profileData?.subscription_id,
    })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          setIsCancelSubPopupOpen(false);
          setError('');
          remountComponent();
        }, 5000);
      })
      .catch((err) => {
        if (err?.response?.data.error?.reason) setError(err?.response?.data?.error?.reason);
        setLoading(false);
      });
  };

  if (!props.profileData?.is_auto_renewal) return null;
  if (props.profileData.accountType === 'free') return null;

  return (
    <>
      {props?.profileData?.is_auto_renewal && (
        <div
          className="side-box-element cancel-subscription-box"
          // style={{ "border-bottom": "none" }}
        >
          <span>
            Your card will be charged for ₹
            {props?.profileData?.subscription_amount}
            {' '}
            on
            {' '}
            {moment(props?.profileData?.expireOn).format('DD/MM/YYYY')}
          </span>
          <span
            className="cancel-subscription"
            onClick={() => setIsCancelSubPopupOpen(true)}
          >
            Cancel Subscription
          </span>
        </div>
      )}
      <Modal show={isCancelSubPopupOpen} centered>
        <img className="cross-button" src={crossImg} onClick={handleClose} />
        <form>
          <h1>Cancel Subscription </h1>
          <p>Are you sure you want to cancel the subscription?</p>
          <span className="user-Message">{error}</span>
          {loading ? (
            <Loading />
          ) : (
            <div
              className="button-group"
              id="cancel-subscription-button-group"
            >
              <button onClick={handleClose}>No</button>
              <button onClick={handleSubmit} className="cancel-button">
                Yes
              </button>
            </div>
          )}
        </form>
      </Modal>
    </>
  );
}
