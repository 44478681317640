import React, { Component } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout, setTokenFromLocal } from "../actions/UserAccountActions";
import Logo from "../assets/images/logo.png";
import LogoColorInverted from "../assets/images/logoBlack.png";
import "../assets/navbar.css";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      login: false,
    };
  }

  componentDidMount() {
    this.props.setTokenFromLocal();
    if (window.location.pathname == "/") {
      this.setState({
        login: false,
      });
    } else {
      this.setState({
        login: true,
      });
    }
  }

  logoutHandler = (sessionToken) => {
    this.props.logout(sessionToken);
    this.setState({
      login: false,
    });
  };

  changeTitle = () => {
    this.setState({
      login: !this.state.login,
    });
  };

  render() {
    // console.log('this.props', window.location.pathname);
    let token =
      localStorage.getItem("doosra-user-sessionToken") ||
      this.props.sessionToken;
    let authButton;
    if (token !== null) {
      authButton = (
        <p
          className="NavbarButton"
          onClick={() => this.logoutHandler(token)}
          style={this.props.black ? { color: "white" } : null}
        >
          Logout
        </p>
      );
    } else {
      if (this.state.login) {
        authButton = (
          <Link
            className="NavbarButton"
            to="/"
            onClick={this.changeTitle}
            style={this.props.black ? { color: "white" } : null}
          >
            Login
          </Link>
        );
      } else {
        authButton = (
          <Link
            className="NavbarButton"
            to="/choose-plan"
            onClick={this.changeTitle}
            style={this.props.black ? { color: "white" } : null}
          >
            Sign up
          </Link>
        );
      }
    }

    return (
      <Navbar
        collapseOnSelect
        expand="lg"
        className={this.props.black ? null : "NavbarShadow"}
        style={this.props.black ? { background: "black" } : null}
      >
        <a
          href="https://www.doosra.com/"
          className={this.props.black ? " LogoBlack" : " Logo"}
        >
          <img
            src={this.props.black ? LogoColorInverted : Logo}
            // width="30"
            // height="30"
            className="d-inline-block align-top logo"
            alt="React Bootstrap logo"
          />
        </a>
        <Navbar.Brand></Navbar.Brand>
        {this.props.black ? null : (
          <>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              style={{ border: "none" }}
            />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto"></Nav>
              <Nav>{authButton}</Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionToken: state.account.sessionToken,
});

export default connect(mapStateToProps, { logout, setTokenFromLocal })(NavBar);
