import React from "react";

export default function ErrorMessage(props) {
  return (
    <p
      style={{
        fontSize: "16px",
        fontWeight: "600",
        color: "#ed3833",
        margin: "0",
      }}
    >
      {props.msg}
    </p>
  );
}
