const axios = require("axios");

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_V2 = process.env.REACT_APP_V2_URL;
const API_URL_TWO = process.env.REACT_APP_API_URL_TWO;
const API_KEY = process.env.REACT_APP_API_KEY;

function get(url, params = {}) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
  };
  return axios.get(API_URL + url, {
    headers: header,
    params,
    withCredentials: true,
  });
}

function get_v2(url, params = {}) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
  };
  return axios.get(API_URL_V2 + url, {
    headers: header,
    params,
    withCredentials: true,
  });
}

function get_two(url, params = {}) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
  };
  return axios.get(API_URL_TWO + url, {
    headers: header,
    params,
    withCredentials: true,
  });
}

function post(url, params = {}) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
  };
  return axios.post(API_URL + url, params, {
    headers: header,
    withCredentials: true,
  });
}

function post_v2(url, params = {}) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
  };
  return axios.post(API_URL_V2 + url, params, {
    headers: header,
    withCredentials: true,
  });
}

function post_two(url, params = {}) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
  };
  return axios.post(API_URL_TWO + url, params, {
    headers: header,
    withCredentials: true,
  });
}

function sess_get(url, sessiontoken, params) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
    "x-metro-sessiontoken": sessiontoken,
  };
  return axios.get(API_URL + url, {
    headers: header,
    params,
    withCredentials: true,
  });
}

function sess_post(url, sessiontoken, params) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
    "x-metro-sessiontoken": sessiontoken,
  };
  return axios.post(API_URL + url, params, {
    headers: header,
    withCredentials: true,
  });
}
function sess_post_v2(url, sessiontoken, params) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
    "x-metro-sessiontoken": sessiontoken,
  };
  return axios.post(API_URL_V2 + url, params, {
    headers: header,
    withCredentials: true,
  });
}

function sess_put(url, sessiontoken, params) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
    "x-metro-sessiontoken": sessiontoken,
  };
  return axios.put(API_URL + url, params, {
    headers: header,
    withCredentials: true,
  });
}

function sess_delete(url, sessiontoken, params) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
    "x-metro-sessiontoken": sessiontoken,
  };
  return axios.delete(API_URL + url, { headers: header }, params);
}

function setup_axios_interceptor(store, props) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { data, status } = error.response;
      if (status === 503 && data.error.code === "MAINTENANCE_MODE") {
        store.dispatch({
          type: "MAINTAINANCE",
          payload: data.error.reason,
        });
      }

      if (data && data.error.code === 1004) {
        data.error.reason = "Invalid session, Logging out";
        setTimeout(() => {
          store.dispatch({
            type: "LOGOUT",
          });
        }, 2000);
      }

      return Promise.reject(error);
    }
  );
}

export {
  get,
  get_v2,
  get_two,
  post,
  post_v2,
  post_two,
  sess_get,
  sess_post,
  sess_post_v2,
  sess_put,
  sess_delete,
  setup_axios_interceptor,
};
