import React, { Component } from "react";
import { Redirect } from "react-router";
import { ReactComponent as ForwardArrow } from "../assets/images/Group 1228.svg";
import DoneImage from "../assets/images/red_tick_circle.svg";
import { get_two, post_two } from "../utils/HttpRequests";
import GoogleTag from "../v2/components/GoogleTag";
import InCNavbar from "./InCNavBar";
import Loading from "./Loading";
import WaitSteps from "./WaitSteps";
const GreenTick = require("../assets/images/checkmark_green.svg");
class JoinWaitingList extends Component {
  constructor() {
    super();
    this.state = {
      currentView: "A",
      number: "",
      otp: "",
      primaryNumber: "",
      secret: "",
      numberError: "",
      otpError: "",
      isVNAvailable: false,
      otpResent: false,
      loading: true,
    };
  }

  componentWillMount() {
    window.dataLayer.push({
      event: "Waitlist",
      pagePath: "/waiting",
      pageTitle: "Waitlist",
    });
    GoogleTag();

    get_two("w/is-vn-available", {})
      .then((resp) => {
        if (resp.data.response.status == true) {
          this.setState({
            isVNAvailable: true,
            loading: false,
          });
        } else {
          this.setState({
            isVNAvailable: false,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  JoinWaitList = () => {
    this.setState({
      currentView: "B",
    });
  };

  submitNumber = () => {
    post_two("send-otp", {
      phone_number: this.state.number,
      is_waiting_list_user: true,
    })
      .then((resp) => {
        if (resp.data.response) {
          this.setState({
            primaryNumber: resp.data.response.phone_number,
            secret: resp.data.response.secret,
            currentView: "C",
          });
        }
      })
      .catch((err) => {
        this.setState({
          numberError: err.response.data.error.reason,
        });
      });
  };

  submitOTP = () => {
    let data = {
      otp: this.state.otp,
      secret: this.state.secret,
      phone_number: this.state.primaryNumber,
    };
    post_two("profile/add-to-waiting-list", data)
      .then((resp) => {
        if (resp.data.response) {
          this.setState({
            primaryNumber: resp.data.response.number,
            secret: resp.data.response.secret,
            currentView: "D",
          });
        }
      })
      .catch((err) => {
        this.setState({
          otpError: err.response.data.error.reason,
        });
      });
  };

  render() {
    let currentDisplay;
    let A = (
      <div className="CenterContainer">
        <h1 className="WaitlistHeading" style={{ color: "#ed3833" }}>
          If you've an India mobile number,
        </h1>
        <h1 className="WaitlistHeading">you can get a Doosra Number. </h1>
        <div style={{ "text-align": "center" }}>
          <button className="Button" onClick={this.JoinWaitList}>
            <span className="ButtonText">Join The List</span>
            <ForwardArrow style={{ "margin-left": "20px" }} />
          </button>
        </div>
      </div>
    );

    let B = (
      <div>
        <WaitSteps
          step={this.state.currentView}
          style={{ "text-align": "center" }}
        />
        <div className="CenterContainer">
          <h3 className="PageHeading">
            Enter your phone number to receive an OTP
          </h3>
          <div>
            <input
              className="Inputs"
              type="text"
              id="number"
              placeholder="+91 Your Mobile Number"
              value={this.state.number}
              onChange={this.handleChange}
              maxLength="10"
              minLength="10"
              pattern="[0-9]*"
            />
          </div>
          <br />
          <p className="Error">{this.state.numberError}</p>
          <button
            className="Button"
            onClick={this.submitNumber}
            type="submit"
            style={{ width: "180px" }}
          >
            <spam className="ButtonText">Ok</spam>
            <ForwardArrow style={{ "margin-left": "50px" }} />
          </button>
        </div>
      </div>
    );
    let C = (
      <div>
        <WaitSteps
          step={this.state.currentView}
          style={{ "text-align": "center" }}
        />
        <div className="CenterContainer">
          <h3 className="PageHeading">Enter OTP received on your phone</h3>
          <input
            className="Inputs"
            type="text"
            id="otp"
            placeholder="XXXXXX"
            value={this.state.otp}
            onChange={this.handleChange}
          />
          <br />
          <p className="Error">{this.state.otpError}</p>
          <div>
            <a
              className="ResendOTP"
              onClick={() => {
                this.submitNumber(this.state.number);
                this.setState({ otpResent: true });
              }}
            >
              Re-send OTP
            </a>
          </div>
          {this.state.otpResent && (
            <div className="OTPResent" style={{ marginTop: "10px" }}>
              <img src={GreenTick} />
              <p>OTP resent successfully</p>
            </div>
          )}
          <br />
          <div>
            <button
              className="Button"
              onClick={this.submitOTP}
              style={{ width: "180px" }}
            >
              <span className="ButtonText">Ok</span>
              <ForwardArrow style={{ "margin-left": "50px" }} />
            </button>
          </div>
        </div>
      </div>
    );
    let D = (
      <div className="CenterContainer">
        <img src={DoneImage} style={{ "padding-bottom": "20px" }} />
        <h1 className="WaitlistHeading">
          You've successfully been added to our Waitlist
        </h1>
      </div>
    );

    switch (this.state.currentView) {
      case "A":
        currentDisplay = A;
        break;
      case "B":
        currentDisplay = B;
        break;
      case "C":
        currentDisplay = C;
        break;
      case "D":
        currentDisplay = D;
        break;
      default:
        break;
    }
    if (this.state.isVNAvailable) {
      return <Redirect to="/" />;
    }
    if (this.state.loading) {
      return (
        <div>
          <InCNavbar />
          <div style={{ textAlign: "center", marginTop: "20vh" }}>
            <Loading />
          </div>
        </div>
      );
    }
    return (
      <div>
        <InCNavbar />
        <div>{currentDisplay}</div>
      </div>
    );
  }
}

export default JoinWaitingList;
