/* eslint-disable */ 
import React, { useEffect, useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { useHistory } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { Get, Put } from '../../utils/httpReguests';
import GoogleTag from '../components/GoogleTag';
import NavBar from '../components/NavBar';
import Steps from '../components/registrationStepBar';
import { ReactComponent as CartBlue } from '../../assets/images/cart_small_blue.svg';
import { ReactComponent as CartGreen } from '../../assets/images/cart_small.svg';
import { ReactComponent as Proceed } from '../../assets/images/right_arrow_in_blue_circle.svg';
import Loading from '../../components/Loading';
import whitePreloader from '../../assets/images/rolling_white_loader.gif';
import ProgressIndicator from '../components/ProgressIndicator';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
export function Card(props) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const selectNumber = (data) => {
    switch (data.type) {
      case 'PREMIUM':
        sessionStorage.setItem('selectedNumberType', 'PREMIUM');
        selectVIPPremiumNumber(data);
        break;
      case 'VIP':
        sessionStorage.setItem('selectedNumberType', 'VIP');
        selectVIPPremiumNumber(data);
        break;
      case 'DEFAULT':
        sessionStorage.setItem('selectedNumberType', 'DEFAULT');
        BlockDefaultNumber(data);
        if (localStorage.getItem('doosra-user-sessionToken')) {
          updateDoosraNumber(data);
        }
        break;
      case 'SIMILAR':
        sessionStorage.setItem('selectedNumberType', 'SIMILAR');
        if (localStorage.getItem('doosra-user-sessionToken')) {
          history.push('/choose-similar');
        } else {
          history.push('/enter-number');
        }
        break;
    }
  };

  const BlockDefaultNumber = (data) => {
    if (loading) return;
    setLoading(true);
    Put(`vmns/${data?._id}/block`, {}, { type: 'DEFAULT' })
      .then((res) => {
        sessionStorage.setItem(
          'defaultNumberBlockedAt',
          res?.data?.response?.blocked_at,
        );
        sessionStorage.setItem(
          'defaultNumberBlockedFor',
          res?.data?.response?.blocked_for,
        );
        sessionStorage.setItem('defaultNumber', `91${data?.number}`);
        if (!localStorage.getItem('doosra-user-sessionToken')) {
          history.push('/enter-number');
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'an error occured');
        setLoading(false);
      });
  };

  const updateDoosraNumber = (data) => {
    if (loading) return;
    setLoading(true);
    Put(
      `user/${localStorage.getItem('doosra-user-id')}/vmn`,
      {},
      { v_mobile_no: `91${data.number}` },
    )
      .then((res) => {
        history.push('/checkout');
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'an error occured');
        setLoading(false);
      });
  };

  const selectVIPPremiumNumber = async (numberInfo) => {
    if (loading) return;
    setLoading(true);
    if (!localStorage.getItem('doosra-user-sessionToken')) {
      Put(`vmns/${numberInfo?._id}/block`, {}, { type: numberInfo.type })
        .then((res) => {
          sessionStorage.setItem(
            'numberBlockedAt',
            res?.data?.response?.blocked_at,
          );
          sessionStorage.setItem(
            'numberBlockedFor',
            res?.data?.response?.blocked_for,
          );
          sessionStorage.setItem('number', res?.data?.response?.v_mobile_no);
          history.push('/enter-number');
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason || 'an error occured');
          setLoading(false);
        });
    } else {
      Put(`vmns/${numberInfo?._id}/block`, {}, { type: numberInfo.type })
        .then((res) => {
          sessionStorage.setItem(
            'numberBlockedAt',
            res?.data?.response?.blocked_at,
          );
          sessionStorage.setItem(
            'numberBlockedFor',
            res?.data?.response?.blocked_for,
          );
          sessionStorage.setItem('number', res?.data?.response?.v_mobile_no);
          Put(
            `user/${localStorage.getItem('doosra-user-id')}/vmn`,
            {},
            { v_mobile_no: `91${numberInfo?.number}` },
          )
            .then((res) => {
              history.push('/checkout');
            })
            .catch((err) => {
              toast.error(err?.response?.data?.error?.reason || 'an error occured');
              setLoading(false);
            });
        })
        .catch((err) => {
          // let newNumberList = numbersList.map((item) => {
          //   if (item._id === numberInfo._id) {
          //     item.notAvailable = true;
          //   }
          //   return item;
          // });
          // setNumbersList(newNumberList);
          toast.error(err?.response?.data?.error?.reason || 'an error occured');
          setLoading(false);
        });
    }
  };
  let color;
  props.type === 'DEFAULT' ? color = '#00a631' : color = '#196cca';

  return (
    <div className="number-card-two" style={props.color} onClick={() => selectNumber(props)}>
      {loading
        ? (
          <div style={{ textAlign: 'center', width: '100%' }}>
            <img src={whitePreloader} style={{ height: '30px' }} />
          </div>
        )
        : (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}>
              <h5 style={{ margin: 0, fontSize: '18px', fontWeight: 'bold' }}>{props.number}</h5>
              <h6 style={{ margin: 0, fontSize: '14px', fontWeight: '600' }}>
                {props?.amount === 'Free'
                  ? `${props.amount}`
                  : (
                    <span>
                      &#8377;
                      {props.amount}
                    </span>
                  )}
              </h6>
            </div>

            {props.number === 'Starts from'
              ? (
                <button style={{
                  border: '0',
                  borderRadius: '4px',
                  height: '32px',
                  width: '95px',
                  boxSizing: 'border-box',
                  fontSize: '12px',
                  fontWeight: '600',
                  color,
                  backgroundColor: 'white',
                }}
                >
                  BROWSE
                  {' '}
                  <Proceed style={{ marginBottom: '3px', marginLeft: '4px' }} />
                </button>
              )
              : (
                <button
                  style={{
                    border: '0',
                    borderRadius: '4px',
                    height: '32px',
                    width: '95px',
                    boxSizing: 'border-box',
                    fontSize: '12px',
                    fontWeight: '600',
                    color,
                    backgroundColor: 'white',
                  }}
                >
                  ADD TO
                  {' '}
                  {
                    props.type === 'DEFAULT'
                      ? <CartGreen style={{ marginBottom: '3px', marginLeft: '4px' }} />
                      : <CartBlue style={{ marginBottom: '3px', marginLeft: '4px' }} />
                  }
                </button>
              )}
          </>
        )}
    </div>
  );
}

export function MoreCard({ type }) {
  const history = useHistory();

  const viewMoreNumbers = (type) => {
    switch (type) {
      case 'PREMIUM':
        // if (!premium?.length) return;
        sessionStorage.setItem('selectedNumberType', 'PREMIUM');
        history.push('/choose-premium');
        break;
      case 'VIP':
        // if (!doosraNumbers?.vip?.length) return;
        sessionStorage.setItem('selectedNumberType', 'VIP');
        history.push('/choose-vip');
        break;
    }
  };

  return (

    <div className="more-card" onClick={() => viewMoreNumbers(type)}>
      <h6 style={{ margin: 0 }}>SEE MORE NUMBERS </h6>
      <Proceed style={{ marginBottom: '1px', marginLeft: '6px' }} />
    </div>
  );
}

export function Segment(props) {
  return (
    <div className={props.type === 'DEFAULT' ? 'number-card-section-top number-card-section' : 'number-card-section'}>
      <h1>
        {props.title}
      </h1>
      <h2
        style={{
          marginBottom: '0px',
        }}
      >
        {props.subTitle1}
      </h2>
      <h2>
        {props.subTitle2}
      </h2>
      {
        props.type === 'DEFAULT' && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Card amount="Free" number={props.data.display_vmn} color={{ backgroundColor: '#00a631' }} type={props.type} _id={props.data._id} />
          </div>
        )
      }
      {
        props.type === 'VIP' && (
          <div className="number-card-container">
            {props.data.length > 0
              && props.data.map((item) => (
                <Card number={item.display_vmn} amount={item.price / 100} type={props.type} _id={item._id} />
              ))}
            <MoreCard type={props.type} />
          </div>
        )
      }
      {
        props.type === 'PREMIUM' && (
          <div className="number-card-container">
            {props.data.length > 0
              && props.data.map((item) => (
                <Card number={item.display_vmn} amount={item.price / 100} type={props.type} _id={item._id} />
              ))}
            <MoreCard type={props.type} />
          </div>
        )
      }
      {
        props.type === 'SIMILAR' && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Card amount={props.data} number="Starts from" type={props.type} />
          </div>
        )
      }
      <div />
    </div>
  );
}

export default function RegNumbersList() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [defaultNumber, setDefaultNumber] = useState({});
  const [premiumNumbers, setPremiumNumbers] = useState([]);
  const [vipNumbers, setVipNumbers] = useState([]);
  const [similarStartPrice, setSimilarStartPrice] = useState('');

  useEffect(() => {
    setLoading(true);

    if (
      localStorage.getItem('doosra-user-id')
      && localStorage.getItem('doosra-user-status') === 'ACTIVE'
    ) {
      history.push('/user-dashboard');
    }

    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_KEY, null, options);
    ReactPixel.track('SelectPlan', {});
    GoogleTag();
    checkIfPlanSelected();
  }, []);

  const checkIfPlanSelected = async () => {
    const type = new URLSearchParams(window.location.search).get('type');
    const vmn_id = new URLSearchParams(window.location.search).get('vmn_id');
    const more = new URLSearchParams(window.location.search).get('more');
    if (!sessionStorage.getItem('selectedPlan')) {
      Get('plans', { source: 'WEB' })
        .then((res) => {
          const planId = res?.data?.response?.pro[0].plan_id;
          sessionStorage.setItem('selectedPlan', planId);
          if ((type && vmn_id) || (type && more)) {
            webFlowRedirected(type, vmn_id, more);
          } else {
            getVirtualNumbers();
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if ((type && vmn_id) || (type && more)) {
      webFlowRedirected(type, vmn_id, more);
    } else {
      getVirtualNumbers();
    }
  };

  const webFlowRedirected = (type, vmn_id, more) => {
    // sessionStorage.clear();
    // localStorage.clear();

    sessionStorage.setItem('selectedNumberType', type);

    if (type === 'SIMILAR') {
      history.push('/enter-number');
    }

    if ((type === 'PREMIUM' || type === 'VIP') && more) {
      type === 'VIP' ? history.push('/choose-vip') : history.push('/choose-premium');
    }
    if (type === 'DEFAULT') {
      BlockDefaultNumber({ _id: vmn_id });
    }
    if ((type === 'PREMIUM' || type === 'VIP') && !more) {
      selectVIPPremiumNumber({ _id: vmn_id, type });
    }
  };

  const BlockDefaultNumber = (data) => {
    // if (loading) return;
    setLoading(true);
    Put(`vmns/${data?._id}/block`, {}, { type: 'DEFAULT' })
      .then((res) => {
        sessionStorage.setItem(
          'defaultNumberBlockedAt',
          res?.data?.response?.blocked_at,
        );
        sessionStorage.setItem(
          'defaultNumberBlockedFor',
          res?.data?.response?.blocked_for,
        );
        sessionStorage.setItem('defaultNumber', `${res?.data?.response?.v_mobile_no}`);
        history.push('/enter-number');
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'an error occured');
        setLoading(false);
      });
  };

  const selectVIPPremiumNumber = async (numberInfo) => {
    if (loading) return;
    setLoading(true);
    if (!localStorage.getItem('doosra-user-sessionToken')) {
      Put(`vmns/${numberInfo?._id}/block`, {}, { type: numberInfo.type })
        .then((res) => {
          sessionStorage.setItem(
            'numberBlockedAt',
            res?.data?.response?.blocked_at,
          );
          sessionStorage.setItem(
            'numberBlockedFor',
            res?.data?.response?.blocked_for,
          );
          sessionStorage.setItem('number', res?.data?.response?.v_mobile_no);
          history.push('/enter-number');
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.error?.reason || 'an error occured');
          setLoading(false);
        });
    } else {
      Put(`vmns/${numberInfo?._id}/block`, {}, { type: numberInfo.type })
        .then((res) => {
          sessionStorage.setItem(
            'numberBlockedAt',
            res?.data?.response?.blocked_at,
          );
          sessionStorage.setItem(
            'numberBlockedFor',
            res?.data?.response?.blocked_for,
          );
          sessionStorage.setItem('number', res?.data?.response?.v_mobile_no);
          Put(
            `user/${localStorage.getItem('doosra-user-id')}/vmn`,
            {},
            { v_mobile_no: res?.data?.response?.v_mobile_no },
          )
            .then((res) => {
              history.push('/checkout');
            })
            .catch((err) => {
              toast.error(err?.response?.data?.error?.reason || 'an error occured');
              setLoading(false);
            });
        })
        .catch((err) => {
          // let newNumberList = numbersList.map((item) => {
          //   if (item._id === numberInfo._id) {
          //     item.notAvailable = true;
          //   }
          //   return item;
          // });
          // setNumbersList(newNumberList);
          toast.error(err?.response?.data?.error?.reason || 'an error occured');
          setLoading(false);
        });
    }
  };

  const getVirtualNumbers = () => {
    Get('vmns', { max: true })
      .then((res) => {
        setDefaultNumber(res?.data?.response?.default);

        const premiumNumbersList = res?.data?.response?.premium;
        if (premiumNumbersList.length > 8) {
          premiumNumbersList.splice(1, premiumNumbersList.length - 8);
        }
        setPremiumNumbers(premiumNumbersList);

        const vipNumbersList = res?.data?.response?.vip;
        if (vipNumbersList.length > 8) {
          vipNumbersList.splice(1, vipNumbersList.length - 8);
        }
        setVipNumbers(vipNumbersList);
        // setDoosraNumbers(res?.data?.response);
        setSimilarStartPrice(res?.data?.response?.starting_prices?.similar?.price / 100 || 0);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'an error occured');
        setLoading(false);
      });
  };

  return (
    <div style={{ marginBottom: '80px' }}>
      <NavBar />
      {loading && <Loading popup />}
      <ProgressIndicator currentStep={2} />
      <ToastContainer />
      <Segment
        title="Continue with a free number?"
        subTitle1="This could be your new Doosra number!"
        subTitle2="Best part? It's on the house! Plan not included"
        type="DEFAULT"
        data={defaultNumber}
      />

      <Segment
        title="Limited Edition VIP Numbers ⭐⭐⭐⭐⭐"
        subTitle1="For those who take their digits seriously!"
        subTitle2="Check out our specially curated Numbers"
        type="VIP"
        data={vipNumbers}
      />

      <Segment
        title="Premium Doosra Numbers"
        subTitle1="Regular just doesn’t cut it?"
        subTitle2="Check out our affordable but premium Doosra Numbers"
        type="PREMIUM"
        data={premiumNumbers}
      />

      <Segment
        title="Number similar to your current one"
        subTitle1="Love your current number?"
        subTitle2="Get a Doosra Number similar to it."
        type="SIMILAR"
        data={similarStartPrice}
      />
      <div className="more-card" style={{ marginTop: '50px' }} onClick={() => { history.push('/choose-plan'); }}>
        <AiOutlineLeft className="form-btn-icon" style={{ marginBottom: '-2px', marginRight: '6px' }} />
        <h6 style={{ margin: 0 }}>Back to plan selection</h6>
      </div>
    </div>
  );
}
