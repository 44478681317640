import React, { Component } from 'react';
const check = require('../assets/images/checkmark_green.svg');
const uncheck = require('../assets/images/checkmark_grey.svg')


class PasswordCheck extends Component {

  render() {


    if (this.props.forModal) {
      return (
        <div className="ModalPasswordCheckContainer">
          <h1 style={{ 'fontSize': '13px' }}>Your password must contain</h1>
          <div className="ModalPasswordCheckContentContainer">
            {
              (this.props.charNumberValid) ?
                <img src={check} />
                : <img src={uncheck} />
            }
            {
              (this.props.charNumberValid) ?
                <p className="Green" style={{ 'margin': 0, 'fontSize': '13px' }}>6 characters minimum</p>
                : <p className="Grey" style={{ 'margin': 0, 'fontSize': '13px' }}>6 characters minimum</p>
            }
            {
              (this.props.specialCharValid) ?
                <img src={check} />
                : <img src={uncheck} />
            }
            {(this.props.specialCharValid) ?
              <p className="Green" style={{ 'margin': 0, 'fontSize': '13px' }}>At least 1 special character (ex - ! @ # $ % *)</p>
              : <p className="Grey" style={{ 'margin': 0, 'fontSize': '13px' }}>At least 1 special character (ex - ! @ # $ % *)</p>
            }
            {
              (this.props.uppercaseValid) ?
                <img src={check} />
                : <img src={uncheck} />
            }
            {
              (this.props.uppercaseValid) ?
                <p className="Green" style={{ 'margin': 0, 'fontSize': '13px' }}>At least one upper case character</p>
                : <p className="Grey" style={{ 'margin': 0, 'fontSize': '13px' }}>At least one upper case character</p>
            }
            {
              (this.props.lowercaseValid) ?
                <img src={check} />
                : <img src={uncheck} />
            }
            {
              (this.props.lowercaseValid) ?
                <p className="Green" style={{ 'margin': 0, 'fontSize': '13px' }}>At least one lower case character</p>
                : <p className="Grey" style={{ 'margin': 0, 'fontSize': '13px' }}>At least one lower case character</p>
            }
            {
              (this.props.numberValid) ?
                <img src={check} />
                : <img src={uncheck} />
            }
            {
              (this.props.numberValid) ?
                <p className="Green" style={{ 'margin': 0, 'fontSize': '13px' }}>At least one number</p>
                : <p className="Grey" style={{ 'margin': 0, 'fontSize': '13px' }}>At least one number</p>
            }
          </div>
        </div>
      )
    }

    return (
      <div className="PasswordCheckContainer">
        <h1>Your password must contain</h1>
        <div className="PasswordCheckContentContainer">
          {
            (this.props.charNumberValid) ?
              <img src={check} />
              : <img src={uncheck} />
          }
          {
            (this.props.charNumberValid) ?
              <p className="Green">6 characters minimum</p>
              : <p className="Grey">6 characters minimum</p>
          }
          {
            (this.props.specialCharValid) ?
              <img src={check} />
              : <img src={uncheck} />
          }
          {(this.props.specialCharValid) ?
            <p className="Green">At least 1 special character (ex - ! @ # $ % *)</p>
            : <p className="Grey">At least 1 special character (ex - ! @ # $ % *)</p>
          }
          {
            (this.props.uppercaseValid) ?
              <img src={check} />
              : <img src={uncheck} />
          }
          {
            (this.props.uppercaseValid) ?
              <p className="Green">At least one upper case character</p>
              : <p className="Grey">At least one upper case character</p>
          }
          {
            (this.props.lowercaseValid) ?
              <img src={check} />
              : <img src={uncheck} />
          }
          {
            (this.props.lowercaseValid) ?
              <p className="Green" >At least one lower case character</p>
              : <p className="Grey" >At least one lower case character</p>
          }
          {
            (this.props.numberValid) ?
              <img src={check} />
              : <img src={uncheck} />
          }
          {
            (this.props.numberValid) ?
              <p className="Green">At least one number</p>
              : <p className="Grey">At least one number</p>
          }
        </div>
      </div>
    )
  }
}

export default PasswordCheck;