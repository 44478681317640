import React, { Component } from "react";
import { submitSecureDataNumber } from "../actions/secureData";
import "../assets/css/secureData.css";
import InCNavbar from "./InCNavBar";

class SecureData extends Component {
  constructor() {
    super();
    this.state = {
      number: "",
      currentState: "",
      error: "",
    };
  }

  onChange = (e) => {
    e.preventDefault();
    if (e.target.value && e.target.value.length > 10) return;
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ currentState: "", error: "" });
  };

  submitHandler = (e) => {
    e.preventDefault();

    submitSecureDataNumber({ number: this.state.number })
      .then((res) => {
        console.log(res);
        this.setState({ currentState: "success" });
      })
      .catch((err) => {
        if (err?.data?.error?.code == "USER_ALREADY_HAVE_ACCOUNT") {
          this.setState({ currentState: "numberPresent" });
        }
        if (err?.data?.error?.code != "USER_ALREADY_HAVE_ACCOUNT") {
          this.setState({
            error: err.data.error.reason,
            currentState: "error",
          });
        }
      });
  };

  render() {
    let message;

    switch (this.state.currentState) {
      case "success":
        message = (
          <h5 style={{ color: "#ed3833" }}>
            We are processing your request and will update you via SMS shortly.
            Meanwhile, you can search another number.
          </h5>
        );
        break;
      case "numberPresent":
        message = (
          <h5 style={{ color: "#ed3833" }}>
            We are processing your request and will update you via SMS shortly.
            Meanwhile, you can search another number.
          </h5>
        );
        break;
      case "error":
        message = <h5 style={{ color: "#ee2c49" }}>{this.state.error}</h5>;
        break;
      default:
        message = (
          <h5>
            Enter your number and if it was leaked, you’ll receive a 20%
            discount coupon code via SMS so your privacy is never compromised
            again.
          </h5>
        );
        break;
    }

    return (
      <React.Fragment>
        <InCNavbar black />
        <div className="secureDataContainer">
          <h1>
            Domi<span style={{ color: "#e33538" }}>NO</span>
            <span style={{ color: "#206392" }}>OO</span>
          </h1>
          <h2>Find out if your personal number was leaked!</h2>

          <form onSubmit={this.submitHandler}>
            <input
              placeholder="Enter your phone number"
              value={this.state.number}
              type="number"
              id="number"
              onChange={this.onChange}
              className={
                this.state.number.length > 0
                  ? "secureDataContainerInput inputFound"
                  : "secureDataContainerInput"
              }
            />
            <button type="submit">Search</button>
          </form>
          {message}
        </div>
      </React.Fragment>
    );
  }
}

export default SecureData;
