import React from "react";
import InfoGrey from "../../assets/images/info-grey.svg";

export default function KYCMandatoryCard(props) {
  if (props.isKYCCompleted === false) {
    return (
      <div className="kyc-msg-box side-box">
        <img src={InfoGrey} style={{ width: "24px", height: "24px" }} />
        <p>
          <strong>Congratulations on signing up! </strong>
          Complete your KYC on the app to get the full Doosra experience.
        </p>
      </div>
    );
  }
  return null;
}
