/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import '../../styles/editNameModal.css';
import { Post, DELETE } from '../../utils/httpReguests';
import Recaptcha from '../../utils/Recaptcha';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";


const crossImg = require('../../assets/images/cross.svg');

export default function ConfirmDeleteAccountModal(props) {
    const history = useHistory();
    const [otp, setOtp] = useState("");
    const [seconds, setSeconds] = useState(15);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let intervalId = null;
        if (isActive) {
            intervalId = setInterval(() => {
                if (seconds === 0) {
                setIsActive(false);
                } else {
                setSeconds(seconds - 1);
                }
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [isActive, seconds]);

    const resendotp = {
        "background": "none",
        "border": "none",
        "padding": 0,
        "font-family": "Montserrat",
        "font-size": "14px",
        "font-weight": "bold",
        "font-stretch": "normal",
        "font-style": "normal",
        "line-height": 1.29,
        "letter-spacing": "normal",
        "color": "#939598",
        "text-align": "right",
        "text-decoration": "underline",
        "align-self": "end",
    }

    const logoutHandler = () => {
        Post("v1/internal/w/profile/logout")
        .then((response) => {
        if (response.data !== undefined && response.data.success) {
            localStorage.clear();
            sessionStorage.clear();
            history.push("/");
        }
        })
        .catch((err) => {
        console.log(err);
        });
    };

    const handleClose = (e) => {
        if (e) e.preventDefault();
        props.closePopup();
    };

    const handleContinue = (e) => {
        if (e) e.preventDefault();
        const action = Recaptcha.ACTIONS.LOGIN_OTP
        Recaptcha.execute({ action }).then((token) => {   
        DELETE('v1/internal/m/delete-account', {
            phone_number: props.number,
            secret:props.secret,
            otp,
            source: "WEB",
            reason: props.reason,
            device_info: "",
            recaptchaToken: token,
        })
            .then((res) => {
                toast("Account Successfully Deleted");
                logoutHandler();
                props.closePopup();
            })
            .catch((err) => {
                toast.error(err.response.data.error.reason);
            });
        });
    };

    const mouseOver = () => {
        document.getElementById("resendOtpButton").style.color = "#ed3131";
    };

    const mouseOut = () => {
        document.getElementById("resendOtpButton").style.color = "#939598";
    };

    const fetchOTP = () => {
        const action = Recaptcha.ACTIONS.LOGIN_OTP
        Recaptcha.execute({ action }).then((token) => {
        Post('v2/internal/send-otp', {
            phone_number: props.number,
            recaptchaToken: token,
        })
            .then((res) => {
                toast("OTP sent to your registered number successfully.");
                props.getSecret(res.data.response.secret);
                setIsActive(true)
            })
            .catch((err) => {
                toast.error(err.response.data.error.reason);
            });
        });
    };

    return (
        <Modal show={props.isVisible} centered>
            <img className="cross-button" src={crossImg} onClick={handleClose} />
            <span className="steps">Step 3 of 3</span>
            <h5 className="delete-account-head">
                <strong>Enter the OTP we just sent you</strong>
            </h5> 
            <span 
                className="delete-account-subhead"
            >
                Enter the OTP to your registered phone. number to confirm account deletion.
            </span>   
            <span
                className='caution-action'
            >
                This action is permanent and irreversible.
            </span>
            <input 
                type="text"
                placeholder='Enter the OTP here'
                value={otp}
                onChange={(e)=>{setOtp(e.target.value)}}
            />
            <button 
                id="resendOtpButton"
                style={resendotp}
                onClick={fetchOTP}
                onMouseOver={mouseOver}
                onMouseLeave={mouseOut}
            >
                Resend OTP
            </button>
            <span className="otp-caution">
                Never share your Doosra OTPs with anyone. 
                We NEVER ask our users for their OTPs for any reason. Keep it confidential.
            </span>
            <div 
                className='accept-button'
                onClick={ handleContinue }
            >
            <span className='button-text'>Confirm {isActive && seconds !==0 ? `(${seconds}s)` : ""}</span>
            </div>
            <span 
                className="Back"
                onClick={ handleClose }
            >
                Back
            </span>
        </Modal>
    );
};