import moment from "moment";
import React, { Component } from "react";
import { getPlans } from "../../actions/giftCard";
import "../../assets/css/giftSection.css";
import addIcon from "../../assets/images/add-icon.png";
import cancelButton from "../../assets/images/cancelButton.png";
import Checkmark from "../../assets/images/checkmark-circle.png";
import { ReactComponent as ForwardArrow } from "../../assets/images/Group 1228.svg";
import RazorpayImage from "../../assets/images/Image 41.png";
import subtractIcon from "../../assets/images/subtract-icon.png";
import { Post } from "../../utils/httpReguests";
import ErrorMessage from "../../v2/components/errorMessage";
import SuccessMessage from "../../v2/components/successMessage";
let total = 0;
class GiftCardMain extends Component {
  constructor() {
    super();
    this.state = {
      error: "",
      plan1: {
        id: "",
        amount: 0,
      },
      plan2: {
        id: "",
        amount: 0,
      },
      plan3: {
        id: "",
        amount: 0,
      },
      plan4: {
        id: "",
        amount: 0,
      },
      plan1Count: 0,
      plan2Count: 0,
      plan3Count: 0,
      plan4Count: 0,
      discountCode: "",
      discount: {},
      success: false,
    };
  }

  componentDidMount() {
    getPlans()
      .then((res) => {
        this.setState({
          plan1: {
            id: res.plan1.plan_id,
            amount: res.plan1.amount / 100,
          },
          plan2: {
            id: res.plan2.plan_id,
            amount: res.plan2.amount / 100,
          },
          plan3: {
            id: res.plan3.plan_id,
            amount: res.plan3.amount / 100,
          },
          plan4: {
            id: res.plan4.plan_id,
            amount: res.plan4.amount / 100,
          },
        });
      })
      .catch((err) => {
        console.log("err");
      });

    window.dataLayer.push({
      event: "GiftCardHome",
      pagePath: `/gift-card-home`,
      pageTitle: "GiftCardHome",
    });
  }

  onAdd = (plan) => {
    if (plan == "plan1") {
      if (this.state.plan1Count >= 100) return;
      this.setState({ plan1Count: this.state.plan1Count + 1 });
    }
    if (plan == "plan2") {
      if (this.state.plan2Count >= 100) return;
      this.setState({ plan2Count: this.state.plan2Count + 1 });
    }
    if (plan == "plan3") {
      if (this.state.plan3Count >= 100) return;
      this.setState({ plan3Count: this.state.plan3Count + 1 });
    }
    if (plan == "plan4") {
      if (this.state.plan4Count >= 100) return;
      this.setState({ plan4Count: this.state.plan4Count + 1 });
    }

    this.setState({
      discount: {},
      error: "",
      discountCode: "",
      success: false,
    });
  };

  onSubtract = (plan) => {
    if (plan == "plan1") {
      if (this.state.plan1Count <= 0) return;
      this.setState({ plan1Count: this.state.plan1Count - 1 });
    }
    if (plan == "plan2") {
      if (this.state.plan2Count <= 0) return;
      this.setState({ plan2Count: this.state.plan2Count - 1 });
    }
    if (plan == "plan3") {
      if (this.state.plan3Count <= 0) return;
      this.setState({ plan3Count: this.state.plan3Count - 1 });
    }
    if (plan == "plan4") {
      if (this.state.plan4Count <= 0) return;
      this.setState({ plan4Count: this.state.plan4Count - 1 });
    }
    this.setState({
      discount: {},
      error: "",
      discountCode: "",
      success: false,
    });
  };

  concatVouchers = () => {
    let vouchers = [];
    if (this.state.plan1Count >= 1) {
      let a = {
        plan_id: this.state.plan1.id,
        quantity: this.state.plan1Count,
      };
      vouchers.push(a);
    }
    if (this.state.plan2Count >= 1) {
      let a = {
        plan_id: this.state.plan2.id,
        quantity: this.state.plan2Count,
      };
      vouchers.push(a);
    }
    if (this.state.plan3Count >= 1) {
      let a = {
        plan_id: this.state.plan3.id,
        quantity: this.state.plan3Count,
      };
      vouchers.push(a);
    }
    if (this.state.plan4Count >= 1) {
      let a = {
        plan_id: this.state.plan4.id,
        quantity: this.state.plan4Count,
      };
      vouchers.push(a);
    }
    return vouchers;
  };

  selectCoupons = () => {
    let total =
      this.state.plan1Count * this.state.plan1.amount +
      this.state.plan2Count * this.state.plan2.amount +
      this.state.plan3Count * this.state.plan3.amount +
      this.state.plan4Count * this.state.plan4.amount;
    if (total <= 0) return;
    let vouchers = this.concatVouchers();
    let data = { vouchers };
    let displayTotal =
      this.state.plan1Count * this.state.plan1.amount +
      this.state.plan2Count * this.state.plan2.amount +
      this.state.plan3Count * this.state.plan3.amount +
      this.state.plan4Count * this.state.plan4.amount;
    let discountCode = null;
    if (this.state.error !== "" && this.state.discountCode !== "") {
      discountCode = null;
    }
    if (this.state.discountCode !== "" && this.state.discount.coupon) {
      discountCode = this.state.discountCode;
    }
    this.props.selectCoupons(data, displayTotal, discountCode);
  };

  onDiscountCodeChange(e) {
    // if (e.target.value === "") {
    //   this.setState({

    //   });
    // } else {
    this.setState({
      discountCode: e.target.value.toUpperCase(),
      error: "",
      success: false,
      discount: {},
      // discountCode: "",
    });
    // }
  }

  validateDiscountCode = () => {
    // let vouchers = [];
    // this.concatVouchers();
    let vouchers = this.concatVouchers();
    Post("v1/internal/w/apply-discount-gift-vouchers", {
      vouchers,
      discount: this.state.discountCode,
    })
      .then((res) => {
        this.setState({
          discount: res.data.response.order_details,
          success: true,
          error: "",
        });
      })
      .catch((err) => {
        this.setState({
          error: err?.response?.data?.error?.reason,
          discount: {},
          success: false,
        });
      });
  };

  render() {
    let displayTotal =
      this.state.plan1Count * this.state.plan1.amount +
      this.state.plan2Count * this.state.plan2.amount +
      this.state.plan3Count * this.state.plan3.amount +
      this.state.plan4Count * this.state.plan4.amount;

    if (this.state?.discount?.total_amount) {
      displayTotal = this.state?.discount?.total_amount;
    }

    return (
      <div className>
        <h1 className="GiftPageHead">
          Gift your friends and family a Doosra number
        </h1>
        <h3 className="GiftPageSubHead SubHeadPlanSpacing">
          You can buy multiple plans for each of those that are near and dear to
          you
        </h3>

        {/* <div className="GiftPlanHolder">
          <div className="Ribbon"></div>
          <div className="PlanDetails">
            <p className="NoMargin PlanName">Half-Yearly Plan</p>
            <p className="NoMargin FreeNumberIncluded "><img className="CheckMark" src={Checkmark} /> Free number included</p>
          </div>
          <div className="SecondBox">
            {
              (this.state.plan1.amount > 0) ?
                <p className="PlanAmount NoMargin"> <strong>&#8377;{this.state.plan1.amount}</strong></p>
                : <p> Loading </p>
            }

          </div>
          <div className="CounterContainer">
            <span className="PlusMinus"
              onClick={() => this.onSubtract('plan1')}

            ><img src={subtractIcon} className="PlusMinusIcon" /></span>
            <span className="Counter">{this.state.plan1Count}</span>
            <span className="PlusMinus"
              onClick={() => this.onAdd('plan1')}
            ><img src={addIcon} className="PlusMinusIcon" /></span>
          </div>
        </div>
        <div className="GiftPlanHolder">
          <div className="Ribbon"></div>
          <div className="PlanDetails">
            <p className="NoMargin PlanName">Annual Plan</p>
            <p className="NoMargin FreeNumberIncluded "><img className="CheckMark" src={Checkmark} /> Free number included</p>
          </div>
          <div className="SecondBox">
            {
              (this.state.plan2.amount > 0) ?
                <p className="PlanAmount NoMargin"> <strong>&#8377;{this.state.plan2.amount}</strong></p>
                : <p> Loading </p>
            }
          </div>
          <div className="CounterContainer">
            <span className="PlusMinus"
              onClick={() => this.onSubtract('plan2')}

            ><img src={subtractIcon} className="PlusMinusIcon" /></span>
            <span className="Counter">{this.state.plan2Count}</span>
            <span className="PlusMinus"
              onClick={() => this.onAdd('plan2')}
            ><img src={addIcon} className="PlusMinusIcon" /></span>
          </div>
        </div> */}
        <div className="GiftPlanHolder">
          <div className="Ribbon Yellow"></div>
          <div className="PlanDetails">
            <p className="NoMargin PlanName">Half-Yearly Pro Plan</p>
            <p className="NoMargin FreeNumberIncluded ">
              <img className="CheckMark" src={Checkmark} /> Free number included
            </p>
          </div>
          <div className="SecondBox">
            {this.state.plan3.amount > 0 ? (
              <p className="PlanAmount NoMargin">
                {" "}
                <strong>&#8377;{this.state.plan3.amount}</strong>
              </p>
            ) : (
              <p> Loading </p>
            )}
          </div>
          <div className="CounterContainer">
            <span
              className="PlusMinus"
              onClick={() => this.onSubtract("plan3")}
            >
              <img src={subtractIcon} className="PlusMinusIcon" />
            </span>
            <span className="Counter">{this.state.plan3Count}</span>
            <span className="PlusMinus" onClick={() => this.onAdd("plan3")}>
              <img src={addIcon} className="PlusMinusIcon" />
            </span>
          </div>
        </div>
        <div className="GiftPlanHolder">
          <div className="Ribbon Yellow"></div>
          <div className="PlanDetails">
            <p className="NoMargin PlanName">Annual Pro Plan</p>
            <p className="NoMargin FreeNumberIncluded ">
              <img className="CheckMark" src={Checkmark} /> Free number included
            </p>
          </div>
          <div className="SecondBox">
            {this.state.plan4.amount > 0 ? (
              <React.Fragment>
                <p className="PlanAmount NoMargin">
                  <strong>&#8377;{this.state.plan4.amount}</strong>
                </p>
                <p className="NoMargin BestValue">Best Value</p>
              </React.Fragment>
            ) : (
              <p> Loading</p>
            )}
          </div>
          <div className="CounterContainer">
            <span
              className="PlusMinus"
              onClick={() => this.onSubtract("plan4")}
            >
              <img src={subtractIcon} className="PlusMinusIcon" />
            </span>
            <span className="Counter">{this.state.plan4Count}</span>
            <span className="PlusMinus" onClick={() => this.onAdd("plan4")}>
              <img src={addIcon} className="PlusMinusIcon" />
            </span>
          </div>
        </div>
        <div className="gift-card-discount-container">
          <div className="gift-card-discount-input-container">
            {this.state.discountCode !== "" ? (
              <React.Fragment>
                <input
                  placeholder="Have a discount code?"
                  value={this.state.discountCode}
                  onChange={(e) => {
                    this.onDiscountCodeChange(e);
                  }}
                  style={
                    this.state.discountCode !== "" ? { color: "#000000" } : {}
                  }
                />
                <img
                  src={cancelButton}
                  onClick={() => {
                    this.setState({
                      error: "",
                      discountCode: "",
                      discount: {},
                      success: false,
                    });
                  }}
                />
              </React.Fragment>
            ) : (
              <input
                placeholder="Have a discount code?"
                value={this.state.discountCode}
                onChange={(e) => {
                  this.onDiscountCodeChange(e);
                }}
                style={
                  this.state.discountCode !== "" ? { color: "#000000" } : {}
                }
              />
            )}
          </div>
          <button
            style={
              this.state.discountCode !== ""
                ? { backgroundColor: "#000000" }
                : {}
            }
            onClick={this.validateDiscountCode}
          >
            APPLY
          </button>
        </div>
        <div className="gift-card-discount-message-container">
          <ErrorMessage msg={this.state.error} />
          {this.state.success && <SuccessMessage msg="Discount Applied" />}
        </div>
        <div className="TotalContainer">
          <p className="Total">TOTAL</p>
          <div className="TotalAmount">
            <p className="NoMargin"> &#8377; {displayTotal}</p>
            <p className="NoMargin AmountText">Amount</p>
          </div>
        </div>
        <button
          className={
            displayTotal <= 0
              ? "GiftCouponBuyButton ButtonSpacingPageTwo DisabledButton"
              : "GiftCouponBuyButton ButtonSpacingPageTwo"
          }
          onClick={this.selectCoupons}
          type="submit"
        >
          <spam className="">Buy</spam>
          <ForwardArrow
            className="ForwardArrowPosition"
            style={{ "margin-left": "26px" }}
          />
        </button>

        <div className="gift-card-tnc">
          <p>Gifts cards are non-refundable</p>
          <p>Each activation requires a unique primary mobile number</p>
          <p>e-KYC is mandatory at the time of activation</p>
          {moment().isAfter("2021-10-29T09:00:00.000Z") &&
            moment().isBefore("2021-11-09", "date") && (
              <p>Offer valid only until Nov 8th 2021</p>
            )}
          <p>
            Write to{" "}
            <a
              style={{
                color: "#ed3833",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              href="mailto:support@doosra.io"
            >
              support@doosra.io
            </a>{" "}
            for any queries
          </p>
        </div>

        <p className="GreyHeaderOne" style={{ "margin-bottom": "0" }}>
          Secure payments powered by
        </p>
        <img src={RazorpayImage}></img>
      </div>
    );
  }
}

export default GiftCardMain;
