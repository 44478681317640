import moment from "moment";
import React from "react";
import { Table } from "react-bootstrap";
import DownloadIcon from '../../assets/images/download_icon_red.png';
import { Get } from "../../utils/httpReguests";

const DiscountedPriceCard = (item) => {
  if (item.discountPercentage === "100") {
    return (
      <div className="discounted-payment">
        <h5>
          <span
            style={{
              textDecoration: "line-through",
              marginRight: "10px",
              color: "#939598",
            }}
          >
            {item.price / 100}
          </span>
          {0}
        </h5>
        <p>{item.discountPercentage}% Discount Applied</p>
        <h6>
          CODE:
          <span style={{ color: "#469f3e" }}>{item.discountCode}</span>
        </h6>
      </div>
    );
  }


  return (
    <div className="discounted-payment">
      <h5>
        <span
          style={{
            textDecoration: "line-through",
            marginRight: "10px",
            color: "#939598",
          }}
        >
          {item.price / 100 + parseInt(item.discountAmount)}
        </span>
        {item.price / 100}
      </h5>
      {item.discountPercentage && item.discountPercentage !== '0'
        && (
          <p>
            {item.discountPercentage}
            % Discount Applied
          </p>
        )}
      <h6>
        CODE:
        <span style={{ color: "#469f3e" }}>{item.discountCode}</span>
      </h6>
    </div>
  );
};


export default function OrderHistory(props) {

  const handleInvoiceDownload = (id) => {
    console.log(id);
    Get('v1/internal/w/invoice-download-url', { invoice_no: id })
      .then((resp) => {
        if (resp?.data?.response?.url?.url) {
          window.open(resp?.data?.response?.url?.url);
        }
      })
      .catch((err) => {
        console.log('err', err);
      })
  }

  return (
    <>
      <h1 className="order-box-element"> Order History </h1>
      <Table className="order-table">
        <thead>
          <tr>
            <th className="order-table-header">S.No.</th>
            <th className="order-table-header">Plan</th>


            <th
              className="order-table-header"
              style={{
                width: "250px",


                textAlign: "right",
                paddingRight: "100px",
              }}
            >
              Price
            </th>
            <th className="order-table-header" style={{ width: "150px" }}>
              Payment Date
            </th>
            <th className="order-table-header">Invoice</th>
          </tr>
        </thead>
        <tbody>
          {props?.ordersList?.map((item, index) => (
            <tr key={index}>
              <td data-label="S.No.">{index + 1}</td>
              <td data-label="Plan">{item.plan}</td>
              <td
                data-label="Price"
                className="price-column"
                style={{ paddingRight: "100px" }}
              >
                {item.discountCode ? (
                  <DiscountedPriceCard {...item} />
                ) : (
                  item.price / 100
                )}
              </td>
              <td data-label="Payment Date">
                {moment(item.paymentDate).format("DD/MM/YYYY")}
              </td>
              <td data-label="Invoice">
                {item?.invoice_no ? (
                  <img src={DownloadIcon} style={{ width: '14px' }} onClick={() => handleInvoiceDownload(item.invoice_no)} />
                ) : '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
